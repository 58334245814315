import {Col, Row} from "antd";
import React from "react";
import PropTypes from "prop-types";

export const TextCenter = props => {
    return (
        <Row type="flex" justify="center" align="middle">
            <Col>{props.children}</Col>
        </Row>
)}

TextCenter.propTypes = {
    children: PropTypes.node
}