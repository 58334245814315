import queryString from 'query-string';
import {oauthService} from "../../../services/oauthService";
import {useEffect} from "react";

export const GoogleDriveCallback = ({location}) => {

    useEffect(() => {

        let params = queryString.parse(location.search);

        if (params.error) {
            console.log(params.error);
            window.close();
            window.opener.location.reload();
        } else {
            oauthService.getOauthAccessToken(params.code, params.state, 'google-drive').then(() => {
                window.close();
                window.opener.location.reload();
            });
        }

    }, [location]);

}