import {processStepService} from "../../../../../services/processStepService";

export const STEPS = {
    EXPORT_OBJECT_STEP: "export-object-step",
    EXPORT_MAPPING_STEP: "export-mapping-step"
}

export const exportFormService = {

    getData: (step, action, values, inputs) => {

        switch (step) {
            case STEPS.EXPORT_MAPPING_STEP:
                return exportFormService.handleExportMappingStepAction(action, values, inputs);
            default: return Promise.reject(new Error("Not supported action"));
        }

    },

    handleExportMappingStepAction: (action, values, inputs) => {

        if (action === 'fetch-supported-fields') {
            return processStepService.getProcessStepData(values.source, "fields", values);
        } else {
            return Promise.reject(new Error("Not supported action " + action));
        }

    }

}