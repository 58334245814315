import {axiosService} from "../axios";

export const notificationService = {

    fetchCompanyNotifications: (level, backupHistoryId, type, page, size) => {

        let url = `/api/notifications/?page=${page - 1}&size=${size}`;

        if (level.length>0) {
            url = url + `&level=${level}`;
        }

        if (backupHistoryId) {
            url = url + `&backupHistoryId=${backupHistoryId}`;
        }

        if (type) {
            url = url + `&type=${type}`;
        }

        return axiosService.get(url);

    },

    fetchHeaderNotifications: (timestamp) => {

        if (timestamp) {
            return axiosService.get(`/api/notifications/header-notifications?t=${timestamp}`);
        } else {
            return axiosService.get(`/api/notifications/header-notifications`);
        }

    },

    lastSeenNotification: (id) => {
        return axiosService.post(`/api/notifications/last-seen`, {id});
    }

}