import React from "react";
import {notificationLevels, notificationsTypes} from "../../../utils/filter.utils";
import {Link} from "react-router-dom";
import {NotificationLevelValue} from "../NotificationLevel/NotificationLevelValue";
import {NotificationType} from "../NotificationType/NotificationType";
import {Tooltip} from "antd";
import {FormattedDateTime} from "../../DesignSystem/Moment/FormattedDateTime";

export const columns = (filter) => [
    {
        title: 'Level',
        dataIndex: 'level',
        key: 'level',
        visible: true,
        width: 100,
        render: (text, record) => (
            <NotificationLevelValue level={record.level}/>
        ),
        filters: notificationLevels,
        filterMultiple: false,
        filteredValue: filter.level
    },
    {
        title: 'Snapshot name',
        dataIndex: 'backupHistoryName',
        visible: true,
        width: 200,
        render: (text, record) => <Link to={`/backups/${record.backupId}/history/${record.backupHistoryId}`}>Snapshot {record.backupHistoryId}</Link>
    },
    {
        title: 'Type',
        dataIndex: 'type',
        visible: true,
        filters: notificationsTypes,
        filterMultiple: false,
        filteredValue: filter.type,
        width: 200,
        render: (text, record) => <NotificationType type={record.type}/>
    },
    {
        title: 'Message',
        dataIndex: 'value',
        key: 'value',
        width: 400,
        visible: true,
        ellipsis: {
            showTitle: true
        },
        render: (text) => <Tooltip title={text}>{text}</Tooltip>

    },
    {
        title: 'Date',
        width: 150,
        dataIndex: 'createdAt',
        visible: true,
        render: (text) => (
            <FormattedDateTime date={text}/>
        )
    }
];