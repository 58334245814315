import moment from "moment";

export const getTimezoneLabel = (value) => {

    const tz = moment.tz(value);
    const hours = tz.format('Z');
    return `(GMT${hours}) ${value}`;

}

export const getDuration = (startDate, endDate) => {
     const diff = endDate.diff(startDate);
     const duration = moment.duration(diff);
     return duration.get("minutes") + " minutes, " + duration.get("seconds")  + " second, " + duration.get("milliseconds") + " milliseconds";
}

export const humanFormat = (date) => {
     try {
          return moment(date).fromNow()
     } catch (err) {
          return `Invalid date (${date})`;
     }
}

export const formatDateTime = (dateTime) => {

     try {
          if (dateTime === null || dateTime === '') {
               return '';
          } else {
               return moment(dateTime).format('DD/MM/YY HH:mm:ss');
          }
     }
     catch(err) {
          return '';
     }

}

export const formatDate = (date) => {

     try {
          if (date === null || date === '') {
               return '';
          } else {
               return moment(date).format('LL');
          }
     }
     catch(err) {
          return '';
     }

}
