import React, {useEffect, useState} from "react";
import {Form} from "antd";
import {DynamicForm} from "../DynamicForm/DynamicForm";
import {processStepService} from "../../../services/processStepService";
import {applyChanges, applyInitialFormRules, reloadValues} from "../DynamicForm/dynamicForm.utils";
import {getErrorFromResponse} from "../../../utils/error.utils";
import PropTypes from "prop-types";

export const ProcessStepForm = ({properties, type, initialValues, handleSubmit}) => {

    const [form] = Form.useForm();

    const [data, setData] = useState([]);

    useEffect(() => {
        form.setFieldsValue({...initialValues});
        applyInitialFormRules(initialValues, properties, setData, processStepService);
    }, [initialValues, properties]);

    const onChange = (values) => {

        if (values.hasOwnProperty("connection")) {
            const payload = {connection: values["connection"]};
            processStepService.getProcessStepData(type, "connection-test", payload).then(() => {
            }).catch(error => {
                form.setFields([
                    {
                        name: 'connection',
                        errors: [getErrorFromResponse(error)],
                    },
                ]);
            })
        }

        applyChanges(form, values, data, setData, processStepService);

    }

    const onReload = (name) => {
        console.log("We need to reload data for " + name);
        return reloadValues(name, form, data, setData, processStepService);
    }

    return <DynamicForm form={form} onChange={onChange} onReload={onReload} onFinish={handleSubmit} properties={data}
        processService={processStepService}
    />

}

ProcessStepForm.propTypes = {
    properties: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired
}