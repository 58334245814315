import React from "react";
import {Button, Result, Spin} from "antd";
import {PublicContent} from "../PublicContent";
import PropTypes from "prop-types";

export const ConfirmEmailForm = ({confirmed, showLogin}) => {

    const inProgress = <Spin spinning={true}>
        <Result
            title="A token validation in progress."
        /></Spin>

    const invalid = <Result
        status={"error"} title="An email has not been confirmed."
        subTitle={"The token is not valid."}
    />

    const valid = <Result
        status={"success"}
        title="An email has been confirmed."
        subTitle="Please log in."
        extra={
            <Button type="primary" htmlType="submit" onClick={showLogin}>Sign in</Button>
        }>
    </Result>

    return <PublicContent>
        {confirmed === 'valid' && (valid)}
        {confirmed === 'invalid' && (invalid)}
        {confirmed === undefined && (inProgress)}
    </PublicContent>


}

ConfirmEmailForm.propTypes = {
    confirmed: PropTypes.string,
    showLogin: PropTypes.func.isRequired
}