import React from "react";
import style from "./ExportIcon.module.css";
import {BorderedIcon} from "../../DesignSystem/Icons/BorderedIcon";
import PropTypes from "prop-types";
import {getSourceName, getTargetName} from "../../../utils/download.utils";

export const ExportIcon = ({size = "small", source = "document", target = "document"}) => {

    const icon = target == null ? "document" : target;
    const sourceIcon = source == null ? "document" : source;

    return <span style={{minWidth: '120px'}}>

        <BorderedIcon image={`/process-types/${sourceIcon}.svg`} size={size} tooltip={getSourceName(sourceIcon)}/>
        <span className={style.arrow}>&gt;</span>
        <BorderedIcon image={`/process-types/${icon}.svg`} size={size} tooltip={getTargetName(target)}/></span>

}

ExportIcon.propTypes = {
    size: PropTypes.string,
    source: PropTypes.string,
    target: PropTypes.string
}