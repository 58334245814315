import React from "react";
import {Layout, Menu} from 'antd';
import HomeOutlined from "@ant-design/icons/lib/icons/HomeOutlined";
import {useHistory} from "react-router-dom";
import {useGlobalState} from "../../../App";
import ShopOutlined from "@ant-design/icons/lib/icons/ShopOutlined";
import {LockOutlined} from "@ant-design/icons";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";

const {Sider} = Layout;

const SettingsSidebar = () => {

    let history = useHistory();

    const defaultSelectedKeys = history.location.pathname;

    const [settingsSider, setSettingsSider] = useGlobalState("settingsSider");

    function handleCollapsed(value) {
        setSettingsSider({...setSettingsSider, collapsed: value})
    }

    function handlePageChange(value) {
        setSettingsSider({...setSettingsSider, current: value});
    }

    const onCollapse = (value) => {
        handleCollapsed(value);
    }

    const showPage = (values) => {
        handlePageChange(values.key);
        history.push(values.key);
    }

    return <Sider width={250}
                  className="sider"
                  theme={"light"}
                  style={{backgroundColor: "#fafafa"}}
                  collapsible collapsed={settingsSider.collapsed}
                  onCollapse={onCollapse}
    >
        <Menu
            onClick={showPage}
            style={{backgroundColor: "#fafafa"}}
            defaultSelectedKeys={[defaultSelectedKeys]}
            mode="inline"
        >
            <Menu.Item key="/settings" icon={<HomeOutlined/>}>
                Profile
            </Menu.Item>
            <Menu.Item key="/settings/password/" icon={<LockOutlined />}>
                Password
            </Menu.Item>
            {/*<Menu.Item key="/settings/access-tokens/" icon={<ApiOutlined/>}>*/}
            {/*    Access Tokens*/}
            {/*</Menu.Item>*/}
            <Spacer border={true}/>
            <Menu.Item key="/settings/company/" icon={<ShopOutlined/>}>
                Company
            </Menu.Item>
            {/*<Menu.Item key="/settings/subscriptions/" icon={<ShopOutlined/>}>*/}
            {/*    Subscriptions*/}
            {/*</Menu.Item>*/}
        </Menu></Sider>

}

export default SettingsSidebar;