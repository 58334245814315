import React, {useEffect, useState} from "react";
import {processStepService as processStepsService} from "../../../../services/processStepService";
import {Select, Radio, Flex} from "antd";
import {FilterOutlined} from "@ant-design/icons";
import Search from "antd/es/input/Search";

const {Option} = Select;

export const FilterTypes = {
    FILTERS: "FILTERS",
    JQL: "JQL",
};

const options = [
    {
        label: 'Filters',
        value: FilterTypes.FILTERS,
    },
    {
        label: 'JQL',
        value: FilterTypes.JQL,
    }
];

export const JiraFilters = ({connectionId, cloudId, onChange, value, type}) => {

    const [filters, setFilters] = useState(undefined);
    const [filterType, setFilterType] = useState(type);
    const [internalValue, setInternalValue] = useState(value);

    const loadFilters = () => {
        return processStepsService.getProcessStepData("jira", "filters", {connection: connectionId, cloudid: cloudId}).then(response => {
            setFilters(response.data.values);
        });
    }

    useEffect(() => {
        loadFilters();
    }, []);

    const handleFilterTypeChange = (value) => {
        setInternalValue(undefined);
        setFilterType(value.target.value);
        onChange(undefined, value.target.value);
    }

    const handleFilterChange = (value) => {
        onChange(value, filterType);
    }

    const onSearch = (value) => {
        onChange(value, filterType);
    }

    if (filters === undefined) {
        return <span/>
    }

    return <Flex gap="middle">

        {filterType === FilterTypes.JQL && <Search
            onSearch={onSearch}
            onChange={(e) => setInternalValue(e.target.value)}
            value={internalValue}
            style={{
                width: 500,
            }}/>}

        {filterType === FilterTypes.FILTERS &&
            <Select placeholder={"Select filter"} suffixIcon={<FilterOutlined/>} showSearch style={{width: '500px'}} onChange={handleFilterChange}
                    value={value} allowClear={true}>
                {filters.map(filter => {
                    return <Option key={filter.id} value={filter.id}>{filter.name}</Option>
                })}
            </Select>}

        <Radio.Group
            block
            options={options}
            defaultValue={FilterTypes.FILTERS}
            onChange={handleFilterTypeChange}
            value={filterType}
            optionType="button"
            buttonStyle="solid"
        />
        {" "}
    </Flex>

}