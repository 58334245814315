import React from "react";
import {Button, Form, Input, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import PropTypes from "prop-types";
import {CountrySelect} from "../../DesignSystem/ContrySelect/CountrySelect";

export const GetOfferForm = ({onSubmit}) => {

    return <Form layout='vertical'
                 onFinish={onSubmit}
    >
        <Form.Item style={{marginBottom: 0}}>
            <Form.Item name="firstName" label="First name" rules={[
                {
                    required: true,
                    min: 2,
                    message: '',
                }
            ]} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}>
                <Input placeholder="First name"/>
            </Form.Item>{' '}
            <Form.Item name="lastName" label="Last name" rules={[
                {
                    required: true,
                    min: 2,
                    message: '',
                }
            ]} style={{display: 'inline-block', width: 'calc(50% + 7px)'}}>
                <Input placeholder="Last name"/>
            </Form.Item>
        </Form.Item>
        <Form.Item name="email" label="Work email" rules={[
            {
                type: 'email',
                message: 'Please insert a valid email address'
            },
            {
                required: true,
                message: '',
            }
        ]}>
            <Input placeholder="example@acme.com"/>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
            <Form.Item name="company" label="Company name" rules={[
                {
                    required: true,
                    message: ''
                }
            ]} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}>
                <Input placeholder="Acme Inc."/>
            </Form.Item>{' '}
            <Form.Item name="companySize" label="Company size" rules={[
                {
                    required: true,
                    message: ''
                }
            ]} style={{display: 'inline-block', width: 'calc(50% + 7px)'}}>
                <Select options={getCompanySizeValues}/>
            </Form.Item>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
            <Form.Item name="department" label="Department" rules={[
                {
                    required: true,
                    message: ''
                }
            ]} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}>
                <Select options={getCompanyDepartment}/>
            </Form.Item>{' '}
            <Form.Item name="role" label="Role" rules={[
                {
                    required: true,
                    message: ''
                }
            ]} style={{display: 'inline-block', width: 'calc(50% + 7px)'}}>
                <Select options={getRoles}/>
            </Form.Item>
        </Form.Item>
        <Form.Item name="country" label="Country" rules={[
            {
                type: 'country',
                message: 'Please select a country'
            },
            {
                required: true,
                message: '',
            }
        ]}>
            <CountrySelect/>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}}>
            <Form.Item name="phone" label="Phone number" rules={[
                {
                    required: true,
                    message: ''
                }
            ]} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}>
                <Input/>
            </Form.Item>{' '}
            <Form.Item name="type" label="How can our sales team help?" rules={[
                {
                    required: true,
                    message: ''
                }
            ]} style={{display: 'inline-block', width: 'calc(50% + 7px)'}}>
                <Select options={getHelpOptions}/>
            </Form.Item>
        </Form.Item>
        <Form.Item name="message" label="Anything else?">
            <TextArea/>
        </Form.Item>
        <Spacer/>
        <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
    </Form>


};

const getCompanySizeValues = [
    {"value": "1 - 15", "label": "1 - 15"},
    {"value": "16 - 100", "label": "16 - 100"},
    {"value": "101 - 500", "label": "101 - 500"},
    {"value": "501 - 1000", "label": "501 - 1000"},
    {"value": "1000+", "label": "1000+"}
]

const getCompanyDepartment = [
    {"value": "Dev / Engineering", "label": "Dev / Engineering"},
    {"value": "IT", "label": "IT"},
    {"value": "Sales", "label": "Sales"},
    {"value": "Support & Success", "label": "Support & Success"},
    {"value": "Marketing", "label": "Marketing"},
    {"value": "Finance / Legal / Ops", "label": "Finance / Legal / Ops"},
    {"value": "HR / People", "label": "HR / People"},
    {"value": "Strategy / Biz Dev", "label": "Strategy / Biz Dev"},
]

const getRoles = [
    {"value": "CXO", "label": "CXO"},
    {"value": "VP", "label": "VP"},
    {"value": "Director", "label": "Director"},
    {"value": "Manager", "label": "Manager"},
    {"value": "Contributor", "label": "Contributor"},
]

const getHelpOptions = [
    {"value": "I want to evaluate apimoon for my organization"},
    {"value": "I want to understand which apimoon plan is right for me"},
    {"value": "I want to buy subscription or upgrade"},
    {"value": "I have a product question"}
]

GetOfferForm.propTypes = {
    onSubmit: PropTypes.func.isRequired
}