import {getErrorFromResponse} from "../../../utils/error.utils";

export const reloadValues = (fieldName, form, definition, setDefinition, processStepService) => {
    const item = definition.find(item => item.name === fieldName);
    return setOptionsOnFields(form.getFieldsValue(), [item], definition, setDefinition, processStepService);
}

export const applyChanges = (form, values, definition, setDefinition, processStepService) => {

    const changedProperty = Object.keys(values)[0];
    const changedValue = Object.values(values)[0];
    const connectedFields = getConnectedFields(changedProperty, definition);

    console.log(`Changed property ${changedProperty} reset values on ${connectedFields.map(connectedField => connectedField.name).join(",")}`);

    const joinedValues = {...form.getFieldsValue(), ...values};

    if (connectedFields.length > 0) {
        const allConnectedFields = getConnectedFieldsTree(changedProperty, definition);
        resetConnectedFieldsValue(form, allConnectedFields);

        disableNoneDirectFields(joinedValues, allConnectedFields.filter(s => s.direct !== undefined), definition, setDefinition);

        disabledDirectConnectedFieldsBasedOnChangedValue(changedValue, connectedFields, definition);

        setOptionsOnFields(joinedValues, connectedFields, definition, setDefinition, processStepService);
    }

}

export const applyInitialFormRules = (initialValues, definition, setDefinition, processStepService) => {

    definition.forEach(definitionItem => {

        const initialValue = initialValues[definitionItem.name];

        const connectedFields = getConnectedFields(definitionItem.name, definition);

        console.log(`Apply initial form rules property ${definitionItem.name} on values ${connectedFields.map(connectedField => connectedField.name).join(",")}`);

        disabledDirectConnectedFieldsBasedOnChangedValue(initialValue, connectedFields, definition);

    });

    setOptionsOnFields(initialValues, definition, definition, setDefinition, processStepService);

}

export const getConnectedFields = (changedField, definition) => {
    return definition.filter(item => item?.dependsOn !== null &&
        item.dependsOn.includes(changedField));
}

export const getConnectedFieldsTree = (changedField, definition) => {

    const connectedFields = getConnectedFields(changedField, definition);

    const allConnectedFields = [...connectedFields];

    connectedFields.forEach(connectedField => {
        traverseConnectionFields(allConnectedFields, connectedField, definition);
    });

    return allConnectedFields;

}

export const traverseConnectionFields = (result, field, definition) => {

    const connectedFields = getConnectedFields(field.name, definition);

    connectedFields.forEach(connectedField => {
        result.push({...connectedField, direct: false});
        traverseConnectionFields(result, connectedField, definition);
    });

}

export const resetConnectedFieldsValue = (form, connectedFields) => {
    console.log('resetConnectedFieldsValues on ' + connectedFields.map(connectedField => connectedField.name));
    form.resetFields(connectedFields.map(connectedField => connectedField.name));
}


export const disableNoneDirectFields = (values, connectedFields, data, setData) => {

    console.log('disableNoneDirectFields - connectedFields size is ' + connectedFields.length);

    console.log(connectedFields.map(connectedField => connectedField.name).join(","));

    connectedFields.forEach(connectedField => {
        data.forEach(item => {
            if (item.name === connectedField.name) {
                item.disabled = true;
                item.error = undefined;
                item.extended.options = [];
            }
        })
    });

    setData([...data]);

}

export const disabledDirectConnectedFieldsBasedOnChangedValue = (value, connectedFields, data) => {

    connectedFields.forEach(connectedField => {
        data.forEach(item => {
            if (item.name === connectedField.name) {
                item.disabled = value === undefined;
            }
        })
    });

}

export const getValuesForFields = (values, fields) => {

    const obj = {};

    fields.forEach(key => {
        obj[key] = values[key];
    });

    return obj;

}


export const setOptions = (values, item, processStepsService) => {

    return new Promise((resolve, reject) => {

        if (item.disabled !== undefined && item.disabled === true) {
            item.extended.options = [];
            item.error = undefined;
            return resolve([]);
        } else if (item.extended === undefined || item.extended === null) {
            return resolve();
        } else if (item.extended !== undefined && item.extended.fetch === undefined) {
            return resolve(item.extended.options)
        } else {
            console.log(`We need to load data for ${item.name} with payload ${JSON.stringify(values)}`);
            const payload = getValuesForFields(values, item.extended.fetch.payload);

            return processStepsService.getProcessStepData(item.extended.fetch.type, item.extended.fetch.action, payload).then(response => {
                item.extended.options = [...response.data];
                item.error = undefined;
                return resolve(response.data);
            }).catch(error => {
                item.disabled = true;
                if (item.extended !== undefined && item.extended.fetch === undefined) {
                    item.extended.options = undefined;
                }
                item.error = getErrorFromResponse(error);
                reject(getErrorFromResponse(error));
            });
        }

    });

}

async function callPromise(index, name, promise, setData, definition) {
     return await promise.then(() => {
         setData([...definition]);
         console.log(`Promise for ${name} is resolved.`);
         return {status: "OK", name: name};
     }).catch((error) => {
         setData([...definition]);
         console.log(`Promise for ${name} is rejected with error ${error}`);
         return {status: "FAILED", name: name};
    });
}
export const setOptionsOnFields = async (values, changedFields, definition, setData, processStepService) => {

    let promises = [];

    changedFields.forEach(item => {
        promises.push({
            name: item.name,
            promise: setOptions(values, item, processStepService)
        });
    })

    console.log('setOptionsOnFields - Promises size is ' + promises.length);
    console.log('Promises size is ' + promises.length);

    for (let i = 0; i < promises.length; i++) {
        await callPromise(i, promises[i].name, promises[i].promise, setData, definition);

        // if (response === "FAILED") {
        //     const allConnectedFields = getConnectedFieldsTree("connection", definition);
        //     resetConnectedFieldsValue(form, allConnectedFields);
            // break;
        // }

    }

    console.log("Done");

}