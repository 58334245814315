import React, {useState} from "react";
import {userService} from "../../../../services/user.service";
import RegistrationForm from "./RegistrationForm";
import {getErrorFromResponse} from "../../../../utils/error.utils";

const RegistrationFormContainer = () => {

    const [error, setError] = useState(undefined);
    const [state, setState] = useState(undefined);

    const handleFinish = values => {

        setState('in-progress');
        setError(undefined);

        userService.register(values).then(() => {
            setState('registered');
        }).catch(error => {
            setError(getErrorFromResponse(error));
            setState('error');
        });

    };

    return <RegistrationForm state={state} error={error} onFinish={handleFinish}/>

};

export default RegistrationFormContainer;
