import React from "react";
import {Button, Tooltip} from "antd";
import PropTypes from "prop-types";
import {getPublicDocumentText} from "../../utils/download.utils";
import LinkOutlined from "@ant-design/icons/lib/icons/LinkOutlined";


export const ExportDocumentPublicUrlButton = ({documentPublicUrl, type}) => {

    const openDocument = () => {
        window.open(documentPublicUrl, '_blank');
    }

    if (documentPublicUrl) {
        return <Tooltip title={getPublicDocumentText(type)}>
            {' '}<Button type={"text"} onClick={openDocument} icon={<LinkOutlined />}/>
        </Tooltip>
    } else {
        return <></>
    }

}

ExportDocumentPublicUrlButton.propTypes = {
    documentPublicUrl: PropTypes.string,
    target: PropTypes.string
}