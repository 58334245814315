import {PointerSensor} from "@dnd-kit/core";

export class ExcludeCheckboxPointerSensor extends PointerSensor {
    static activators = [
        {
            eventName: 'onPointerDown',
            handler: ({nativeEvent: event}) => {
                return !(!event.isPrimary ||
                    event.button !== 0 ||
                    isInteractiveElement(event.target));
            },
        },
    ];
}

function isInteractiveElement(element) {
    const interactiveElements = [
        'button',
        'input',
        'textarea',
        'select',
        'option',
        'span',
        'li'
    ];

    return interactiveElements.includes(element.tagName.toLowerCase());

}