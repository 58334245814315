import React, {useEffect, useState} from "react";
import {Spacer} from "../DesignSystem/Spacer/Spacer";
import {BorderedIcon} from "../DesignSystem/Icons/BorderedIcon";
import {ProcessStep} from "../DesignSystem/ProcessStep/ProcessStep";
import {Step} from "../Step/Step";
import {processStepService} from "../../services/processStepService";
import {MODE} from "../DesignSystem/ProcessStep/ProcessStepHeader";
import PropTypes from "prop-types";
import {Card, Result, Skeleton} from "antd";

export const DefinitionState = {
    ExportSelectSourceStep: "ExportSelectSourceStep",
    ExportObjectStep: "ExportObjectStep",
    ExportMappingStep: "ExportMappingStep",
    Completed: "Completed"
}

export const ExportMainForm = ({exportId, data, editableMode}) => {

    const [currentStep, setCurrentStep] = useState(DefinitionState.ExportSelectSourceStep);
    const [exportObjectStepData, setExportObjectStepData] = useState(undefined);
    const [exportMappingStepData, setExportMappingStepData] = useState(undefined);
    const [currentEditModeStep, setCurrentEditModeStep] = useState(undefined);

    const handleProcessStepEdit = (processStepId) => {
        return processStepService.getProcessStep(processStepId).then(response => {
            return response.data;
        });
    }

    const handleProcessStepDelete = (processStepId) => {
        return processStepService.deleteProcessStep(processStepId).then(() => {
            window.location.reload();
        })
    }

    const processExportObjectStepData = (data) => {
        setExportObjectStepData({connection: data.values?.connection, filter: data.values?.filter, object: data.export.object});
    }

    const processExportMappingStepData = (data) => {

        setExportMappingStepData({
            ...data.values,
            source: data.export.source,
            mapping: data.export.mapping === null ? [] : data.export.mapping
        })
    }

    const showAddProcessStep = () => {
        return currentStep === DefinitionState.Completed
    }

    useEffect(() => {

        if (data) {

            if (data.processSteps.length === 0) {
                setCurrentStep(DefinitionState.ExportSelectSourceStep);
            } else if (data.processSteps.length === 1 && data.export.mapping == null) {
                setCurrentStep(DefinitionState.ExportObjectStep);
                processExportObjectStepData(data);
            } else if (data.processSteps.length > 0) {
                setCurrentStep(DefinitionState.Completed);
                processExportObjectStepData(data);
                processExportMappingStepData(data);
            }

            setCurrentEditModeStep(undefined);

        }

    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


    if (editableMode === undefined && exportId !== undefined) {
        return <Skeleton/>
    }

    if (editableMode === 'PRIVATE_CONNECTION') {
        return <Card><Result
            status="warning"
            title="You aren't allowed to edit this export."
            subTitle="This export contains a private connection."
        /></Card>
    }

    return <>
        {showAddProcessStep() && exportId && data && data.processSteps.length === 1 && (<><Spacer/><Step exportId={exportId}/></>)}
        {data?.processSteps && data.processSteps.filter(type => type.direction === "OUT").map(processStep =>
            <span key={processStep.name}>
                <Spacer/>
            <ProcessStep name={processStep.name} processStepId={processStep.id}
                         onEdit={handleProcessStepEdit}
                         onDelete={handleProcessStepDelete}
                         showDelete={processStep.direction === "OUT"}
                         defaultMode={currentStep === DefinitionState.Completed && data.export.definitionState === 'DRAFT' ? MODE.EDIT : MODE.READ}
                         icon={<BorderedIcon image={`/process-types/${processStep.type}.svg`} size={"small"}/>}
            /></span>)}
    </>

}

ExportMainForm.propTypes = {
    exportId: PropTypes.string,
    onFinish: PropTypes.func.isRequired,
    data: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    editableMode: PropTypes.string
}