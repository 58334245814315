import React from "react";
import RegistrationFormContainer from "../../../components/apps/public/registration/RegistrationForm.container";

export const RegistrationPage = () => {

    return <RegistrationFormContainer/>


}

