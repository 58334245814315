import {Button} from "antd";
import React from "react";
import {Spacer} from "../DesignSystem/Spacer/Spacer";
import style from "./PricingTableItem.module.css";
import {TextCenter} from "../DesignSystem/Center/TextCenter";
import {PricingHeader} from "./PricingTableItem";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

const CustomPrice = ({price}) => {
    return <span className={style.price}>{price}</span>
}

CustomPrice.propTypes = {
    price: PropTypes.string.isRequired
}

export const CustomPricingTableItem = ({title}) => {

    const history = useHistory();

    const onShowTalkToUs = () => {
        history.push("/contact-sales");
    }

    return <div>
        <PricingHeader name={title}/>
        <CustomPrice price="Get an offer"/>
        <Spacer size={"large"}/>
        <span>For sizable organizations and enterprises dealing with a substantial volume of data and a wide range of intricate business scenarios.</span>
        <Spacer size={"large"}/>
        <TextCenter>
            <Button style={{marginTop: 16, minWidth: "200px"}} type={"primary"} onClick={onShowTalkToUs}>Talk to us</Button>
        </TextCenter>
    </div>


}

CustomPricingTableItem.propTypes = {
    title: PropTypes.string.isRequired
}