import React, {Tooltip, Tree} from "antd";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Search from "antd/es/input/Search";
import {SearchOutlined} from "@ant-design/icons";

const getTitle = (a) => {
    if (a.label === undefined) {
        return a.name
    } else {
        return <Tooltip title={'Name: ' + a.name + '. ' + a.description}>{a.label}</Tooltip>
    }
}
export const ExportMappingTableColumnsSelection = ({fields, mapping, onSelected, onRemoved}) => {

    const [treeData, setTreeData] = useState([]);
    const [initialTreeData, setInitialTreeData] = useState([]);
    const [defaultCheckedKeys, setDefaultCheckedKeys] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {

        if (fields === undefined) {
            return;
        }

        const tree = [];

        fields.groups.forEach(group => {

            const sortedProperties = group.properties.sort((a, b) => a.label.localeCompare(b.label));

            if (group.name === 'Base') {

                sortedProperties.forEach(a => {
                    tree.push({title: getTitle(a), key: a.name, name: a.name, label: a.label, target: a.target, type: a.type});
                })

            } else {

                tree.push({
                    title: group.name,
                    label: group.name,
                    key: group.name,
                    children: sortedProperties.map(a => {
                        return {title: getTitle(a), key: a.name, name: a.name, label: a.label, target: a.target, type: a.type}
                    })
                });

            }


        });

        tree.sort((a, b) => a.label.localeCompare(b.label));

        if (searchTerm !== '') {
            setFilteredTreeData(searchTerm);
        } else {
            setTreeData(tree);
        }

        setInitialTreeData(tree);

        setDefaultCheckedKeys(mapping.map(s => s.name));

    }, [fields, mapping]);

    const getParentKey = (key, tree) => {
        let parentKey;
        for (let i = 0; i < tree.length; i++) {
            const node = tree[i];
            if (node.children) {
                if (node.children.some(item => item.key === key)) {
                    parentKey = node.key;
                } else if (getParentKey(key, node.children)) {
                    parentKey = getParentKey(key, node.children);
                }
            }
        }
        return parentKey;
    };

    const getLabel = (info, treeData) => {

        const parent = getParentKey(info.key, treeData);

        return parent === undefined ? info.label : info.label + " (" + parent + ")";

    }

    const onCheck = (checkedKeys, info) => {

        setDefaultCheckedKeys(checkedKeys);

        if (info.node.hasOwnProperty("children")) {

            if (info.checked === true) {
                onSelected(info.node.children.map(s => {
                    return {name: s.name, label: getLabel(s, treeData), target: s.target, type: s.type}
                }));
            } else {
                const keys = [];
                info.node.children.forEach(a => {
                    keys.push(a.key);
                })
                onRemoved(keys);
            }

        } else if (info.checked === true) {
            onSelected([{name: info.node.key, label: getLabel(info.node, treeData), target: info.node.target, type: info.node.type}]);
        } else {
            onRemoved([info.node.key]);
        }


    };

    const handleOnSelect = (selectedKeys, info) => {

        if (info.node.hasOwnProperty("children")) {

            if (info.node.selected === true) {
                onSelected(info.node.children.map(s => {
                    return {name: s.name, label: getLabel(s, treeData), target: s.target, type: s.type}
                }));
            } else {
                const keys = [];
                info.node.children.forEach(a => {
                    keys.push(a.key);
                })
                onRemoved(keys);
            }

        } else if (info.node.selected === true) {
            onSelected([{name: info.node.key, label: getLabel(info.node, treeData), target: info.node.target, type: info.node.type}]);
        } else {
            onRemoved([info.node.key]);
        }

    }

    const filterTreeData = (data, searchTerm) => {
        return data
            .map((node) => {
                const newNode = { ...node };
                if (newNode.children) {
                    newNode.children = filterTreeData(newNode.children, searchTerm);
                }
                // Include the node if its title includes the search term or any of its children include the search term
                return newNode.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
                (newNode.children && newNode.children.length)
                    ? newNode
                    : null;
            })
            .filter((node) => node); // Remove nulls from the filtered array
    };


    // Filter tree data when search term changes
    const onSearch = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        setFilteredTreeData(value);
    };

    const setFilteredTreeData = (value) => {
        const filteredData = filterTreeData(initialTreeData, value);
        setTreeData(filteredData);
    }

    return <div style={{maxHeight: '400px', overflow: 'scroll', minHeight: '80vh', backgroundColor: '#FAFAFA'}}>
        <Search
            placeholder="Search"
            onChange={onSearch}
            style={{ marginBottom: 8 }}
        />
        <Tree checkable treeData={treeData} onCheck={onCheck}
              selectable={false}
              onSelect={handleOnSelect}
              defaultExpandParent={true}
              defaultCheckedKeys={[...defaultCheckedKeys]}
              style={{backgroundColor: '#FAFAFA', paddingTop: '10px'}}
              checkedKeys={[...defaultCheckedKeys]}/>
    </div>

}

ExportMappingTableColumnsSelection.propTypes = {
    fields: PropTypes.object.isRequired,
    mapping: PropTypes.array.isRequired,
    onSelected: PropTypes.func.isRequired,
    onRemoved: PropTypes.func.isRequired
}