import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";

import {Checkbox, Col, Row} from "antd";
import style from "./PreferencesList.module.css";
import {MenuOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const PreferenceListItem = ({title, name, visible, onChange}) => {

    return <Row className={style.item}>
        <Col sm={23}>
            <Checkbox checked={visible} onChange={e => onChange(name, e.target.checked)}/>
            <span className={style.value}>{title} ({name})</span>
        </Col>
        <Col sm={1}>
            <span className={style.handle}><MenuOutlined/></span>
        </Col>
    </Row>

};

PreferenceListItem.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}

export const PreferenceListItemContext = (props) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({id: props.name});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            {...props}
            {...attributes}
            {...listeners}
        >
            <PreferenceListItem name={props.name} title={props.title} visible={props.visible} onChange={props.onChange}/>
        </div>
    );
};

PreferenceListItemContext.propTypes = {
    title: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    visible: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
}