import React from "react";
import {Card} from "antd";
import style from "./CustomCard.module.css";
import PropTypes from "prop-types";

export const CustomCard = (props) => {

    return <Card className={style.card} {...props}>{props.children}</Card>

}

CustomCard.propTypes = {
    children: PropTypes.node.isRequired
}