import React, {useEffect, useState} from "react";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {FormCard} from "../../../components/DesignSystem/FormCard/FormCard";
import {Button, Form, Spin} from "antd";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {FormElement} from "../../../components/DesignSystem/DynamicForm/DynamicForm";
import {connectionTypeService} from "../../../services/connectionTypeService";
import {connectionService} from "../../../services/connectionService";
import {AlertIfMessage} from "../../../components/DesignSystem/AlertIfMessage/AlertIfMessage";
import {getErrorFromResponse} from "../../../utils/error.utils";
import {useParams} from "react-router-dom";

export const EditConnectionPage = () => {

    const [form] = Form.useForm();

    const [error, setError] = useState(undefined);
    const [fields, setFields] = useState([]);
    const [loadingContext, setLoadingContext] = useState({loading: true, tip: undefined});

    let {connectionId, connectionType} = useParams();

    useEffect(() => {

        if (connectionId && connectionType) {
            connectionTypeService.fetchConnectionTypes().then(response => {
                handleConnectionTypeSelect(connectionType, response.data);
            })
        } else {
            alert('Invalid params');
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleConnectionTypeSelect = (connectionType, types) => {

        connectionTypeService.fetchConnectionType(connectionType).then(response => {
            const properties = response.data.properties;
            setFields(properties);
        }).finally(() => setLoadingContext({loading: false}) )

    }

    const handleFinish = (values) => {
        setError(undefined);
        connectionService.updateConnection(connectionId, values).then(() => {
            window.close();
            window.opener.location.reload();
        }).catch(error => {
            setError(getErrorFromResponse(error));
        });
    }

    return <CenteredDesign>
        <Spin spinning={loadingContext.loading} tip={loadingContext.tip}>
            <FormCard title={"Update connection"}>
                <AlertIfMessage message={error}/>
                <Form layout='vertical' form={form} onFinish={handleFinish}>
                    {fields.map((field) => (
                        <FormElement {...field} key={field.name}/>
                    ))}
                    <Spacer/>
                    <Form.Item shouldUpdate className="submit">
                        {() => (
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={
                                    !form.isFieldsTouched() ||
                                    form.getFieldsError().filter(({errors}) => errors.length)
                                        .length > 0
                                }
                            >
                                Save
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </FormCard>
        </Spin>
    </CenteredDesign>

}