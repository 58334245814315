import React, {useMemo} from "react";
import {Col, Row, Select} from "antd";
import Dayjs from "dayjs";
import PropTypes from "prop-types";

const {Option} = Select;

export const CronHourPart = ({value, onChange}) => {

    const mapping = useMemo(() => {

        const hours = [];

        for (let hour = 0; hour < 24; hour++) {
            const label = Dayjs().hour(hour).minute(0).format("HH:mm")
            hours.push({value: hour.toString(), label: label});
        }

        return hours;

    }, []);

    const handleHourChange = (hour) => {
        onChange(hour);
    }

    return <Row gutter={12}>
        <Col sm={24}>
            <Select showSearch value={value} style={{width: "100%"}} onChange={handleHourChange}>
                {mapping.map(interval => <Option key={interval.value} value={interval.value}>{interval.label}</Option>)}
            </Select>
        </Col>
    </Row>

}

CronHourPart.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}