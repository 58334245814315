import {Select} from "antd";
import React, {useMemo} from "react";
import PropTypes from "prop-types";
import {getNames} from "country-list";

const {Option} = Select;

export const CountrySelect = ({value, onChange}) => {

    const countries = useMemo(() => {
        return getNames().map(value => {
            return value;
        })
    }, []);

    return <Select placeholder={"Select country"} showSearch onSelect={onChange} value={value}>
        {countries.map(country => {
            return <Option key={country} value={country}>{country}</Option>
        })}

    </Select>

}

CountrySelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}