import {Col, Row} from "antd";
import React from "react";
import PropTypes from "prop-types";

const Center = ({children}) => {
    return (
        <Row type="flex" justify="center" align="middle" style={{minHeight: '70vh', padding: '30px'}}>
            <Col>{children}</Col>
        </Row>
)}

export default Center;

Center.propTypes = {
    children: PropTypes.node
}