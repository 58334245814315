import React from "react";
import style from "./HeaderItem.module.css";
import PropTypes from "prop-types";
import {Tooltip, Typography} from "antd";

export const HeaderItem = ({icon, value, label}) => {

    return <div className={style.right}>
        <span> <span className={style.label}>{label} </span>
        </span><br/>
        <span><Typography.Text ellipsis={true} style={{maxWidth: '300px'}}><Tooltip title={value}>{icon} {value}</Tooltip></Typography.Text></span>
    </div>

}

HeaderItem.propTypes = {
    icon: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired
}