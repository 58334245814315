import React from "react";
import styles from "./SubTitle.module.css";
import PropTypes from "prop-types";

export const SubTitle = props => {
    return (
        <div className={styles.subTitle}>{props.children}</div>
    )};

SubTitle.propTypes = {
    children: PropTypes.node
}