import React from "react";
import styles from "./Spacer.module.css";
import PropTypes from "prop-types";

export function Spacer({size, border = false}) {
    let className = size === undefined ? styles.small : styles[size];
    if (border) {
        className = className + " " + styles.border;
    }
    return <div className={className} />;
}

Spacer.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    border: PropTypes.bool
}