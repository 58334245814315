import React from "react";
import {HumanReadableDate} from "./HumanReadableDate";
import pluralize from "pluralize";
import PropTypes from "prop-types";


export const CreatedAbout = ({createdAt, usage}) => {

    const usageMessage = (usage) => {

        if (usage === 0) {
            return 'Not in use yet.';
        } else {
            return `Used by ${pluralize("process", usage, true)}.`;
        }

    }

    return <span>Created about <HumanReadableDate date={createdAt}/>. {usageMessage(usage)}</span>

}

CreatedAbout.propTypes = {
    createdAt: PropTypes.string,
    usage: PropTypes.number
}