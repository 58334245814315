import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Form, Radio, Space, Select, Card, Tag} from "antd";
import {Spacer} from "../../../../DesignSystem/Spacer/Spacer";
import {DeleteUserAlert} from "./DeleteUserAlert";
import {companyService} from "../../../../../services/company.service";
import {DeleteLastUserAlert} from "./DeleteLastUserAlert";

const {Option} = Select;


export const DeleteUserForm = ({onDeleteUser, onCancel, preDeleteUser, loggedUserId}) => {

    const [form] = Form.useForm();

    const [moveToUserIdRequired, setMoveToUserIdRequired] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [lastUser, setLastUser] = useState(null);

    useEffect(() => {
        companyService.getMembers().then(users => {
            setLastUser(users.data.content.length === 1);
        });
    }, []);


    function handleDeleteTypeChange(e) {
        if (e.target.value === 'DELETE') {
            setMoveToUserIdRequired(false);
            form.setFieldsValue({moveToUserId: undefined});
        } else {
            setMoveToUserIdRequired(true);
        }
    }

    function handleDeleteUser(values) {
        if (showForm) {
            onDeleteUser({...values, userId: preDeleteUser.userId});
        } else {
            onDeleteUser({...values, userId: preDeleteUser.userId, deleteType: 'DELETE'});
        }
    }

    useEffect(() => {

        if (preDeleteUser && ((preDeleteUser.connections.length > 0) || (preDeleteUser.exports.length > 0))) {
            setShowForm(true);
        }

    }, [preDeleteUser]);

    if (lastUser) {
        return <Card><DeleteLastUserAlert/>
            <Spacer/>
            <Button htmlType="button" onClick={onCancel}>Back</Button>
        </Card>
    }

    return <Card bordered={false}>

        {!lastUser && (
        <Form layout='vertical' form={form} onFinish={handleDeleteUser} initialValues={{deleteType: 'MOVE'}}>

            <DeleteUserAlert containsEntities={showForm} preDeleteUser={preDeleteUser} loggedUserId={loggedUserId}/>

            <Spacer/>

            {preDeleteUser && (
                <div>
                    {preDeleteUser.connections.length > 0 && <>
                        <h4>Existing connections</h4>
                        {preDeleteUser.connections.map(connection => <div key={Math.random()}>{connection}</div>)}
                    </>}
                    {preDeleteUser.exports.length > 0 && <>
                        <h4>Existing exports</h4>
                        {preDeleteUser.exports.map(exportName => <div key={Math.random()}>{exportName}</div>)}
                    </>}
                </div>
            )}

            {showForm && (
                <><Spacer/><Form.Item name="deleteType" label={"Delete strategy"} required={true}>
                    <Radio.Group onChange={handleDeleteTypeChange}>
                        <Space direction="vertical">
                            <Radio value="MOVE">Delete user and assign his connections and exports to a selected user</Radio>
                            <Radio value="DELETE">Delete user and his connections and exports</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                    <Spacer/>
                    <Form.Item name="moveToUserId" label={"Assign existing connections and exports to"} rules={[{
                        required: moveToUserIdRequired,
                    }]}>
                        <Select showSearch style={{width: "100%"}} disabled={!moveToUserIdRequired}>
                            {preDeleteUser.candidates.map(candidate => <Option key={candidate.id}
                                                                               value={candidate.id}>{candidate.fullName}</Option>)}
                        </Select>
                    </Form.Item><Spacer/>
</>
            )}
            <Form.Item>
                <Button htmlType="button" onClick={onCancel}>No, keep this user</Button>{' '}
                <Button danger htmlType="submit">Yes delete this user</Button>
            </Form.Item>
        </Form>)}


    </Card>

}

DeleteUserForm.propTypes = {
    onDeleteUser: PropTypes.func.isRequired
}