import React, {useEffect, useState} from "react";
import {PageableTable} from "./PageableTable";
import {Preferences} from "./preferences/Preferences";
import PropTypes from "prop-types";

export const PageableTableWithPreferences = ({
                                                 topPadding,
                                                 showPreferences,
                                                 preferences,
                                                 savePreferences,
                                                 resetPreferences,
                                                 columns,
                                                 rowKey,
                                                 rowSelection,
                                                 fetch,
                                                 pageSize,
                                                 size,
                                                 reload,
                                                 filter,
                                                 hideOnSinglePage = true,
                                                 ...props
                                             }) => {

    const [visible, setVisible] = useState(false);
    const [finalColumns, setFinalColumns] = useState([]);

    useEffect(() => {
        setVisible(showPreferences);
    }, [showPreferences]);

    useEffect(() => {
        processColumns();
    }, [preferences]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (filter) {
            const keys = Object.keys(filter);
            const newColumns = finalColumns.map(column => {
                if (keys.includes(column.key)) {
                    column.filteredValue = filter[column.key];
                }
                return column;
            });
            setFinalColumns(newColumns);
        }
    }, [filter])

    function processColumns() {
        if (!preferences) {
            setFinalColumns(columns.filter(column => column.visible || column.hideInPreferences));
        } else {

            const visibleColumnNames = preferences
                .filter(column => column.visible)
                .map(column => column.name);

            // view actions column
            visibleColumnNames.push(...columns.filter(column => column.hideInPreferences).map(column => column.name));

            const visibleColumns = columns.filter(column => visibleColumnNames.includes(column.dataIndex));
            const sortedColumns = visibleColumnNames.map(columnName => visibleColumns.find(column => column.dataIndex === columnName));
            setFinalColumns([...sortedColumns]);
        }
    }

    function handleSavePreferences(columns, save) {
        savePreferences([...columns], save);
        setVisible(false);
        processColumns();
    }

    function handleResetToDefault() {
        resetPreferences();
        setVisible(false);
    }

    return <>
        <Preferences columns={preferences} visible={visible}
                     onOk={handleSavePreferences}
                     onResetToDefault={handleResetToDefault}
                     onCancel={() => setVisible(false)}/>
        <PageableTable columns={finalColumns} fetch={fetch} hideOnSinglePage={hideOnSinglePage} reload={reload}
                       size={size}
                       pageSize={pageSize}
                       rowSelection={rowSelection}
                       rowKey={rowKey}
                       topPadding={topPadding}
        />
    </>

}

PageableTableWithPreferences.propTypes = {
    filter: PropTypes.object.isRequired,
    topPadding: PropTypes.number,
    showPreferences: PropTypes.bool.isRequired,
    preferences: PropTypes.array.isRequired,
    savePreferences: PropTypes.func.isRequired,
    resetPreferences: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,
    rowSelection: PropTypes.object,
    fetch: PropTypes.func.isRequired,
    size: PropTypes.string,
    reload: PropTypes.bool.isRequired,
    hideOnSinglePage: PropTypes.bool
}