import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {passwordService} from "../../../../services/password.service";
import {ResetPasswordForm} from "./ResetPasswordForm";
import {getErrorFromResponse} from "../../../../utils/error.utils";

export const ResetPasswordFormContainer = () => {

    let history = useHistory();
    const location = useLocation();

    const [confirmed, setConfirmed] = useState(undefined);
    const [token, setToken] = useState(undefined);
    const [error, setError] = useState(undefined);

    function handleSubmit(values) {
        setError(undefined);
        passwordService.set(token, values.password).then(() => {
            setConfirmed("reset-finished");
        }).catch(error => {
            setError(getErrorFromResponse(error));
        });
    }

    function showLogin() {
        history.replace('login');
    }

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");
        setToken(token);

        passwordService.validateToken(token).then(() => {
            setConfirmed('valid');
        }).catch(() => {
            setConfirmed('invalid');
        })

    }, [location]);

    return <ResetPasswordForm onSubmit={handleSubmit} onLogin={showLogin} confirmed={confirmed} error={error}/>

};