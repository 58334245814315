import {Card, Result} from "antd";
import React from "react";


export const GetOfferFormConfirmation = () => {

    return <Card>
        <Result
            status="success"
            title="Request Received! Our sales team will be in touch with you shortly."
            subTitle="Thank you for reaching out."
        />
    </Card>
}