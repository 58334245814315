import React from "react";
import PropTypes from "prop-types";

export const Header = ({children}) => {

    return <>{children}</>

}

Header.propTypes = {
    children: PropTypes.any
}