import React, {useEffect} from "react";
import {Button, Card, Result} from 'antd';
import SmileOutlined from "@ant-design/icons/lib/icons/SmileOutlined";
import Center from "../../../components/DesignSystem/Center/Center";
import {useHistory} from "react-router-dom";
import {authenticationService} from "../../../services/authentication.service";

export const LogoutPage = () => {

    let history = useHistory();

    function showLogin() {
        history.replace('login');
    }

    useEffect(() => {
        authenticationService.logout().then(() => {
        })
    }, []);

    return <Center>
        <Card style={{width: '500px'}}>
            <Result
                icon={<SmileOutlined/>}
                title="You have been successfully logged out!"
                extra={<Button type="primary" htmlType="submit" onClick={showLogin}>Sign in again!</Button>}
            />
        </Card>
    </Center>

};