import {PIPEDRIVE_COOKIE} from "../../../ouath/PipedriveCallback";
import {oauthService} from "../../../../../services/oauthService";

export const finishPipedriveAppRegistrationProcess = (cookies, removeCookies, history, message) => {

    const {code, state} = cookies[PIPEDRIVE_COOKIE];

    oauthService.getOauthAccessToken(code, state, 'pipedrive').then(() => {
        history.push('/connections');
        message.info("Pipedrive connection has been created");
    }).catch((e) => {
        history.push('/connections/new?type=pipedrive');
    }).finally(() => {
        removeCookies(PIPEDRIVE_COOKIE);
    });

}