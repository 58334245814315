import React, {useEffect, useState} from "react";
import {ConfigProvider, Table} from "antd";
import PropTypes from "prop-types";

const TABLE_HEADER_HEIGHT = 17;

export const PageableTable = ({topPadding = TABLE_HEADER_HEIGHT, columns, rowKey, fetch, rowSelection, reload, pageSize = 20,
                                  hideOnSinglePage = true, renderEmpty, showHeader = true, size = 'large', ...props}) => {

    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: pageSize, showSizeChanger: false});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pagination.current, pagination.pageSize);
    }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleTableChange(pagination, filters, sorter) {
        setPagination(pagination);
        fetchData(pagination.current, pagination.pageSize, filters);
    }

    function fetchData(current, pageSize, filters) {
        setLoading(true);
        fetch(current, pageSize, filters).then(response => {
            setData(response.data.content);
            setPagination({...pagination, current: current, total: response.data.totalElements, showTotal: showTotal, hideOnSinglePage: hideOnSinglePage })
        }).finally(() => {
            setLoading(false);
        })
    }

    function showTotal(total) {
        return `Total ${total} items`;
    }

    return <ConfigProvider renderEmpty={renderEmpty && loading === false ? renderEmpty : undefined}><Table
        {...props}
        scroll={{ y: `calc(100vh - ${topPadding}em)`, x: 500 }}
        rowKey={rowKey}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        loading={loading}
        showHeader={showHeader}
        pagination={pagination}
        onChange={handleTableChange}
        size={size}
    /></ConfigProvider>

}

PageableTable.propTypes = {
    topPadding: PropTypes.number,
    columns: PropTypes.array.isRequired,
    rowKey: PropTypes.string.isRequired,
    fetch: PropTypes.func.isRequired,
    rowSelection: PropTypes.object,
    reload: PropTypes.bool,
    pageSize: PropTypes.number,
    hideOnSinglePage: PropTypes.bool,
    renderEmpty: PropTypes.func,
    showHeader: PropTypes.bool,
    size: PropTypes.string
}