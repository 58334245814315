import React from "react";
import {Dropdown} from "antd";
import {UserAvatar} from "../../DesignSystem/UserAvatar/UserAvatar";
import PropTypes from 'prop-types';

const items = [
    {
        key: '1',
        label: (
            <a rel="noopener noreferrer" href="/settings">
                Profile
            </a>
        ),
    },
    {
        key: '2',
        label: (
            <a rel="noopener noreferrer" href="/logout">
                Logout
            </a>
        ),
    }
];

export const UserMenu = ({user}) => (

    <Dropdown menu={{items,}} trigger={['click']}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="ant-dropdown-link" onClick={e => e.preventDefault()} onKeyDown={e => e.preventDefault()}>
            <UserAvatar user={user}/>
        </a>
    </Dropdown>

)

UserMenu.propTypes = {
    user: PropTypes.object.isRequired
};

