import { axiosService } from "../axios";

export const tablePreferenceService = {

    fetchTablePreference: (name) => {
        return axiosService.get(`/api/table-preferences/${name}`);
    },

    saveTablePreference: (name, columns) => {
        return axiosService.post(`/api/table-preferences/${name}`, { columns: columns});
    },

    deleteTablePreference: (name) => {
        return axiosService.delete(`/api/table-preferences/${name}`);
    }

};