import React from "react";
import {Button, Form, Input, Result, Spin} from "antd";
import {Title} from "../../../DesignSystem/Typography/Title/Title";
import {Link} from "react-router-dom";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {PublicContent} from "../PublicContent";
import {SubTitle} from "../../../DesignSystem/Typography/SubTitle/SubTitle";
import PropTypes from "prop-types";

export const ResetPasswordRequestForm = ({onSubmit, loading, resetSuccessful, error}) => {

    const [form] = Form.useForm();

    const resetDone = <PublicContent><Result
        status={"success"}
        title="A request for a password reset has been sent to your email."
        subTitle="Please check your mail box."/></PublicContent>

    if (resetSuccessful) {
        return resetDone
    } else {
        return (
            <PublicContent width={"600px"}>
                <Spin spinning={loading}>
                    <Title>Reset my password</Title>
                    <SubTitle>Do you remember your password already? <Link to="/login">Please, Sign in.</Link></SubTitle>
                    <Spacer/>
                    <AlertIfMessage message={error}/>
                    <Form
                        layout='vertical'
                        form={form}
                        onFinish={onSubmit}
                    >
                        <Form.Item name="username" label="Username" rules={[
                            {
                                type: 'email',
                                message: 'The input is not valid email!'
                            },
                            {
                                required: true,
                                message: 'Please input your username!'
                            }
                        ]}>
                            <Input placeholder="Username"/>
                        </Form.Item>
                        <Form.Item>
                            <Spacer/>
                            <Button type="primary" htmlType="submit">Reset</Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </PublicContent>
        );
    }

}

ResetPasswordRequestForm.propTypes = {
    onSubmit: PropTypes.func,
    loading: PropTypes.bool,
    resetSuccessful: PropTypes.bool,
    error: PropTypes.string
}