import React, {useEffect, useState} from "react";
import {Button, Checkbox, Modal} from "antd";
import {PreferencesList} from "./PreferencesList";
import Paragraph from "antd/es/typography/Paragraph";
import PropTypes from "prop-types";

export const Preferences = ({visible, columns, onOk, onCancel, onResetToDefault}) => {

    const [internalColumns, setInternalColumns] = useState([]);
    const [savePreferences, setSavePreferences] = useState(true);

    useEffect(() => {
        setInternalColumns(JSON.parse(JSON.stringify(columns)));
    }, [columns]);

    function handleOk() {
        onOk(internalColumns, savePreferences);
    }

    function handleColumns(columns) {
        setInternalColumns([...columns]);
    }

    return <Modal title="Preferences" open={visible} onOk={handleOk} onCancel={onCancel} footer={[
        <Checkbox key="savePreferences" checked={savePreferences} onChange={(e) => setSavePreferences(e.target.checked)}>Save on apply</Checkbox>,
        <Button key="cancel" onClick={onCancel}>Cancel</Button>,
        <Button key="reset" onClick={onResetToDefault}>Reset to Default</Button>,
        <Button key="ok"onClick={handleOk} type={"primary"}>OK</Button>
    ]}>
        <Paragraph>
            <PreferencesList columns={internalColumns} updateColumns={handleColumns}/>
        </Paragraph>
    </Modal>

}

Preferences.propTypes = {
    visible: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onResetToDefault: PropTypes.func.isRequired
}