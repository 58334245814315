import {Spacer} from "../../../../../components/DesignSystem/Spacer/Spacer";
import {Header} from "../../../../../components/DesignSystem/Header/Header";
import {Col, Row} from "antd";
import {HeaderItem} from "../../../../../components/DesignSystem/Header/HeaderItem";
import {UpperCaseText} from "../../../../../components/DesignSystem/Typography/UpperCaseText/UpperCaseText";
import {NumberFormat} from "../../../../../components/DesignSystem/NumberFormat/NumberFormat";
import React from "react";
import {ScheduledInfo} from "./ScheduledInfo";

export const StatisticsHeader = ({object, statistics, rightPanel, schedule}) => {

    return <span><Spacer size={"large"}/>
    <Header>
        <Row>
            <Col sm={20}>
                {object && (<HeaderItem label={"Object"} value={<UpperCaseText text={object}/>}/>)}
                {statistics && (<HeaderItem label={"Used runs"} value={<NumberFormat value={statistics?.runsUsed}/>}/>)}
                {statistics && (<HeaderItem label={"Exported rows"} value={<NumberFormat value={statistics?.rowsUsed}/>}/>)}
                {statistics && (<HeaderItem label={"Failed runs"} value={<NumberFormat value={statistics?.failedRuns}/>}/>)}
                <HeaderItem label={"Scheduled"} value={<ScheduledInfo schedule={schedule}/>}/>
            </Col>
            <Col sm={4} style={{textAlign: "right"}}>
                {rightPanel}
            </Col>
        </Row>
    </Header>
    <Spacer/></span>

}