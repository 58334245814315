import React, {Button, Col, Form, Input, message, Radio, Row, Switch} from "antd";
import {useEffect, useState} from "react";
import {Spacer} from "../DesignSystem/Spacer/Spacer";
import {TimezoneSelect} from "../Schedule/TimezoneSelect/TimezoneSelect";
import {CronTypePart} from "../Form/CronTypePart";
import {CronDayOfMonthPart} from "../Form/CronDayOfMonthPart";
import {CronHourPart} from "../Form/CronHourPart";
import {CronHoursPart} from "../Form/CronHoursPart";
import {CronDaysPart} from "../Form/CronDaysPart";
import * as cronUtils from "../Form/cronUtils";
import {DAYS_INDEX, HOURS_INDEX} from "../Form/cronUtils";
import style from "../Form/CronForm.module.css";
import moment from "moment/moment";
import PropTypes from "prop-types";
import {SilverTitle} from "../DesignSystem/Typography/SilverTitle/SilverTitle";
import {SwitchWithHorizontalLabel} from "../Form/SwitchWithHorizontalLabel";

const defaultValueInCronFormat = {
    enabled: true,
    timezone: moment.tz.guess(),
    cron: "* 0/15 9-18 * * MON,TUE,WED,THU,FRI"
}


export const ExportTriggerStepForm = ({data, onFinish}) => {

    const [showSchedule, setShowSchedule] = useState(false);
    const [cron, setCron] = useState(undefined);

    const [form] = Form.useForm();

    useEffect(() => {
        if (data) {
            if (data.executionType === 'SCHEDULED') {
                setFields(data.cron, data.enabled, data.timezone);
                setShowSchedule(true);
            }
        }

    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    const setFields = (cron, enabled, timezone) => {

        let cronParts = cronUtils.parseCron(cron);
        setCron(cron);

        form.setFieldsValue({
            syncEnabled: cronUtils.detectType(cronParts, data.scheduleType) !== 'MANUAL',
            type: cronUtils.detectType(cronParts, data.scheduleType),
            enabled: enabled,
            timezone: timezone,
            minute: cronParts[1],
            hour: cronParts[HOURS_INDEX],
            dayOfMonth: cronParts[3],
            days: cronParts[DAYS_INDEX]
        });

    }

    const handleFinish = (values) => {

        let v = {};

        if (values.syncEnabled) {
            v = {
                ...v, schedule: {
                    timezone: values.timezone,
                    cron: cron,
                    enabled: values.enabled
                },
                executionType: 'SCHEDULED'
            }
        } else {
            v = {...v, executionType: 'MANUAL'}
        }

        onFinish({...v, step: "export-trigger-step"}).then(() => {
            message.info("All changes have been applied");
        })
    }

    const handleTypeChange = (value) => {

        switch (value) {
            case "15":
                form.setFieldsValue(
                    {
                        minute: "0/15",
                        hour: "9-18",
                        dayOfMonth: "*"
                    }
                )
                break;
            case "30":
                form.setFieldsValue(
                    {
                        minute: "0/30",
                        hour: "9-18",
                        dayOfMonth: "*"
                    }
                )
                break;
            case "HOUR":
                form.setFieldsValue(
                    {
                        minute: "0",
                        hour: "9-18",
                        dayOfMonth: "*"
                    }
                )
                break;
            case "DAY":
                form.setFieldsValue(
                    {
                        minute: "0",
                        hour: "9",
                        dayOfMonth: "*",
                        days: "*"
                    }
                )
                break;
            case "MONTH":
                form.setFieldsValue(
                    {
                        minute: "0",
                        hour: "1",
                        dayOfMonth: "9",
                        days: "*"
                    }
                )
                break;
        }

    }

    const handleValuesChange = (values, all) => {

        if (values.hasOwnProperty("type")) {
            handleTypeChange(values.type);
        } else if (values.hasOwnProperty("syncEnabled")) {

            let value = values.syncEnabled;

            if (value === true) {
                setFields(defaultValueInCronFormat.cron, defaultValueInCronFormat.enabled,
                    defaultValueInCronFormat.timezone);
            }
            form.setFieldsValue({executionType: value === true ? 'SCHEDULED' : 'MANUAL'});

            setShowSchedule(value === true);

        } else if (values.hasOwnProperty("executionType")) {

            let value = values.executionType;

            if (value === 'SCHEDULED') {
                setFields(defaultValueInCronFormat.cron, defaultValueInCronFormat.enabled,
                    defaultValueInCronFormat.timezone);
            }
            setShowSchedule(value === 'SCHEDULED');

        }

        let cron = getCronValue(form.getFieldValue("minute"),
            form.getFieldValue("hour"), form.getFieldValue("dayOfMonth"),
            form.getFieldValue("days"));
        setCron(cron);

    }

    const getCronValue = (minute, hour, dayOfMonth, dayOfWeek) => {
        return `* ${minute} ${hour} ${dayOfMonth} * ${dayOfWeek}`;
    }

    return <Form form={form}
                 layout='vertical'
                 onFinish={handleFinish}
                 onValuesChange={handleValuesChange}
    >
        <Col sm={24}>
            <Form.Item name={"syncEnabled"} valuePropName="checked">
                <SwitchWithHorizontalLabel label={"Automatic export sync is "}
                                           checkedChildren={"Enabled"}
                                           unCheckedChildren={"Disabled"}
                />
            </Form.Item>
            {showSchedule && (
                <Row gutter={12}>
                    <Col sm={24}>
                        <Form.Item name={"type"} label={"Type"} required={true}
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please select type!',
                                       },
                                   ]}>
                            <CronTypePart scheduleType={data.scheduleType} style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col sm={12}>
                        <Form.Item name={"enabled"} label={"Scheduled job type is"} valuePropName="checked" hidden={true}>
                            <Switch checkedChildren="Enabled" unCheckedChildren="Disabled"/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item name={"minute"} hidden={true}>
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item name={"dayOfMonth"} label={"Day of month"} required={true} hidden={form.getFieldValue("type") !== 'MONTH'}>
                            <CronDayOfMonthPart/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item name={"hour"} label={"At"} required={true} hidden={form.getFieldValue("type") !== 'MONTH' && form.getFieldValue("type") !== 'DAY'}>
                            <CronHourPart/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item name={"hour"} label={"Between hours"} required={true}
                                   hidden={form.getFieldValue("type") === 'MONTH' || form.getFieldValue("type") === 'DAY'}>
                            <CronHoursPart/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item name={"days"} label={"Days of week"} hidden={form.getFieldValue("type") === 'MONTH' || form.getFieldValue("type") === 'DAY'}>
                            <CronDaysPart/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <Form.Item name={"timezone"} label={"Timezone"} required={true}>
                            <TimezoneSelect style={{width: "100%"}}/>
                        </Form.Item>
                    </Col>
                    <Col sm={24}>
                        <div className={style.label}>
                            <span>Will be triggered</span>
                        </div>
                        <SilverTitle fontSize={'12px'}>{cronUtils.convertToHumanReadable(cron)}</SilverTitle>
                    </Col>
                    <Col sm={24}>
                        <div className={style.label}>
                            Next run will be on
                        </div>
                        <SilverTitle fontSize={'12px'}>{cronUtils.convertToNextRun(cron, form.getFieldValue('timezone'))}</SilverTitle>
                    </Col>
                </Row>
            )}
            <Spacer/>
            <Form.Item shouldUpdate className="submit">
                {() => (
                    <Button
                        type="primary"
                        block
                        htmlType="submit"
                        disabled={
                            !form.isFieldsTouched() ||
                            form.getFieldsError().filter(({errors}) => errors.length)
                                .length > 0
                        }
                    >
                        Continue
                    </Button>
                )}
            </Form.Item>
        </Col>
    </Form>

}

ExportTriggerStepForm.propTypes = {
    data: PropTypes.object,
    onFinish: PropTypes.func.isRequired,
}