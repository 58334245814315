import React from 'react';
import {Button, Card, Result} from "antd";

export const PipedriveProcessCookiesDialog = ({handleCancel, handleOk}) => {

    return <Card><Result
        status="404"
        title="Finish connecting your Pipedrive account to Apimoon?"
        subTitle={"It looks like you've started the process. Would you like to complete the connection now and unlock the full potential of" +
            " seamless integration?"}
        extra={[
            <Button type="primary" onClick={handleOk}>
                Finish now
            </Button>,
            <Button onClick={handleCancel}>Finish later</Button>,
        ]}
    /></Card>

}