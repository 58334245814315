import React, {Button, Col, Dropdown, Row, Space, Typography} from "antd";
import {CloseOutlined, DownOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {TextRight} from "../../DesignSystem/Center/TextRight";
import moment from "moment";

function getDescendantProp(obj, desc) {
    const arr = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()])) ;
    return obj;
}

export const convertValue = (name, type, record) => {

    const value = getDescendantProp(record, name);

    if (value === undefined) {
        return value;
    } else if (typeof value == "boolean") {
        return value.toString();
    } else if (value instanceof Array) {
        return JSON.stringify(value);
    } else if (value instanceof Object) {
        return JSON.stringify(value);
    } else {
        return applyTypeFormat(value, type);
    }

}

export const applyTypeFormat = (value, type) => {

    try {
        if (type === undefined) {
            return value;
        } else if (type === 'datetime' && value !== null) {
            return moment(value).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return value;
        }
    } catch (e) {
        return value;
    }


}

export const createHeaderItems = (itemIndex, itemsCount) => {

    const data = [];

    if (itemIndex > 0) {
        data.push({
            key: 'moveLeft', label: 'Move Left', icon: <LeftOutlined/>
        });
    }

    if (itemIndex != itemsCount - 1) {
        data.push({
            key: 'moveRight', label: 'Move Right', icon: <RightOutlined/>
        });
    }

    data.push({
        key: 'hide', label: 'Hide', icon: <CloseOutlined/>
    });

    return data;

}

export const showHeaderMenu = (onHeaderMenuClick, payload, itemIndex, itemsCount) => {

    return <Dropdown
        menu={{
            items: createHeaderItems(itemIndex, itemsCount), onClick: (e) => {
                onHeaderMenuClick(e.key, payload, itemIndex);
            }
        }}
    >
        <a onClick={(e) => e.preventDefault()} style={{color: "black"}}>
            <Space>
                <Button icon={<DownOutlined style={{fontSize: '10px', fontWeight: 'bolder'}}/>}
                        type={"text"} size="small" onClick={(e) => e.preventDefault()}/>

            </Space>
        </a>
    </Dropdown>
}

export const columns = (mapping, onHeaderMenuClick, exportMappingType) => {

    return mapping.map((item, index) => {

        const title = exportMappingType === 'LABEL' ? item.label : item.name;

        return {
            width: 100,
            title: <Row>
                <Col sm={20}><Typography.Text ellipsis={true} title={title} className="dragHandler">{title}</Typography.Text>
                </Col>
                <Col sm={4}>
                    <TextRight>{showHeaderMenu(onHeaderMenuClick, item, index, mapping.length)}</TextRight>
                </Col>
            </Row>,
            dataIndex: item.name,
            ellipsis: {
                showTitle: false,  // Disable default browser title on hover
            },
            render: (text, record) => convertValue(item.name, item.type, record)
        }
    })
}