import React from "react";
import {columns} from "./ExportSnapshotsTable.columns";
import PropTypes from "prop-types";
import {PageableTable} from "../../DesignSystem/PageableTable/PageableTable";
import {Card, Col, Row, Switch} from "antd";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";


export const ExportSnapshotsTable = ({fetchData, reload, handleSelect, showFailedOnly, onShowFailedOnlyChange}) => {

    return <Card bordered={false}>
        <Row>
            <Col sm={12}>
                <strong>{"Export history for 24 hours"}</strong>
            </Col>
            <Col sm={12} style={{textAlign: 'right'}}>
                <>Show failed only: &nbsp;</>
                    <Switch
                        checked={showFailedOnly}
                        onChange={(e) => onShowFailedOnlyChange(e)}
                    />
            </Col>
        </Row>
        <Spacer size={"small"}/>
        <PageableTable
            rowKey="id"
            columns={columns(handleSelect)}
            fetch={fetchData}
            reload={reload}
            size={"small"}
            showHeader={false}
            pageSize={20}
        />
    </Card>

}

ExportSnapshotsTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    reload: PropTypes.bool.isRequired,
    handleSelect: PropTypes.func.isRequired
}