import {Input, Form, Checkbox, Button, InputNumber} from "antd"
import {ConnectionSelect} from "../../Connections/connection-select/ConnectionSelect";
import {SelectSupportedObject} from "../../Export/SelectSupportedObject";
import {Spacer} from "../Spacer/Spacer";
import React from "react";
import {DynamicSelect} from "./DynamicSelect";
import PropTypes from "prop-types";

// mapping of our components
const componentMapping = {
    input: Input,
    inputNumber: InputNumber,
    password: Input.Password,
    checkbox: Checkbox,
    connection: ConnectionSelect,
    supportedObjects: SelectSupportedObject,
    select: DynamicSelect
}

const getComponentByType = (type) => {

    if (type === 'connection') {
        return 'connection';
    } else if (type === 'supportedObjects') {
        return 'supportedObjects';
    } else if (type === 'password') {
        return 'password';
    } else if (type === 'checkbox') {
        return type
    } else if (type === 'select') {
        return 'select';
    } else if (type === 'number') {
        return 'inputNumber';
    } else {
        return 'input';
    }

}

export const FormElement = ({ form, label, onReload, error, required, type, name, properties, processService, style, disabled = false }) => {

    const Component = componentMapping[getComponentByType(type)];

    const rules = [{
        required
    }];

    if (type !== 'select' && type !== 'connection') {
        rules[0].type = type;
    }

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}
            style={style}
            validateStatus={error === undefined ? '' : 'error'}
            help={error}
        >
            <Component properties={properties} name={name} onReload={onReload} form={form} disabled={disabled} processService={processService} />
        </Form.Item>
    )

}

FormElement.propTypes = {
    form: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onReload: PropTypes.func.isRequired,
    error: PropTypes.string,
    required: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    properties: PropTypes.object.isRequired,
    processService: PropTypes.object.isRequired,
    style: PropTypes.object,
    disabled: PropTypes.bool
}

export const DynamicForm = ({form, onFinish, onChange, onReload, properties, processService}) => {

    return (<Form layout="vertical" form={form} onFinish={onFinish} onValuesChange={onChange}>
        {properties.map((property) => (<FormElement {...property} onReload={onReload} form={form} processService={processService} properties={property.extended} key={property.name}/>))}
        <Spacer size={"large"}/>
        <Form.Item shouldUpdate className="submit">
            {() => (
                <Button
                    type="primary"
                    block
                    htmlType="Save"
                    disabled={
                        !form.isFieldsTouched() ||
                        form.getFieldsError().filter(({errors}) => errors.length)
                            .length > 0
                    }
                >
                    Continue
                </Button>
            )}
        </Form.Item>
    </Form>)

}

DynamicForm.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onReload: PropTypes.func.isRequired,
    properties: PropTypes.array.isRequired,
    processService: PropTypes.object.isRequired
}