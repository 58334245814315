import React from "react";
import style from "./FormCard.module.css";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

export const FormCard = ({title, detail, children}) => {

    return <div className={style.default}>
        <Row>
            <Col sm={24} className={style.header}><span className={style.title}>{title}</span> <span className={style.detail}>{detail}</span></Col>
        </Row>
        <Row>
            <Col sm={24} className={style.body}>{children}</Col>
        </Row>
    </div>

}

FormCard.propTypes = {
    title: PropTypes.string.isRequired,
    detail: PropTypes.string,
    children: PropTypes.any
}