import React from "react";
import {Spin} from "antd";
import PropTypes from "prop-types";


export const Spinner = ({children, tip, spinning = false}) => {

    return <Spin tip={tip} spinning={spinning}>{children}</Spin>

}

Spinner.propTypes = {
    tip: PropTypes.string,
    spinning: PropTypes.bool,
    children: PropTypes.object.isRequired
}