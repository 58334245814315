import {FlowExistingConnections} from "./FlowExistingConnections";
import React, {useState} from "react";
import {FlowAddConnection} from "./FlowAddConnection";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import {PageHeader} from "@ant-design/pro-layout";
import {Space} from "antd";


export const FlowConnectionsStep = ({connections, onSelect}) => {

    const [mode, setMode] = useState("list");

    const onAdd = () => {
        setMode("add");
    }

    const handleBackAddConnection = () => {
        setMode("list");
    }

    return <div><Spacer/><PageHeader
        className="site-page-header-responsive"
        title={'Import data from already connected systems ...'}>
        <Spacer/>
        <FlowExistingConnections connections={connections} onSelect={onSelect}/>
        <Spacer size={"large"}/>
        <div style={{color: "gray", padding: '10px'}}>or connect to your system</div>
        <FlowAddConnection connections={connections} onSelect={onSelect} onBack={handleBackAddConnection}/>
    </PageHeader></div>

}