import React, {useState} from "react";
import {passwordService} from "../../../../services/password.service";
import {ResetPasswordRequestForm} from "./ResetPasswordRequestForm";
import {getErrorFromResponse} from "../../../../utils/error.utils";

export const ResetPasswordRequestFormContainer = () => {

    const [error, setError] = useState(undefined);
    const [resetSuccessful, setResetSuccessful] = useState(false);
    const [loading, setLoading] = useState(false);

    function handleSubmit(values) {

        setLoading(true);

        passwordService.resetPasswordRequest(values.username).then(() => {
            setResetSuccessful(true);
        }).catch(error => {
            setError(getErrorFromResponse(error));
            setResetSuccessful(false);
        }).finally(() => {
            setLoading(false);
        })

    }

    return <ResetPasswordRequestForm loading={loading} resetSuccessful={resetSuccessful} error={error} onSubmit={handleSubmit}/>

}