import {useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {userService} from "../../../../services/user.service";
import {InvitationForm} from "./InvitationForm";

export const InvitationFormContainer = () => {

    const location = useLocation();
    let history = useHistory();

    const [confirmed, setConfirmed] = useState(undefined);
    const [username, setUsername] = useState(null);
    const [company, setCompany] = useState(null);

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");

        if (token === null) {
            setConfirmed('invalid');
        } else {

            userService.validateInvitation(token).then(response => {
                const data = response.data;
                setCompany(data.company);
                setUsername(data.username)
                setConfirmed('valid');
            }).catch(() => {
                setConfirmed('invalid');
            })

        }

    }, [location]);


    function showLogin() {
        history.replace('login');
    }

    function handleSave(values) {
        userService.completeInvitation(values).then(() => {
            setConfirmed("completed");
        })
    }

    return <InvitationForm confirmed={confirmed} onSave={handleSave} onShowLogin={showLogin} username={username}
                           companyName={company}/>


}