import {axiosService} from "../axios";

export const connectionTypeService = {

    fetchConnectionTypes: () => {
        return axiosService.get(`/api/connection-types`);
    },

    fetchConnectionType: (connectionType) => {
        return axiosService.get(`/api/connection-types/${connectionType}`);
    },

}