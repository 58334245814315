import React from "react";
import {Button, Col, Form, Input, Row} from "antd";
import PropTypes from "prop-types";

export const InviteMemberForm = ({onInviteMember, companySubscriptionState}) => {

    const [form] = Form.useForm();

    return <Row justify="space-around" align="middle">
            <Col sm={12}>Invite your team members to join this apimoon organization</Col>
            <Col sm={12} style={{textAlign: 'right'}}>
                <Form form={form} name="invite_member" layout='vertical'
                      onFinish={onInviteMember}>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please insert a valid email address'
                            },
                            {
                                required: true,
                                message: 'Please input email of a user!'
                            },
                        ]}
                        style={{
                            display: 'inline-block',
                            width: "300px",
                        }}
                    >
                        <Input placeholder="Email address"/>
                    </Form.Item>
                    <Form.Item style={{
                        display: 'inline-block',
                        margin: '0 8px',
                    }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={companySubscriptionState !== "OK"}
                        >
                            Invite
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>

}

InviteMemberForm.propTypes = {
    onInviteMember: PropTypes.func.isRequired,
    companySubscriptionState: PropTypes.string.isRequired
}