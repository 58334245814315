import React from "react";
import {columns} from "./ConnectionsTable.columns";
import {ConfigProvider, Table} from "antd";
import PropTypes from "prop-types";

export const ConnectionsTable = ({handleSelect, renderEmpty, handleNameChange, data, companySubscriptionState}) => {

    return <ConfigProvider renderEmpty={renderEmpty}><Table
        rowKey="id"
        renderEmpty={renderEmpty}
        columns={columns(handleSelect, handleNameChange, companySubscriptionState)}
        dataSource={data}
        showHeader={false}
        pagination={false}
        size={"small"}
    /></ConfigProvider>

}

ConnectionsTable.propTypes = {
    handleSelect: PropTypes.func,
    renderEmpty: PropTypes.func,
    handleNameChange: PropTypes.func,
    data: PropTypes.array,
    companySubscriptionState: PropTypes.string
}