import React from "react";
import {formatDateTime, humanFormat} from "../../../utils/moment.utils";
import {Tooltip} from "antd";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import PropTypes from "prop-types";

export const FormattedDateTime = ({date, showTooltip = true}) => {

    const formattedDateTime = formatDateTime(date);

    if (formattedDateTime === 'Invalid date') {
        return <>{formattedDateTime} <Tooltip title={date}><ExclamationCircleOutlined style={{color: "#FAAE13"}}/></Tooltip></>
    } else if (showTooltip) {
        return <Tooltip title={humanFormat(date)}>{formattedDateTime}</Tooltip>
    } else {
        return formattedDateTime
    }

}

FormattedDateTime.propTypes = {
    date: PropTypes.any.isRequired,
    showTooltip: PropTypes.bool
}