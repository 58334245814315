import React from "react";
import LinkOutlined from "@ant-design/icons/lib/icons/LinkOutlined";
import {Tooltip} from "antd";
import PropTypes from "prop-types";
import {getPublicDocumentText} from "../../utils/download.utils";


export const ExportDocumentPublicUrl = ({documentPublicUrl, type}) => {

    if (documentPublicUrl) {
        return <span>, show exported data: <Tooltip title={getPublicDocumentText(type)}>
                <a href={documentPublicUrl} title={"Link to document"} target="_blank">
                <LinkOutlined title={getPublicDocumentText(type)}/></a>
            </Tooltip>
            </span>
    } else {
        return <></>
    }

}

ExportDocumentPublicUrl.propTypes = {
    documentPublicUrl: PropTypes.string,
    type: PropTypes.string
}