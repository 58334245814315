import React from "react";
import {Button, Result, Spin} from "antd";
import {PublicContent} from "../PublicContent";
import {RegistrationInputsForm} from "../registration/RegistrationInputsForm";
import PropTypes from "prop-types";

export const InvitationForm = ({confirmed, onSave, onShowLogin, username, companyName}) => {

    const inProgress = <Spin spinning={true}>
        <Result
            title="A token validation in progress."
        /></Spin>

    const invalid = <Result
        status={"error"} title="An invitation has not been confirmed."
        subTitle={"The token is not valid."}
    />

    const valid = <><Result
        status={"success"}
        title="An email has been confirmed."
        subTitle="Please finish your registration"
    >
    </Result>
        <RegistrationInputsForm onSave={onSave} username={username} companyName={companyName}/>
    </>

    const completed = <Result
        status={"success"}
        title="Your registration has been completed."
        subTitle="Please log in."
        extra={<Button type="primary" htmlType="submit" onClick={onShowLogin}>Sign in</Button>}>
    </Result>

    return <PublicContent>
        {confirmed === 'valid' && (valid)}
        {confirmed === 'invalid' && (invalid)}
        {confirmed === 'completed' && (completed)}
        {confirmed === undefined && (inProgress)}
    </PublicContent>


}

InvitationForm.propTypes = {
    confirmed: PropTypes.string,
    onSave: PropTypes.func,
    onShowLogin: PropTypes.func,
    username: PropTypes.string,
    companyName: PropTypes.string
}