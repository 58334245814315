import React, {useEffect, useState} from 'react';
import {Button, Card, Col, message, Row} from "antd";
import {PageHeader} from "@ant-design/pro-layout";
import {ExportMappingTableComponent} from "../ExportMappingTableComponent";
import {columns} from "../ExportMappingTable";
import {ExportMappingTableColumnsSelection} from "../ExportMappingTableColumnsSelection";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {BackButton} from "../../../DesignSystem/BackButton/BackButton";
import {JiraMappingTableAccessibleSources} from "./JiraMappingTableAccessibleSources";
import {FilterTypes, JiraFilters} from "./JiraFilters";
import {getJiraErrorMessages} from "../../../../utils/error.utils";


export const JiraMappingTable = ({
                                     data, mapping, onBack, onColumnMoved,
                                     onSave, onRemoved,
                                     onSelected,
                                     exportMappingType,
                                     onHeaderMenuClick,
                                     processStepsService,
                                     onChangeMapping
                                 }) => {

    const [preview, setPreview] = useState(undefined);
    const [fields, setFields] = useState(undefined);
    const [validPayload, setValidPayload] = useState(false);
    const [accessibleResources, setAccessibleResources] = useState(undefined);
    const [loadingPreview, setLoadingPreview] = useState(false);
    const [internalData, setInternalData] = useState(undefined);
    const [isJqlValid, setIsJqlValid] = useState(true);

    useEffect(() => {

        if (data === undefined) {
            return;
        }

        const internalData = data;

        if (internalData.filterType === undefined) {
            internalData.filterType = FilterTypes.FILTERS;
        }

        setInternalData(internalData);

        processStepsService.getProcessStepData("jira", "accessible-resources", {connection: data.connection}).then(response => {
            setAccessibleResources(response.data);
            loadFields(data);
        });


    }, [data]);

    const loadFields = (values) => {

        if (values.cloudid === undefined) {
            return;
        }

        setLoadingPreview(true);
        processStepsService.getProcessStepData("jira", "fields", {
            ...values,
        }).then(response => {
            setPreview(response.data.preview);
            setFields(response.data.fields);
            if (mapping.length === 0) {
                onChangeMapping(response.data.defaultMapping);
            }
            setIsJqlValid(true);
            updateValidPayload(values, true);
        }).catch(error => {
            setPreview(undefined);
            setIsJqlValid(false);
            message.error(getJiraErrorMessages(error));
            updateValidPayload(values, false);
        }).finally(() => {
            setLoadingPreview(false);
        });

    }

    const handleSelectedCloudId = (cloudid) => {
        const internalData = {...data, cloudid: cloudid};
        setInternalData(internalData);
        loadFields(internalData);
    }

    const handleFilterChange = (value, type) => {
        const updated = {...internalData, filter: value, filterType: type};
        setInternalData(updated);
        loadFields(updated);
    }

    const updateValidPayload = (value, isJqlValid) => {
        setValidPayload(value.cloudid !== undefined && value.connection !== undefined
            && value.filterType !== undefined && isJqlValid);
    }

    const handleOnSave = () => {

        const payload = {
            mapping: mapping, values: {
                ...internalData
            }
        }

        onSave(payload);
    }

    return <PageHeader className="site-page-header-responsive" title={"Data Preview"}>
        <Card style={{minHeight: '80vh'}}>
            <Row align="middle">
                <Col sm={6}>
                    <h4><BackButton onBack={onBack}/> Jira</h4>
                </Col>
                <Col sm={18}>
                    <Row>
                        <Col sm={20}>
                            {internalData?.cloudid !== undefined && fields !== undefined &&
                                <JiraFilters connectionId={data.connection}
                                             value={internalData?.filter}
                                             type={internalData?.filterType}
                                             cloudId={internalData?.cloudid}
                                             onChange={handleFilterChange}
                                />}
                        </Col>
                        <Col sm={4} style={{textAlign: 'right'}}>
                            <Button type={"primary"} onClick={handleOnSave} disabled={!validPayload}>Save and Continue</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Spacer/>
            <Row gutter={14}>
                <Col sm={6} style={{backgroundColor: '#FAFAFA', padding: '30px', borderRadius: '8px', border: '1px solid #f0f0f0'}}>
                    <Spacer border={true}/>
                    {internalData?.cloudid === undefined && (
                        <JiraMappingTableAccessibleSources accessibleResources={accessibleResources} onChange={handleSelectedCloudId}/>)}
                    {fields !== undefined && (
                        <ExportMappingTableColumnsSelection fields={fields}
                                                            mapping={mapping}
                                                            onRemoved={onRemoved}
                                                            onSelected={onSelected}/>)}
                </Col>
                <Col sm={18} style={{backgroundColor: 'white'}}>
                    {preview !== undefined && (
                        <ExportMappingTableComponent data={preview}
                                                     loading={loadingPreview}
                                                     loadingMessage={"Connecting to Jira ..."}
                                                     columns={columns(mapping, onHeaderMenuClick, exportMappingType)}
                                                     onMoved={onColumnMoved}/>)}
                </Col>
            </Row>
        </Card></PageHeader>

}