export const getErrorFromResponse = (error) => {

    return getError(error.response.data);

}

export const getError = (error) => {

    try {

        if (error.error?.message) {
            return error.error.message;
        } else if (error.message) {
            return error.message;
        } else {
            return error.error;
        }

    } catch (error) {
        return "Error: " + JSON.stringify(error);
    }

}

export const getJiraErrorMessages = (error) => {

    const finalError = getError(error.response.data);

    try {
        const errors = JSON.parse(finalError);

        if (errors.errorMessages) {
            return errors.errorMessages.join(", ");
        } else {
            return finalError;
        }

    } catch (error) {
        return finalError
    }

}