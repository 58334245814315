import React, {useMemo} from "react";
import {Col, Row, Select} from "antd";
import PropTypes from "prop-types";

const {Option} = Select;

export const CronTypePart = ({scheduleType, value, onChange}) => {

    const types = [{name: "Every 15 minutes", value: "15", scope: ["MINUTES_15"]},
        {name: "Every 30 minutes", value: "30", scope: ["MINUTES_15", "MINUTES_30"]}, {
            name: "Every Hour",
            value: "HOUR",
            scope: ["MINUTES_15", "MINUTES_30"]
        }, {
            name: "Every Day", value: "DAY", scope: ["MINUTES_15", "MINUTES_30", "DAILY"]
        }, {name: "Every Month", value: "MONTH", scope: ["MINUTES_15", "MINUTES_30", "DAILY"]}];

    const internalTypes = useMemo(() => {
        return types.map(s => {
            s.disabled = !s.scope.includes(scheduleType);
            if (s.disabled) {
                s.name = `${s.name} (not available in your subscription)`;
            }
            return s;
        });
    }, [scheduleType]);

    return <Row gutter={12}>
        <Col sm={24}>
            <Select onSelect={onChange} value={value} style={{width: "100%"}}>
                {internalTypes.map(type => <Option key={type.value} disabled={type.disabled} value={type.value}>{type.name}</Option>)}
            </Select>
        </Col>
    </Row>

}

CronTypePart.propTypes = {
    scheduleType: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}