import React from "react";
import PropTypes from "prop-types";

export const TextRight = props => {
    return (
        <div style={{textAlign: 'right'}}>
            {props.children}
        </div>
)}

TextRight.propTypes = {
    children: PropTypes.node
}