import React from "react";
import {Button, Dropdown, Modal} from "antd";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import {MoreOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

const {confirm} = Modal;

const ActionDropdown = ({handleSelect, data, items, icon}) => {

    function createMarkup(content) {
        return {__html: content};
    }

    function showConfirm(title, content, id, data) {
        confirm({
            title: title,
            icon: <ExclamationCircleOutlined/>,
            content: <div dangerouslySetInnerHTML={createMarkup(content)} />,
            onOk() {
                handleSelect(id, data);
            }
        });
    }

    function handleSelectedItem({key}) {

        const item = items.find(item => item.id === key);
        if (item.confirmation) {
            showConfirm(item.confirmation.title, item.confirmation.content, key, data);
        } else {
            handleSelect(key, data);
        }

    }

    function getMenuItems() {
        return items.filter(item => item.hasOwnProperty('visible') ? item.visible : true).map(item => {
            return { key: item.id, icon: item.icon, label: item.title, disabled: item.disabled }
        })
    }

    const getIcon = () => icon === undefined ? <MoreOutlined/> : icon

    return <Dropdown menu={{items: getMenuItems(), onClick: handleSelectedItem}}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Button type={"text"} icon={getIcon()} size={"small"}></Button>
    </Dropdown>

}

export default ActionDropdown;

ActionDropdown.propTypes = {
    handleSelect: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    icon: PropTypes.object
}