import React, {useEffect, useMemo, useState} from "react";
import {Col, message, Row, Select} from "antd";
import Dayjs from "dayjs";
import PropTypes from "prop-types";

const {Option} = Select;

export const CronHoursPart = ({value, onChange}) => {

    const [from, setFrom] = useState(undefined);
    const [to, setTo] = useState(undefined);

    const mapping = useMemo(() => {

        const hours = [];

        for (let hour = 0; hour < 24; hour++) {
            const label = Dayjs().hour(hour).minute(0).format("HH:mm")
            hours.push({value: hour.toString(), label: label});
        }

        return hours;

    }, []);


    useEffect(() => {
        const parts = value.split("-")
        setFrom(parts[0]);
        setTo(parts[1]);
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleFromChange = (value) => {

        if (parseInt(value) >= parseInt(to)) {
            message.error("From must be before To");
        } else {
            onChange(`${value}-${to}`);
        }

    }

    const handleToChange = (value) => {

        if (parseInt(value) <= parseInt(from)) {
            message.error("To must be after From");
        } else {
            onChange(`${from}-${value}`);
        }

    }

    return <Row gutter={12}>
        <Col sm={12}>
            <Select showSearch value={from} style={{width: "100%"}} onChange={handleFromChange}>
                {mapping.map(interval => <Option key={interval.value} value={interval.value}>{interval.label}</Option>)}
            </Select>
        </Col>
        <Col sm={12}>
            <Select showSearch style={{width: "100%"}} value={to} onChange={handleToChange}>
                {mapping.map(interval => <Option key={interval.value} value={interval.value}>{interval.label}</Option>)}
            </Select>
        </Col>
    </Row>

}

CronHoursPart.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}