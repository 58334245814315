import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {userService} from "../../../../services/user.service";
import {ConfirmEmailForm} from "./ConfirmEmailForm";

export const ConfirmEmailFormContainer = () => {

    const location = useLocation();
    let history = useHistory();

    const [confirmed, setConfirmed] = useState(undefined);

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("token");

        if (token === null) {
            setConfirmed('invalid');
        } else {
            userService.validate(token).then(() => {
                setConfirmed('valid');
            }).catch(() => {
                setConfirmed('invalid');
            })
        }

    }, [location]);


    function showLogin() {
        history.replace('login');
    }

    return <ConfirmEmailForm confirmed={confirmed} showLogin={showLogin}/>

}