import React from "react";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {Button, Form, Input, Spin, Switch} from "antd";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import PropTypes from "prop-types";

export const ProfileForm = ({user, error, handleSubmit, loading}) => {

    const [form] = Form.useForm();

    return <Spin spinning={loading}><AlertIfMessage message={error}/>
        <Form
            layout='vertical'
            form={form}
            onFinish={handleSubmit}
            initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                sendNotifications: user.sendNotifications
            }}
        >
            <Form.Item name="firstName" label="First name" rules={[
                {
                    required: true,
                    min: 2,
                    message: 'Please input your first name!'
                }
            ]}>
                <Input placeholder="First name"/>
            </Form.Item>
            <Form.Item name="lastName" label="Last name" rules={[{
                required: true,
                min: 2,
                message: 'Please input your last name!'
            }]}>
                <Input placeholder="Last name"/>
            </Form.Item>
            <Spacer/>
            <Form.Item name="sendNotifications" label="Send notifications" valuePropName="checked" tooltip={"We will send you notifications about failed exports and reached limits."}>
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
            </Form.Item>
            <Spacer/>
            <Form.Item>
                <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
        </Form></Spin>

}

ProfileForm.propTypes = {
    user: PropTypes.object.isRequired,
    error: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
}