import React, {useEffect, useState} from "react";
import {Spacer} from "../Spacer/Spacer";
import {Alert} from "antd";
import PropTypes from "prop-types";

export const AlertIfMessage = ({message, description, type = 'error'}) => {

    const [internalDescription, setInternalDescription] = useState(undefined);

    useEffect(() => {

        if (description === undefined) {
            return;
        } else if (typeof(description) === 'string') {
            setInternalDescription(description);
        } else {
            setInternalDescription(description.map(item => item + ". "));
        }

    }, [description]);

    if (message || description) {
        return (<>
            <Alert message={message} description={internalDescription} type={type} showIcon={true}/>
            <Spacer size={"large"}/>
        </>)
    } else {
        return ''
    }

};

AlertIfMessage.propTypes = {
    message: PropTypes.string,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    type: PropTypes.oneOf(['error', 'warning', 'success', 'info'])
}