import React from "react";
import {PageableTable} from "../../../DesignSystem/PageableTable/PageableTable";
import {columns} from "./MembersTable.columns";
import PropTypes from "prop-types";

export const MembersTable = ({fetchData, loggedUsername, onAction, reload}) => {

    return <>
        <h4>Existing users</h4>
        <PageableTable rowKey="id" columns={columns(loggedUsername, onAction)} fetch={fetchData} reload={reload} size={"large"}/></>

}

MembersTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    loggedUsername: PropTypes.string.isRequired,
    onAction: PropTypes.func.isRequired,
    reload: PropTypes.any
}