import {axiosService, axiosServiceWithoutToken} from "../axios";

export const userService = {

    me: () => {
        return axiosService.get("/api/users/me");
    },

    updateProfile: (firstName, lastName, sendNotifications) => {
        return axiosService.post("/api/users", {firstName: firstName, lastName: lastName, sendNotifications: sendNotifications});
    },

    register: (values) => {
        return axiosServiceWithoutToken.post("/api/users/register",values);
    },

    invite: (email) => {
        return axiosService.post("/api/users/invite", {email});
    },

    setNewPassword: (values) => {
        return axiosService.post("/api/users/set-new-password",values);
    },

    validate: (token) => {
        return axiosServiceWithoutToken.get(`/api/users/validate?token=${token}`);
    },

    validateInvitation: (token) => {
        return axiosServiceWithoutToken.get(`/api/users/invitation/validate?token=${token}`);
    },

    completeInvitation: (values) => {
        return axiosServiceWithoutToken.post("/api/users/invitation/complete", values);
    },

    delete: (values) => {
        return axiosService.delete('/api/users', {data : values});
    },

    preDeleteUser: (id) => {
        return axiosService.get(`/api/users/pre-delete/${id}`);
    },

    sendRequestToSales: (values) => {
        return axiosServiceWithoutToken.post(`/api/users/contact-sales`, values);
    }

};
