import {Col, Row} from "antd";
import style from "./ExportHistoryColumns.module.css";
import React from "react";

export const ExportHistoryColumns = ({name, subtitle}) =>  {

    return <Row align="middle">
        <Col flex="auto">
            <Row justify="space-between" align="middle">
                <Col>
                    <div className={style.title}>{name}</div>
                    <div className={style.subtitle}>
                        {subtitle}
                    </div>
                </Col>
            </Row>
        </Col>
    </Row>

}