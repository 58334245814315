import React, {useEffect, useMemo, useState} from "react";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Button, Card, Form} from "antd";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {FormElement} from "../../../components/DesignSystem/DynamicForm/DynamicForm";
import {connectionTypeService} from "../../../services/connectionTypeService";
import {connectionService} from "../../../services/connectionService";
import {AlertIfMessage} from "../../../components/DesignSystem/AlertIfMessage/AlertIfMessage";
import {getErrorFromResponse} from "../../../utils/error.utils";
import {oauthService} from "../../../services/oauthService";
import {ConnectionTypeSelect} from "../../../components/Connections/connection-type-select/ConnectionTypeSelect";
import {Title} from "../../../components/DesignSystem/Typography/Title/Title";
import {useLocation} from "react-router-dom";

export const NewConnectionPage = () => {

    const [form] = Form.useForm();
    const location = useLocation();

    const [error, setError] = useState(undefined);
    const [types, setTypes] = useState([]);
    const [fields, setFields] = useState([]);
    const [selectedType, setSelectedType] = useState(undefined);

    const connectionType = useMemo(() => {

        const searchParams = new URLSearchParams(location.search);
        return searchParams.has("type") ? searchParams.get("type") : undefined;

    }, []);


    useEffect(() => {

        if (connectionType !== undefined) {
            connectionTypeService.fetchConnectionTypes().then(response => {
                handleConnectionSelect(connectionType, response.data);
            })

        } else {
            connectionTypeService.fetchConnectionTypes().then(response => {
                setTypes(response.data);
            })
        }

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleConnectionSelect = (connectionType, types) => {

        const selectedConnectionType = types.find(v => v.type === connectionType);

        if (selectedConnectionType.metadata.type === "oauth") {
            oauthService.getOauthCode(selectedConnectionType.type).then(response => {
                window.location.href = response.data.url;
            })
        } else {
            connectionTypeService.fetchConnectionType(selectedConnectionType.type).then(response => {
                const properties = response.data.properties;
                setSelectedType(selectedConnectionType.type);
                setFields(properties);
            })
        }

    }

    const handleFinish = (values) => {
        setError(undefined);
        connectionService.createConnection(selectedType, values).then(() => {
            window.close();
            window.opener.location.reload();
        }).catch(error => {
            setError(getErrorFromResponse(error));
        });
    }

    if (connectionType !== undefined)  {
        return <></>
    }

    return <CenteredDesign>
        <Card>
            <Title>Create new connection</Title>
            <AlertIfMessage message={error}/>
            <Form layout='vertical' form={form} onFinish={handleFinish}>
                <Form.Item label={"Connection type"} required={true} rules={[
                    { required: true }
                ]}>
                    <ConnectionTypeSelect items={types} style={{width: "100%"}} onSelect={handleConnectionSelect}/>
                </Form.Item>
                <Spacer/>
                {fields.map((field) => (
                    <FormElement {...field} key={field.name}/>
                ))}
                <Spacer/>
                <Form.Item shouldUpdate className="submit">
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={
                                !form.isFieldsTouched() ||
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                    .length > 0
                            }
                        >
                            Save
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Card>
    </CenteredDesign>

}