import React, {useState} from "react";
import PropTypes from 'prop-types';
import styles from './InlineEdit.module.css';
import {Image} from "antd";
import pen from "../../../images/pen.svg";

const ComponentMode = {
    NORMAL: 'normal',
    EDIT: 'edit'
}

export const InlineEdit = ({value, onChange}) => {

    const [mode, setMode] = useState(ComponentMode.NORMAL);
    const [internalValue, setInternalValue] = useState(value);

    const [visibleExtra, setVisibleExtra] = useState(false);

    const handleMouseEnter = () => {
        setVisibleExtra(true);
    }

    function handleInputChange(event) {
        setInternalValue(event.target.value);
    }

    function handleKeyDown(e) {
        if (e.keyCode === 27) {
            cancelEdit();
        } else if (e.keyCode === 13) {
            setNormalMode();
            onChange(internalValue);
        }
    }

    function handleMouseLeave() {
        setInternalValue(value);
        setVisibleExtra(false)
    }

    function handleClick() {
        setEditMode();
    }

    const cancelEdit = () => {
        setNormalMode();
        setInternalValue(value);
    }

    const setNormalMode = () => {
        setMode(ComponentMode.NORMAL);
    }

    const setEditMode = () => {
        setMode(ComponentMode.EDIT);
    }

    if (mode === ComponentMode.EDIT) {
        return <input type="text" autoFocus
                      value={internalValue}
                      className={styles.inline}
                      onKeyDown={handleKeyDown} onChange={handleInputChange} onBlur={cancelEdit}/>
    } else {
        return <span style={{cursor: "pointer"}} onClick={handleClick}
                     onKeyDown={handleClick}><span onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}
                                                                                                            onKeyDown={handleClick}>{value}</span>
            {visibleExtra && (<Image src={pen} style={{marginLeft: "5px", marginTop: "-10px"}} width={15} height={15} preview={false}/>)}
        </span>
    }

}

InlineEdit.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};