import {PricingTable} from "../../../PricingTable/PricingTable";
import {CenteredDesign} from "../../../DesignSystem/CenteredDesign/CenteredDesign";
import {Card, Skeleton} from "antd";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {useEffect, useState} from "react";
import {planService} from "../../../../services/planService";
import {TextCenter} from "../../../DesignSystem/Center/TextCenter";


export const PricingContent = () => {

    const [monthlyPlans, setMonthlyPlans] = useState(undefined);
    const [annuallyPlans, setAnnuallyPlans] = useState(undefined);

    useEffect(() => {
        planService.fetchPublicPlans().then(response => {
            setMonthlyPlans(response.data.monthly);
            setAnnuallyPlans(response.data.annually);
        })
    }, []);

    if (monthlyPlans === undefined || annuallyPlans === undefined) {
        return <Skeleton/>
    }

    return <CenteredDesign sm={16} lg={20}>

        <Spacer size={"large"}/>
        <Card>
            <TextCenter>
                <h1>Let your team fully automated their data export</h1>
                <h2>Get started with a 1 month free trial — no credit card required</h2>
            </TextCenter>
        </Card>
        <Spacer size={"large"}/>
        <PricingTable monthlyPlans={monthlyPlans} annuallyPlans={annuallyPlans} user={""} customerPortal={""}/>


    </CenteredDesign>


}