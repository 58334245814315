import React from "react";
import {EyeOutlined, SlidersOutlined} from "@ant-design/icons";
import ActionDropdown from "../../../ActionDropdown/ActionDropdown";
import PropTypes from "prop-types";

export const PreferencesMenu = ({showPreferences}) => {

	function onSelect(value) {
		showPreferences(true);
	}

	return <ActionDropdown icon={<SlidersOutlined />} handleSelect={onSelect} items={[{
			title: 'Show / hide columns',
			id: 'showFields',
			icon: <EyeOutlined />
		}]}/>

}

PreferencesMenu.propTypes = {
    showPreferences: PropTypes.func.isRequired
}