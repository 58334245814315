import React from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

export const SettingsLayout = ({children}) => {

    return <Row>
        <Col sm={18} lg={18} xl={10} xxl={10}>{children}</Col>
    </Row>

}

SettingsLayout.propTypes = {
    children: PropTypes.node.isRequired
}