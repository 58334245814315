import React from "react";
import {Row, Col, List, Card} from "antd";
import {GetOfferForm} from "./GetOfferForm";
import {Title} from "../../DesignSystem/Typography/Title/Title";
import {Spacer} from "../../DesignSystem/Spacer/Spacer";
import {SubTitle} from "../../DesignSystem/Typography/SubTitle/SubTitle";
import {CheckOutlined} from "@ant-design/icons";
import PropTypes from "prop-types";

export const GetOfferFormContainer = ({onSubmit}) => {

    const data = [
        'Learn how to increase team productivity',
        'Get pricing information',
        'Explore use cases for your team'
    ];

    return <Card>
        <Row gutter={40}>
            <Col sm={12}>
                <Title>Contact our sales team</Title>
                <Spacer size={"large"}/>
                <SubTitle>We're happy to answer questions and get you acquainted with apimoon.</SubTitle>
                <Spacer size={"large"}/>
                <List
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <CheckOutlined style={{color: "green", fontSize: '16px', fontWeight: 800}} /> {item}
                        </List.Item>
                    )}
                />

            </Col>
            <Col sm={12}>
                <Row>
                    <Col sm={24}>
                        <GetOfferForm onSubmit={onSubmit}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>

};

GetOfferFormContainer.propTypes = {
    onSubmit: PropTypes.func.isRequired
}