import React, {useEffect, useState} from 'react';
import './App.css';
import './Webflow.css';
import './Terms.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import {createGlobalState} from "react-hooks-global-state";
import routes from "./routes";
import SettingsParentPage from "./views/apps/settings/SettingsParentPage";
import ParentPage from "./views/ParentPage";
import { QueryParamProvider } from 'use-query-params';
import {authenticationService} from "./services/authentication.service";
import {PublicParentPage} from "./views/apps/public/PublicParentPage";
import {userService} from "./services/user.service";
import {Skeleton} from "antd";

export const SETTINGS_SIDER_DEFAULT_VALUES = {current: 'profile', collapsed: false};
export const DEFAULT_SIDER_DEFAULT_VALUES = {collapsed: false};
export const BACKUP_SIDER_DEFAULT_VALUES = { current: 'settings', collapsed: false, hideMenu: false};

const initialState = {user: undefined,
    mock: undefined,
    settingsSider: SETTINGS_SIDER_DEFAULT_VALUES,
    backupSider: BACKUP_SIDER_DEFAULT_VALUES,
    backup: undefined,
    defaultSider: DEFAULT_SIDER_DEFAULT_VALUES
};

export const {useGlobalState} = createGlobalState(initialState);

function RouteWithSubRoutes(route) {

    if (route.private && !authenticationService.isUserLoggedIn()) {
        return (<Redirect
            to={{
                pathname: "/login"
            }}
        />)
    } else if (route.template === 'connection') {
        return (
            <Route
                exact={true}
                path={route.path}
                render={props => (<route.component {...props} routes={route.routes}/>)}
            />
        );
    } else if (route.template === 'user') {
        return (
            <Route
                exact={true}
                path={route.path}
                render={props => (
                    <SettingsParentPage>
                        <route.component {...props} routes={route.routes}/>
                    </SettingsParentPage>)}
            />
        );
    } else if (route.template === 'default') {
        return (
            <Route
                exact={true}
                path={route.path}
                render={props => (
                    <ParentPage user={route.user}>
                        <route.component {...props} routes={route.routes} user={route.user}/>
                    </ParentPage>)}
            />
        );
    } else if (route.template === 'public' || route.private === false) {
        return (
            <Route
                exact={true}
                path={route.path}
                render={props => (
                    <PublicParentPage>
                        <route.component {...props} routes={route.routes}/>
                    </PublicParentPage>)}
            />
        );
    }
}

const App = () => {

    const [user, setUser] = useState(undefined);

    useEffect(() => {

        if (window.location.pathname !== "/login" && window.location.pathname !== "/confirm-email"
            && window.location.pathname !== "/invitation-email"
            && window.location.pathname !== "/reset-password"
            && window.location.pathname !== "/reset-password-request"
            && window.location.pathname !== "/connections/oauth/pipedrive/callback") {
            userService.me().then(response => {
                setUser(response.data);
            }).catch(error => {
                window.location = "/login";
            })
        }

    }, []);

    if (window.location.pathname !== "/login" && window.location.pathname !== "/confirm-email"
        && window.location.pathname !== "/invitation-email"
        && window.location.pathname !== "/reset-password"
        && window.location.pathname !== "/reset-password-request"
        && window.location.pathname !== "/connections/oauth/pipedrive/callback" && user === undefined) {
        return <Skeleton/>
    } else {
        return <React.StrictMode>
            <BrowserRouter>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <div className="App">
                        <Switch>
                            {routes.map((route) => (
                                <RouteWithSubRoutes key={route.path} {...route} user={user} />
                            ))}
                        </Switch>
                    </div>
                </QueryParamProvider>
            </BrowserRouter>
        </React.StrictMode>
    }

};

export default App;