import React from "react";
import {Col, Row} from "antd";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {MembersFormContainer} from "../../../components/apps/settings/members/MembersForm.container";
import {PageHeader} from "@ant-design/pro-layout";
import PropTypes from "prop-types";
const MembersPage = ({user}) => {

    return <CenteredDesign>
        <Spacer size={"large"}/>
        <PageHeader className="site-page-header-responsive" title={"Users"}>
            <Row>
                <Col sm={24}>
                    <MembersFormContainer companySubscriptionState={user.companyPlan.companySubscriptionState}/>
                </Col>
            </Row>
        </PageHeader>
    </CenteredDesign>

};

export default MembersPage;

MembersPage.propTypes = {
    user: PropTypes.object.isRequired
}
