import React, {useState, useEffect} from "react";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {PageHeader} from "@ant-design/pro-layout";
import {Connections} from "../../../components/Connections/Connections";
import {Button, Empty} from "antd";
import {TextCenter} from "../../../components/DesignSystem/Center/TextCenter";
import PropTypes from "prop-types";
export const ConnectionsPage = ({user}) => {

    const [screenSize, setScreenSize] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        setScreenSize({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    const handleCreateNewConnection = () => {

        let height = 600;
        let width = 800;
        const left = (screenSize.dynamicWidth - width) / 2;
        const top = (screenSize.dynamicHeight - height) / 2;
        window.open("/connections/new", "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

    }

    const handleEditConnection = (connectionId, connectionType) => {

        let height = 600;
        let width = 800;
        const left = (screenSize.dynamicWidth - width) / 2;
        const top = (screenSize.dynamicHeight - height) / 2;
        window.open(`/connections/edit/${connectionId}/${connectionType}`, "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);


    }

    const customizeRenderEmpty = () => (
        <Empty style={{padding: "50px"}}
               imageStyle={{
                   height: 80,
               }}
               description="There are no connections defined yet">
            <Spacer/>
            <Button type={"primary"} onClick={handleCreateNewConnection}>Create a first connection</Button>
            <Spacer size={"large"}/>
            <TextCenter>If you need more info please visit our documentation about <a href={"https://www.apimoon.com/help/manage-connections"} target="_blank">How to create a
                connection.</a></TextCenter>
        </Empty>
    );

    return <CenteredDesign>
        <Spacer size={"large"}/>
        <PageHeader className="site-page-header-responsive" title={"Connections"}
                    extra={[
                        <Button key="createNewConnection" type="primary" onClick={handleCreateNewConnection}
                                disabled={user.companyPlan.companySubscriptionState !== "OK"}>
                            New connection
                        </Button>
                    ]}
        >
            <Connections onEditConnection={handleEditConnection}
                         renderEmpty={customizeRenderEmpty}
                         companySubscriptionState={user.companyPlan.companySubscriptionState}/>
        </PageHeader>
    </CenteredDesign>

}

ConnectionsPage.propTypes = {
    user: PropTypes.object.isRequired
}