import {message, Popover, Select, Spin} from "antd";
import React from "react";
import PropTypes from "prop-types";
import {connectionService} from "../../../services/connectionService";

const {Option} = Select;

const connectionPopoverContent = (
    <div>
        <div><b>Private connections</b> are exclusive to the owner.</div>
        <div><b>Shared connections</b> can be used by other company users on the export.
        </div>
    </div>
);

const OPTIONS = [
    {label: 'Private', value: 'PRIVATE'},
    {label: 'Shared', value: 'SHARED'},
];

export const ConnectionAccessSelect = ({connectionId, value}) => {

    const [loading, setLoading] = React.useState(false);
    const [internalValue, setInternalValue] = React.useState(value);

    function handleChange(value) {

        const label = OPTIONS.find(({value: optionValue}) => optionValue === value).label;

        setLoading(true);
        connectionService.updateConnectionAccess(connectionId, value).then(() => {
            message.info(`Connection access updated to ${label}`);
            setInternalValue(value);
        }).catch(() => {
            message.error(`Error updating connection access to ${label}`);
        }).finally(() => {
            setLoading(false);
        });
    }

    return <Spin spinning={loading}>
        <Popover content={connectionPopoverContent}>
            <Select value={internalValue} onChange={handleChange} style={{minWidth: "100px", minHeight: "35px"}}>
                {OPTIONS.map(({label, value}) => <Option key={value} value={value}>{label}</Option>)}
            </Select>&nbsp;
            </Popover>
    </Spin>

}

ConnectionAccessSelect.propTypes = {
    value: PropTypes.string,
    connectionId: PropTypes.string.isRequired
}