import {Alert, Tag} from "antd";
import React from "react";


export const DeleteUserAlert = ({containsEntities, preDeleteUser, loggedUserId}) => {

    const tag = (loggedUserId, preDeleteUser) => {
        return loggedUserId === preDeleteUser.userId ? <Tag color="#2db7f5">It's you</Tag> : <></>
    }

    const message = (containsEntities, preDeleteUser, loggedUserId) => {
        return containsEntities ? <>You are about to permanently delete {preDeleteUser.fullName} {tag(loggedUserId, preDeleteUser)}</> :
            <>You are about to permanently delete {preDeleteUser.fullName} {tag(loggedUserId, preDeleteUser)}</>;
    }

    return <>
        {containsEntities && (<Alert
            message={message(containsEntities, preDeleteUser, loggedUserId)}
            description="Please notice that this user has existing connections or exports. Once an user is permanently deleted it can't be restored."
            type="warning"
            showIcon
        />)}

        {!containsEntities && (<Alert
            message={message(containsEntities, preDeleteUser, loggedUserId)}
            description="Once an user is permanently deleted it can't be restored."
            type="warning"
            showIcon
        />)}
    </>

}