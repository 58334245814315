import React from "react";
import {formatDate, humanFormat} from "../../../utils/moment.utils";
import {Tooltip} from "antd";
import ExclamationCircleOutlined from "@ant-design/icons/lib/icons/ExclamationCircleOutlined";
import PropTypes from "prop-types";

export const FormattedDate = ({value, showTooltip = true}) => {

    const formattedDate = formatDate(value);

    if (formattedDate === 'Invalid date') {
        return <>{formattedDate} <Tooltip title={value}><ExclamationCircleOutlined style={{color: "#FAAE13"}}/></Tooltip></>
    } else if (showTooltip) {
        return <Tooltip title={humanFormat(value)}>{formattedDate}</Tooltip>
    } else {
        return formattedDate
    }

}

FormattedDate.propTypes = {
    value: PropTypes.any.isRequired,
    showTooltip: PropTypes.bool
}
