import React from "react";
import {Button} from "antd";
import {Spacer} from "../DesignSystem/Spacer/Spacer";
import {PageHeader} from "@ant-design/pro-layout";
import PropTypes from "prop-types";

export const ExportsDetailHeader = ({children, onCreateNewExport, companySubscriptionState}) => {

    return (
        <>
            <Spacer size={"large"}/>
            <PageHeader
                className="site-page-header-responsive"
                title={'Exports'}
                extra={[
                    <Button key="createNewExport" type="primary" onClick={onCreateNewExport} disabled={companySubscriptionState !== 'OK'}>
                        New export
                    </Button>
                ]}
            >
                {children}
            </PageHeader></>

    )

}

ExportsDetailHeader.propTypes = {
    children: PropTypes.any,
    onCreateNewExport: PropTypes.func.isRequired,
    companySubscriptionState: PropTypes.string.isRequired
}