import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Card, Skeleton} from "antd";
import {exportService} from "../../../../../../services/exportService";
import PropTypes from "prop-types";
import {ExportTriggerStep} from "../../../../../../components/Export/ExportTriggerStep";

export const ExportSchedulePanel = () => {

    let {exportId} = useParams();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(undefined);

    useEffect(() => {
        setLoading(true);
        exportService.fetchExport(exportId).then(response => {
            setData(response.data);
        }).finally(() => {
            setLoading(false);
        })
    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps

    const onFinish = values => {

        setLoading(true);

        return exportService.updateExportTriggerStep(exportId, values).then(response => {
            setData(response.data);
            window.location.reload();
        }).catch(e => {
            return Promise.reject(e);
        }).finally(() => {
            setLoading(false);
        });

    };

    if (loading) {
        return <Card><Skeleton/></Card>
    } else {
        return <ExportTriggerStep data={data} onFinish={onFinish}/>
    }

}

ExportSchedulePanel.propTypes = {
    user: PropTypes.object.isRequired
};