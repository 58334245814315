import React, {useEffect, useState} from "react";
import {Avatar} from "antd";
import PropTypes from "prop-types";

export const UserAvatar = ({user}) => {

    const [value, setValue] = useState("");

    useEffect(() => {
        if (user) {
            const firstCharacter = user.firstName && user.firstName.length > 0 ?  user.firstName[0] : "";
            const secondCharacter = user.lastName && user.lastName.length > 0 ?  user.lastName[0] : "";
            setValue(firstCharacter + secondCharacter);
        }

    }, [user]);

    return <Avatar size={"small"} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>{value}</Avatar>

}

UserAvatar.propTypes = {
    user: PropTypes.object.isRequired
}