import {Button, Col, Radio, Row, Space} from "antd";
import React from "react";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";


export const PipedriveMappingTableSelectObject = ({objects, onChange}) => {

    const [value, setValue] = React.useState(undefined);

    const handleValueChange = (e) => {
        setValue(e.target.value);
    }

    const handleChange = () => {
        onChange(value);
    }

    if (objects === undefined || objects.length === 0) {
        return <div>Loading...</div>
    }

    return (
        <Row gutter={[16, 20]}>
            <Col sm={24}>
                <strong>Choose object ...</strong>
                <Spacer border={true}/>
                <Radio.Group onChange={handleValueChange}>
                    <Space direction="vertical">
                        {objects.map(object => <Radio value={object.value} key={object.value}>{object.label}</Radio>)}
                    </Space>
                </Radio.Group>
            </Col>
            <Col sm={24}>
                <Button type="primary" block disabled={value === undefined} onClick={handleChange}>
                    Select
                </Button>
            </Col>
        </Row>
    );

}