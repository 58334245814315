import {Divider, Input, Select, message, Flex, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {PlusOutlined, ReloadOutlined} from "@ant-design/icons";
import {processStepService} from "../../../services/processStepService";
import {getErrorFromResponse} from "../../../utils/error.utils";
import {SelectButton} from "./components/SelectButton";
import PropTypes from "prop-types";
const {Option} = Select;

export const DynamicSelect = ({form, name, value, onReload, onChange, properties, disabled = false}) => {

    const [reloading, setReloading] = useState(false);

    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [internalName, setInternalName] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [add, setAdd] = useState(undefined);

    const onNameChange = (event) => {
        setInternalName(event.target.value);
    };

    useEffect(() => {
        setOptions(properties.options);
        setShowAdd(properties.hasOwnProperty("add"));
        setAdd(properties.add);
    }, [properties.options]);

    const handleRefresh = (name, value) => {
        setReloading(true);
        onReload(name).then(() => {
        }).finally(() => {
            setInternalName("");
            setReloading(false);
            if (value !== undefined) {
                // we may have problem here if we expect different type. So if form expect number
                // we should keep it as number
                onChange(value.toString());
            }
        });
    }

    const addItem = (e) => {
        e.preventDefault();

        const payload = {
            name: internalName
        }

        add.payload.forEach(payloadItem => {
            payload[payloadItem] = form.getFieldsValue()[payloadItem];
        });


        setReloading(true);

        processStepService.getProcessStepData(add.type, add.action, payload)
            .then((response) => {
                handleRefresh(name, response.data);
            }).catch(error => {
            message.error(getErrorFromResponse(error));
        }).finally(() => {
            setOpen(false);
            setInternalName("");
            setReloading(false);
        });

    };

    function getDropdownMenu(menu) {
        return <Spin spinning={reloading}>
            {menu}
            <Divider
                style={{
                    margin: '8px 0',
                }}
            />
            <span>
                                   <div>
                                       <SelectButton title={"Refresh"} onClick={() => handleRefresh(name)} icon={<ReloadOutlined/>}/>
                                   </div>
                                       <div>
                                       {showAdd && (
                                           <Flex>
                                               <Input
                                                   placeholder="Please enter name"
                                                   value={internalName}
                                                   onChange={onNameChange}
                                                   style={{width: "300px"}}
                                               />
                                               <SelectButton title={add.title} onClick={addItem} icon={<PlusOutlined/>}/>
                                           </Flex>
                                       )}
                                   </div>
                                       </span>
        </Spin>;
    }

    if (options) {
        return <Select value={value} onChange={onChange} disabled={disabled} open={open} onDropdownVisibleChange={(visible) => setOpen(visible)}
                       dropdownRender={(menu) => getDropdownMenu(menu)}>
            {options.map(option => <Option key={properties.fetch?.option === undefined ? option.value : option[properties.fetch.option.value]}
                                           value={properties.fetch?.option === undefined ? option.value : option[properties.fetch.option.value]}>
                {properties.fetch?.option === undefined ? option.label : option[properties.fetch.option.label]}</Option>)}
        </Select>
    } else {
        return <Select/>
    }

}

DynamicSelect.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onReload: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    properties: PropTypes.object.isRequired,
    disabled: PropTypes.bool
}