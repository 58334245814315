import {CenteredDesign} from "../../../DesignSystem/CenteredDesign/CenteredDesign";
import {GetOfferFormContainer} from "../../../PricingTable/contact-sales/GetOfferForm.container";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {GetOfferFormConfirmation} from "../../../PricingTable/contact-sales/GetOfferFormConfirmation";
import React, {useState} from "react";
import {userService} from "../../../../services/user.service";


export const ContactSalesContent = () => {

    const handleSubmit = (values) => {
        userService.sendRequestToSales(values).then(response => {
            setMode('CONFIRMED');
        }).catch(e => {
            console.log(e);
        })
    }

    const [mode, setMode] = useState("FORM");

    return <CenteredDesign sm={16} lg={20}>
        <Spacer size={"large"}/>
        <Spacer size={"large"}/>
        {mode === 'CONFIRMED' && (
            <GetOfferFormConfirmation/>
        )}
        {mode === 'FORM' && (
            <GetOfferFormContainer onSubmit={handleSubmit}/>
        )}
    </CenteredDesign>


}