import React, {useEffect, useState} from 'react';
import {connectionService} from "../../services/connectionService";
import {PipedriveMappingTable} from "./table/pipedrive/PipedriveMappingTable";
import {exportService} from "../../services/exportService";
import {ExportMappingStepView} from "./ExportMappingStepView";
import {useHistory} from "react-router-dom";
import {MODE} from "../DesignSystem/ProcessStep/ProcessStepHeader";
import {Spinner} from "../DesignSystem/Spinner/Spinner";
import {FlowConnectionsStep} from "./flow/FlowConnectionsStep";
import {JiraMappingTable} from "./table/jira/JiraMappingTable";


export const ExportNewFlowForm = ({data, processStepService, onModeChanged, exportMappingType}) => {

    let history = useHistory();

    const [mode, setMode] = useState(MODE.READ);
    const [connections, setConnections] = useState([]);
    const [internalData, setInternalData] = useState([]);
    const [mapping, setMapping] = useState([]);
    const [exportId, setExportId] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [source, setSource] = useState(undefined);
    const [phase, setPhase] = useState(undefined);

    useEffect(() => {

        if (data?.export?.mapping === undefined) {
            setMapping([]);
        } else {
            const values = data.flow.source.processStepValues.reduce((acc, current) => {
                acc[current.name] = current.value;
                return acc;
            }, {});
            setInternalData(values);
            setExportId(data?.export?.id);
            setMapping(data?.export?.mapping);
            setSource(data?.export?.source);
        }

    }, [data]);

    function loadConnections() {
        return connectionService.fetchConnectionsByDirection("IN").then(response => {
            setConnections(response.data);
        });
    }

    function handleChangeConnection(connection) {
        setSource(connection.type);
        setInternalData({connection: connection.id});
        onModeChanged(MODE.EDIT);
    }

    useEffect(() => {
        loadConnections();
    }, []);

    const handleHeaderMenuClick = (key, header, headerIndex) => {

        if (key === 'moveLeft' && headerIndex > 0) {
            handleColumnMoved(headerIndex, headerIndex - 1);
        } else if (key === 'moveRight' && headerIndex < mapping.length - 1) {
            handleColumnMoved(headerIndex, headerIndex + 1);
        } else if (key === 'hide') {
            handleRemoved([header.name]);
        }

    };

    const handleSelected = (values) => {
        setMapping([...mapping, ...values]);
    }

    const handleRemoved = values => {
        values.forEach(value => {
            mapping.splice(mapping.findIndex(i => i.name === value), 1);
        })
        setMapping([...mapping]);
    }

    const handleColumnMoved = (fromIndex, toIndex) => {
        const columns = [...mapping];
        const item = columns.splice(fromIndex, 1)[0];
        columns.splice(toIndex, 0, item);
        setMapping(columns);
    }

    const handleChangeMapping = (value) => {
        setMapping(value);
    }

    const handleOnSave = (values) => {

        setLoading(true);

        var payload = {};

        if (source === "jira") {
            payload = {...values, type: 'jira', name: 'Jira Export'};
        } else if (source === "pipedrive") {
            payload = {...values, type: 'pipedrive', name: 'Pipedrive Export'};
        }

        if (exportId !== undefined) {
            exportService.updateExport(exportId, payload).then(response => {
                window.location.reload();
            });
        } else {
            exportService.createExport(payload).then(response => {
                history.push(`/exports/${response.data.export.id}/edit`)
            });
        }
    }

    const handleEditFields = () => {
        setMode(MODE.EDIT);
        onModeChanged(MODE.EDIT);
    }

    const handleMappingBack = () => {
        window.location.reload();
    }

    if (data?.export?.id === undefined && internalData.connection === undefined) {
        return <FlowConnectionsStep connections={connections} onSelect={handleChangeConnection}/>
    }

    if (data?.export?.id === undefined || mode === MODE.EDIT) {

        if ("jira" === source) {
            return <JiraMappingTable data={internalData}
                                     onSelected={handleSelected}
                                     onRemoved={handleRemoved}
                                     onColumnMoved={handleColumnMoved}
                                     mapping={mapping}
                                     onBack={handleMappingBack}
                                     onSave={handleOnSave}
                                     onChangeMapping={handleChangeMapping}
                                     exportMappingType={exportMappingType}
                                     onHeaderMenuClick={handleHeaderMenuClick}
                                     processStepsService={processStepService}/>
        }

        return <PipedriveMappingTable
            mapping={mapping} data={internalData}
            onSelected={handleSelected}
            onRemoved={handleRemoved}
            onColumnMoved={handleColumnMoved}
            onChangeMapping={handleChangeMapping}
            onSave={handleOnSave}
            onBack={handleMappingBack}
            exportMappingType={exportMappingType}
            onHeaderMenuClick={handleHeaderMenuClick}
            processStepsService={processStepService}/>
    }

    return <Spinner spinning={loading}><ExportMappingStepView mapping={mapping}
                                                              exportMappingType={exportMappingType}
                                                              onEdit={handleEditFields}/></Spinner>


}