import queryString from 'query-string';
import {oauthService} from "../../../services/oauthService";
import {useEffect} from "react";
import {authenticationService} from "../../../services/authentication.service";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";

export const PIPEDRIVE_COOKIE = "pipedrive-code";

export const PipedriveCallback = ({location}) => {


    const [, setCookie] = useCookies([PIPEDRIVE_COOKIE]);

    const history = useHistory();

    const handleLoggedUserScenario = () => {

        let params = queryString.parse(location.search);

        if (params.error) {
            console.log(params.error);
            window.close();
            window.opener.location.reload();
        } else {
            oauthService.getOauthAccessToken(params.code, params.state, 'pipedrive').then(() => {

                // if the window is a popup, close it and refresh the parent window. Otherwise, redirect to /connections
                // it is not popup when it is called directly from pipedrive marketplace
                const isPopup = (window.opener && window.opener !== window && !window.menubar.visible)

                if (isPopup) {
                    window.close();
                    window.opener.location.reload();
                } else {
                    window.location = '/connections';
                }

            });
        }


    }

    const handleNotLoggedUserScenario = () => {
        let params = queryString.parse(location.search);
        setCookie(PIPEDRIVE_COOKIE, { code: params.code, state: params.state }, {path: '/'});
        history.push('/login');
    }

    useEffect(() => {

        if (authenticationService.isUserLoggedIn()) {
            handleLoggedUserScenario();
        } else {
            handleNotLoggedUserScenario();
        }

    }, [location]);

}