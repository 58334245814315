import React, {useEffect, useState} from "react";
import {PageableTableWithPreferences} from "./PageableTableWithPreferences";
import {tablePreferenceService} from "../../../services/tablePreference.service";
import PropTypes from "prop-types";

export const PageableTableWithPreferencesContainer = ({
                                                          topPadding,
                                                          preferenceName,
                                                          showPreferences,
                                                          rowSelection,
                                                          columns,
                                                          rowKey,
                                                          fetch,
                                                          size = "small",
                                                          reload,
                                                          filter,
                                                          pageSize,
                                                          hideOnSinglePage = true,
                                                          ...props
                                                      }) => {

    const [preferences, setPreferences] = useState([]);

    useEffect(() => {
        fetchPreferences();
    }, [showPreferences]); // eslint-disable-line react-hooks/exhaustive-deps


    function fetchPreferences() {

        tablePreferenceService.fetchTablePreference(preferenceName).then(response => {
            if (response.data === '') {
                setPreferences(columns
                    .filter(filterMandatoryColumns)
                    .map(column => ({name: column.dataIndex, title: column.title, visible: column.visible})));
            } else {

                const savedPreferences = response.data.columns;

                // add missing columns - start
                const columnsNames = columns.filter(filterMandatoryColumns).map(column => column.dataIndex);
                const preferenceColumnNames = savedPreferences.map(column => column.name);
                let missingColumnsInPreference = columnsNames.filter(x => !preferenceColumnNames.includes(x));

                missingColumnsInPreference.forEach(missingColumn => {
                    savedPreferences.push({name: missingColumn, visible: false});
                })
                // add missing columns - end

                const data = savedPreferences
                    // filter columns which existed before but have been removed but are saved in preferences
                    .filter(savedPreference => columns.find(column => savedPreference.name === column.dataIndex))
                    .map(savedPreference => {

                        const column = columns.find(column => savedPreference.name === column.dataIndex);

                        if (savedPreference) {
                            return ({name: column.dataIndex, title: column.title, visible: savedPreference.visible})
                        } else {
                            return ({name: column.dataIndex, title: column.title, visible: column.visible})
                        }


                    })
                setPreferences(data);
            }
        })

    }

    function filterMandatoryColumns(column) {
        return !(column.hasOwnProperty('hideInPreferences') && column.hideInPreferences);
    }

    function savePreferences(columns, save) {
        if (save) {
            tablePreferenceService.saveTablePreference(preferenceName, columns).then(() => {
                setPreferences([...columns]);
            })
        } else {
            setPreferences([...columns]);
        }
    }

    function resetPreferences() {
        tablePreferenceService.deleteTablePreference(preferenceName).then(() => {
            fetchPreferences();
        })
    }

    return <PageableTableWithPreferences fetch={fetch} columns={columns}
                                         showPreferences={showPreferences}
                                         resetPreferences={resetPreferences}
                                         preferences={preferences}
                                         size={size}
                                         reload={reload}
                                         rowKey={rowKey}
                                         rowSelection={rowSelection}
                                         filter={filter}
                                         pageSize={pageSize}
                                         savePreferences={savePreferences}
                                         topPadding={topPadding}
                                         fetchPreferences={fetchPreferences}/>

}

PageableTableWithPreferencesContainer.propTypes = {
    topPadding: PropTypes.bool,
    preferenceName: PropTypes.string,
    showPreferences: PropTypes.bool,
    rowSelection: PropTypes.object,
    columns: PropTypes.array,
    rowKey: PropTypes.string,
    fetch: PropTypes.func,
    size: PropTypes.string,
    reload: PropTypes.bool,
    filter: PropTypes.bool,
    hideOnSinglePage: PropTypes.bool,
};