import { axiosService } from "../axios";

export const passwordService = {

    resetPasswordRequest: (username) => {
        return axiosService.post("/api/password/reset", {username: username});
    },

    validateToken: (token) => {
        return axiosService.get(`/api/password/validate?token=${token}`);
    },

    set: (token, password) => {
        return axiosService.post("/api/password/set", {token, password});
    }

};
