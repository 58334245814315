import React from "react";
import {PublicContent} from "../PublicContent";
import {Result} from "antd";

export const UserDeleted = () => {

    return <PublicContent>
        <Result
            status={"success"}
            title={<>Your account has been deleted.</>}>
        </Result>
    </PublicContent>


}