import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

export const HumanReadableDate = ({date}) => {

    if (date) {
        return <>{moment(date).fromNow()}</>
    } else {
        return <>Not defined</>
    }


}

HumanReadableDate.propTypes = {
    date: PropTypes.any.isRequired
}
