import {Button, Col, Row, Tag} from "antd";
import React from "react";
import {Spacer} from "../DesignSystem/Spacer/Spacer";
import style from "./PricingTableItem.module.css"
import {TextCenter} from "../DesignSystem/Center/TextCenter";
import {CheckCircleOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";

const Price = ({price}) => {
    return <><span className={style.price}>{price}</span>/ month</>
}

Price.propTypes = {
    price: PropTypes.string.isRequired
}

const PricingTableValue = ({label, value, postfix}) => {
    return <Row className={style.pricingTableValue}>
        <Col sm={24} className={style.label}><CheckCircleOutlined style={{ fontSize: '16px', color: 'green' }} />{'  '}{label} {value} {postfix}</Col>
    </Row>
}

PricingTableValue.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    postfix: PropTypes.string.isRequired
}

export const PricingHeader = ({name, isCurrent}) => {
    return <div style={{fontWeight: "bold"}}>
        {name}
        {isCurrent && (
            <>{' '}<Tag color="#55acee">Actual plan</Tag></>
        )}
    </div>

}

PricingHeader.propTypes = {
    name: PropTypes.string.isRequired,
    isCurrent: PropTypes.bool
}

export const PricingTableItem = ({definition, customerPortal}) => {

    let history = useHistory();

    const handleSubscribe = () => {
        window.location.replace(definition.priceLink);
    }

    const handleFreeTrial = () => {
        history.push("/sign-up");
    }

    const handleSwitch = () => {
        window.location.replace(customerPortal);
    }


    return <div>
        <PricingHeader name={definition.name} isCurrent={definition.isCurrent}/>
        <Price price={definition.price}/>
        <Spacer/>
        <div style={{minHeight: "250px"}}>
            <PricingTableValue label={"Up to"} value={definition.usersLimit} postfix={"users"}/>
            <PricingTableValue label={"Up to"} value={definition.rowsLimit} postfix={"per run"}/>
            <PricingTableValue label={"Up to"} value={definition.runsLimit} postfix={"runs"}/>
            <PricingTableValue label={"Refresh data"} value={definition.scheduleType}/>
            <PricingTableValue label={"Support"} value={definition.support}/>
        </div>

        {definition.action === 'switch' && (
            <TextCenter>
                <Button style={{marginTop: 16, minWidth: "200px"}} type={"primary"} onClick={() => handleSwitch()}>Change plan</Button>
            </TextCenter>
        )}

        {definition.action === 'view' && (
            <TextCenter>
                <Button style={{marginTop: 16, minWidth: "200px"}} type={"primary"} onClick={() => handleSwitch()}>View</Button>
            </TextCenter>
        )}

        {definition.action === 'subscribe' && (
            <TextCenter>
                <Button style={{marginTop: 16, minWidth: "200px"}} type={"primary"} onClick={() => handleSubscribe()}>Subscribe</Button>
            </TextCenter>
        )}

        {definition.action === undefined && (
            <TextCenter>
                <Button style={{marginTop: 16, minWidth: "200px"}} type={"primary"} onClick={() => handleFreeTrial()}>Free Trial</Button>
            </TextCenter>
        )}


    </div>


}

PricingTableItem.propTypes = {
    definition: PropTypes.shape({
        name: PropTypes.string.isRequired,
        price: PropTypes.string.isRequired,
        usersLimit: PropTypes.string.isRequired,
        rowsLimit: PropTypes.string.isRequired,
        runsLimit: PropTypes.string.isRequired,
        scheduleType: PropTypes.string.isRequired,
        support: PropTypes.string.isRequired,
        priceLink: PropTypes.string.isRequired,
        action: PropTypes.string,
        isCurrent: PropTypes.bool
    }).isRequired,
    customerPortal: PropTypes.string.isRequired
}