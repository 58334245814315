import React, {useEffect, useState} from 'react';
import {Button, Col, ConfigProvider, Flex, Row, Table} from "antd";
import {BigColumn} from "../../DesignSystem/BigColumn/BigColumn";
import {BorderedIcon} from "../../DesignSystem/Icons/BorderedIcon";
import {connectionTypeService} from "../../../services/connectionTypeService";

export const FlowAddConnection = () => {

    const [connectionTypes, setConnectionTypes] = useState([]);


    const [screenSize, setScreenSize] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        setScreenSize({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    const handleCreateNewConnection = (connectionType) => {

        let height = 600;
        let width = 800;
        const left = (screenSize.dynamicWidth - width) / 2;
        const top = (screenSize.dynamicHeight - height) / 2;
        window.open(`/connections/new?type=${connectionType}`, "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

    }

    const loadConnectionTypes = () => {

        connectionTypeService.fetchConnectionTypes().then(response => {
            setConnectionTypes(response.data.filter(type => type.direction == "IN"));
        });

    }

    const handleConnectionTypeSelect = (type) => {
        handleCreateNewConnection(type)
    }

    useEffect(() => {
        loadConnectionTypes();
    }, []);

    const columns = () => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <BigColumn icon={<BorderedIcon image={"/connectors/" + record.type + ".svg"} size={"small"}/>}
                                                 iconSize={"50px"}
                                                 name={record.name}
                                                 extra={buttons(handleConnectionTypeSelect, record)}
                                                 onClick={e => handleCreateNewConnection(record.type)}
                                                 showOnlyOnMouseEnter={true}
                                                 type={record.type}/>
        }
    ];

    return <ConfigProvider><Table
            rowKey="type"
            dataSource={connectionTypes}
            columns={columns()}
            showHeader={false}
            pagination={false}
            size={"small"}
        /></ConfigProvider>
}

export const buttons = () => {

    return <Row align="middle" gutter={5}>
        <Col>
            <Flex align={"center"}>
                <Button type={"primary"}>Connect</Button>
                &nbsp;&nbsp;
            </Flex>
        </Col>
    </Row>

}