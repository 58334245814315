import React, {Card} from "antd";
import {useEffect, useState} from "react";
import {ExportTriggerStepForm} from "./ExportTriggerStepForm";
import PropTypes from "prop-types";

export const ExportTriggerStep = ({onFinish, data}) => {

    const [exportTriggerStepData, setExportTriggerStepData] = useState(undefined);

    useEffect(() => {
        if (data) {
            setExportTriggerStepData({
                scheduleType: data.scheduleType,
                executionType: data.export.executionType,
                enabled: data.schedule?.enabled,
                timezone: data.schedule?.timezone, cron: data.schedule?.value,
            })
        }
    }, [data]);

    return <Card>
        <ExportTriggerStepForm data={exportTriggerStepData} onFinish={onFinish}/>
    </Card>

}

ExportTriggerStep.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    defaultMode: PropTypes.string,
    subtitle: PropTypes.string,
    onShowEditMode: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onFinish: PropTypes.func.isRequired,
    data: PropTypes.object
}