import React from "react";
import {Route} from "react-router-dom";
import {Layout, Skeleton} from "antd";
import TopHeader from "../components/Header/Header/TopHeader";
import {DefaultSidebar} from "../components/apps/default/DefaultSidebar";
import {SubscriptionExpired} from "../components/Notifications/SubscriptionExpired";
import {CenteredDesign} from "../components/DesignSystem/CenteredDesign/CenteredDesign";
import {SubscriptionLimitReached} from "../components/Notifications/SubscriptionLimitReached";
import PropTypes from "prop-types";

const {Content} = Layout;

const ParentPage = ({children, ...rest}) => {

    if (rest.user === undefined) {
        return <Skeleton/>
    } else {
        return (
            layout()
        );
    }


    function layout() {

        return <Layout style={{minHeight: '100vh'}}>
            <TopHeader user={rest.user}/>
            <Layout>
                <DefaultSidebar/>
                <Layout className="apimoon-layout">
                    <Content className="site-layout-background apimoon-content">
                        <CenteredDesign>
                            <SubscriptionExpired companySubscriptionState={rest.user.companyPlan.companySubscriptionState}/>
                            <SubscriptionLimitReached subscriptionLimit={rest.user.companyPlan.subscriptionLimit}/>
                        </CenteredDesign>
                        <Route
                            {...rest}
                            render={({location}) => (
                                children
                            )
                            }
                        />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    }

};

export default ParentPage;

ParentPage.propTypes = {
    children: PropTypes.node.isRequired,
    user: PropTypes.object.isRequired
};