import React from "react";
import PropTypes from "prop-types";

export const UpperCaseText = ({text, style = 'FIRST_UPPERCASE'}) => {

    function firstCharacterUpperCase(text) {
        return text ? text.charAt(0).toUpperCase() + text.slice(1).toLowerCase() : "";
    }

    return <>{firstCharacterUpperCase(text)}</>

}

UpperCaseText.propTypes = {
    text: PropTypes.string.isRequired,
    style: PropTypes.oneOf(['ALL_UPPERCASE', 'FIRST_UPPERCASE'])
}