import React, {useEffect, useState} from "react";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {PageHeader} from "@ant-design/pro-layout";
import {Card, Col, Row, Skeleton, Statistic} from "antd";
import {PricingTable} from "../../../components/PricingTable/PricingTable";
import {SilverTitle} from "../../../components/DesignSystem/Typography/SilverTitle/SilverTitle";
import {userService} from "../../../services/user.service";
import {planService} from "../../../services/planService";

export const BillingPage = () => {

    const [user, setUser] = useState(undefined);
    const [monthlyPlans, setMonthlyPlans] = useState(undefined);
    const [annuallyPlans, setAnnuallyPlans] = useState(undefined);
    const [customerPortal, setCustomerPortal] = useState(undefined);


    useEffect(() => {
        userService.me().then(response => {
            setUser(response.data);
            planService.fetchPlans().then(response => {
                setMonthlyPlans(response.data.monthly);
                setAnnuallyPlans(response.data.annually);
                setCustomerPortal(response.data.customerPortal);
            })
        });
    }, []);

    if (user === undefined) {
        return <Skeleton/>
    } else {
        return <CenteredDesign>
            <Spacer size={"large"}/>
            <PageHeader className="site-page-header-responsive" title={"Billing"}>
                <Card>
                    <Row gutter={8}>
                        <Col span={8}>
                            <Statistic title="Current plan" value={user.companyPlan.subscriptionName}/>
                            <SilverTitle>
                                {user.companyPlan.subscriptionMessage}
                            </SilverTitle>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Users" value={user.companyPlan.usersLimit}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Runs" value={user.companyPlan.runsLimit}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Max import size" value={user.companyPlan.rowsLimit}/>
                        </Col>
                        <Col span={4}>
                            <Statistic title="Schedule" value={user.companyPlan.scheduleType}/>
                        </Col>
                    </Row>
                </Card>
                <Spacer size={"large"}/>
                <PricingTable user={user} annuallyPlans={annuallyPlans} customerPortal={customerPortal} monthlyPlans={monthlyPlans}/>
            </PageHeader>
        </CenteredDesign>
    }

}