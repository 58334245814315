import {CaretRightOutlined} from "@ant-design/icons";
import React, {useMemo} from "react";
import {Button, Tooltip} from "antd";
import PropTypes from "prop-types";

export const RunExportButton = ({record, subscriptionLimit, companySubscriptionState, onClick}) => {

    const disabled = useMemo(() => {
        return record.definitionState === 'DRAFT' || subscriptionLimit !== 'OK' || companySubscriptionState !== 'OK';
    }, [record, subscriptionLimit, companySubscriptionState]); // eslint-disable-line

    const tooltip = useMemo(() => {

        if (subscriptionLimit === 'LIMIT_REACHED') {
            return "This export can't be started because your account reached a monthly exports limit";
        } else if (subscriptionLimit !== 'OK') {
            return "This export can't be started because has invalid subscription state " + subscriptionLimit;
        } else if (companySubscriptionState === 'TRIAL_EXPIRED') {
            return "This export can't be started because your trial subscription expired."
        } else if (companySubscriptionState === 'SUBSCRIPTION_EXPIRED') {
            return "This export can't be started because your subscription expired."
        } else if (record.definitionState === "DRAFT") {
            return "This export can't be started because its configuration is not fully defined";
        } else {
            return "";
        }

    }, [record]);

    if (subscriptionLimit === undefined) {
        return "Subscription limit is not provided";
    } else if (companySubscriptionState === undefined) {
        return "Company subscription state is not provided";
    }

    return <Tooltip title={tooltip}><span>
        <Button type={"primary"} icon={<CaretRightOutlined/>} disabled={disabled} onClick={onClick}>Run</Button></span>
    </Tooltip>


}

RunExportButton.propTypes = {
    record: PropTypes.object.isRequired,
    subscriptionLimit: PropTypes.string.isRequired,
    companySubscriptionState: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
}