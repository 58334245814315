import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {Button, Form, Input, Modal} from "antd";
import React, {useState} from "react";
import {userService} from "../../../../services/user.service";
import {useHistory} from "react-router-dom";
import {authenticationService} from "../../../../services/authentication.service";
import {Spinner} from "../../../DesignSystem/Spinner/Spinner";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {getErrorFromResponse} from "../../../../utils/error.utils";

export const PASSWORD_PATTERN = "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$.%^&*-]).{8,20}$";

export const PasswordForm = () => {

    let history = useHistory();

    const [form] = Form.useForm();
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    function info() {
        Modal.info({
            title: 'Your password has been changed.',
            content: (
                <div>
                    <p>You will be now logged out. Please log in again.</p>
                </div>
            ),
            onOk() {
                authenticationService.logout().then(() => {
                    history.push("/logout");
                })
            },
        });
    }

    function handleSubmit(values) {
        setError(undefined);
        setLoading(true);
        userService.setNewPassword(values).then(() => {
            info();
        }).catch(error => {
            setError(getErrorFromResponse(error));
        }).finally(() => {
            setLoading(false);
        })
    }

    return <Spinner spinning={loading}>
        <AlertIfMessage message={error}/>
        <Form
            layout='vertical'
            form={form}
            onFinish={handleSubmit}
        >
            <Form.Item name="currentPassword" label="Current password (You must provide your current password in order to change it.)" rules={[
                {
                    required: true,
                    min: 8,
                    message: 'Please input your current password!',
                }
            ]}>
                <Input placeholder="Current password" type={"password"}/>
            </Form.Item>
            <Form.Item name="newPassword" label="Password" rules={[
                {
                    required: true,
                    min: 8,
                    pattern: PASSWORD_PATTERN
                }
            ]}>
                <Input placeholder="New password" type={"password"}/>
            </Form.Item>
            <Form.Item name="passwordConfirmation" label="Password confirmation"
                       dependencies={['newPassword']}
                       rules={[
                           {
                               required: true,
                               min: 8,
                               pattern: PASSWORD_PATTERN
                           },
                           ({getFieldValue}) => ({
                               validator(_, value) {
                                   if (!value || getFieldValue('newPassword') === value) {
                                       return Promise.resolve();
                                   }

                                   return Promise.reject(new Error('The two passwords that you entered do not match!'));
                               },
                           })
                       ]}>
                <Input placeholder="Confirm new password" type={"password"}/>
            </Form.Item>
            <Spacer/>
            <Form.Item>
                <Button type="primary" htmlType="submit">Save password</Button>
            </Form.Item>
        </Form></Spinner>

}