import React from "react";
import {Button} from "antd";
import style from "./CustomButton.module.css";
import PropTypes from "prop-types";

export const CustomButton = (props) => {

    return <Button {...props} className={style.button}>{props.children}</Button>

}

CustomButton.propTypes = {
    children: PropTypes.node
}