import React from "react";
import {Col, Row, Switch} from "antd";
import PropTypes from "prop-types";

export const SwitchWithHorizontalLabel = ({label, checked, onChange, checkedChildren, unCheckedChildren}) => {

    return <Row gutter={12}>
        <Col sm={12}>
            <label>{label}</label>
        </Col>
        <Col sm={12} style={{textAlign: 'right'}}>
            <Switch checkedChildren={checkedChildren} unCheckedChildren={unCheckedChildren} checked={checked} onChange={onChange} />
        </Col>
    </Row>

}

SwitchWithHorizontalLabel.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}