import axios from "axios";

export * from "./axios.factory";
export * from "./axios.service";

axios.defaults.baseUrl = "http://localhost:8080/api/";

axios.defaults.proxy = {
    host: "localhost",
    port: 8080
};
