import React from "react";
import {QuestionOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import {Button, Tooltip} from "antd";

export const TopHeaderHelp = () => {

    const history = useHistory();

    const handleClick = () => {

        let url = "https://www.apimoon.com/help"

        if (history.location.pathname === "/connections") {
            url = "https://www.apimoon.com/help/manage-connections"
        } else if (history.location.pathname === "/exports") {
            url = "https://www.apimoon.com/help/how-to-start-working-with-apimoon"
        } else if (history.location.pathname === "/users") {
            url = "https://www.apimoon.com/help/users"
        }

        window.open(url, "_blank");
    }

    return <a className="ant-dropdown-link" onClick={handleClick} onKeyDown={handleClick}>
        <Tooltip title={"Help"}><Button size={"small"} type="dashed" shape="circle" icon={<QuestionOutlined />}/></Tooltip>
    </a>


}