import React, {useState} from "react";
import {CenteredDesign} from "../../../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {NotificationsTable} from "../../../../../components/Notifications/NotificationsTable/NotificationsTable";
import {notificationService} from "../../../../../services/notificationService";
import {Breadcrumb} from "antd";
import {HomeBreadcrumbItem} from "../../../../../components/Breadcrumb/HomeBreadcrumbItem/HomeBreadcrumbItem";
import {useQueryParam} from "use-query-params";
import {StringParam} from "serialize-query-params";
import {normalizeFilter} from "../../../../../utils/filter.utils";
import {PreferencesMenu} from "../../../../../components/DesignSystem/PageableTable/preferences/menu/PreferencesMenu";
import {PageHeader} from "@ant-design/pro-layout";

export const NotificationsPage = () => {

    const [filter, setFilter] = useState({ level: [], type: []});
    const [visible, setVisible] = useState(false);

    const [levelParameter] = useQueryParam('level', StringParam);
    const [backupHistoryIdParameter] = useQueryParam('backupHistoryId', StringParam);
    const [typeParameter] = useQueryParam('type', StringParam);

    function fetchNotifications(page, size, filters) {
        const level = filters ? normalizeFilter(filters.level) : normalizeFilter(levelParameter);
        const type = filters ? normalizeFilter(filters.type) : typeParameter === undefined ? [] : (typeParameter === 'alert' ? ["ALERT_FIRED"] : [typeParameter]);
        const filter = {level, type};
        setFilter( filter);
        return notificationService.fetchCompanyNotifications(level, backupHistoryIdParameter, type, page, size);
    }

    return <CenteredDesign hasSiderBar={false}>
        <Breadcrumb separator=">">
            {HomeBreadcrumbItem()}
            <Breadcrumb.Item><strong>Notifications</strong></Breadcrumb.Item>
        </Breadcrumb>
        <PageHeader
            className="site-page-header-responsive"
            title={"Notifications"}
            extra={<PreferencesMenu showPreferences={() => setVisible(Math.random())}/>}
        >
            <NotificationsTable fetchData={fetchNotifications} filter={filter} showPreferences={visible}/>
        </PageHeader>
    </CenteredDesign>

}