import {axiosService} from "../axios";

export const companyService = {

    get: () => {
        return axiosService.get('/api/company');
    },

    getCompanyPlan: () => {
        return axiosService.get('/api/company/plan');
    },

    delete: (companyName) => {
        return axiosService.delete('/api/company', {data: {name: companyName}});
    },

    getMembers: () => {
        return axiosService.get('/api/company/members');
    },

    update: (id, name, exportMappingType) => {
        return axiosService.post("/api/company", {id, name, exportMappingType});
    }

};
