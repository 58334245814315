import React from "react";
import {closestCenter, DndContext, useSensor, useSensors} from "@dnd-kit/core";
import {verticalListSortingStrategy, SortableContext, arrayMove} from "@dnd-kit/sortable";
import {PreferenceListItemContext} from "./PreferenceListItemContext";
import {ExcludeCheckboxPointerSensor} from "./ExcludeCheckboxPointerSensor";
import PropTypes from "prop-types";

export const PreferencesList = ({columns, updateColumns}) => {

    const onChange = (name, value) => {
        const existingColumn = columns.find(column => column.name === name);
        if (existingColumn) {
            existingColumn.visible = value;
        }
        updateColumns(columns);
    }

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {
            const oldIndex = columns.findIndex(item => item.name === active.id)
            const newIndex = columns.findIndex(item => item.name === over.id)
            const updatedColumns = arrayMove(columns, oldIndex, newIndex)
            updateColumns(updatedColumns);
        }
    }

    const sensors = useSensors(useSensor(ExcludeCheckboxPointerSensor));

    return (
        <DndContext
            autoScroll={false}
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext items={columns.map(item => item.name)} strategy={verticalListSortingStrategy}>
                {columns.map((column) => (
                    <PreferenceListItemContext {...column} key={column.name} onChange={onChange}/>
                ))}
            </SortableContext>
        </DndContext>
    );

}

PreferencesList.propTypes = {
    columns: PropTypes.array.isRequired,
    updateColumns: PropTypes.func.isRequired
}