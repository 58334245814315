import React from "react";
import {Tag} from "antd";
import {HumanReadableDate} from "../../../DesignSystem/Moment/HumanReadableDate";
import ActionDropdown from "../../../DesignSystem/ActionDropdown/ActionDropdown";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {UpperCaseText} from "../../../DesignSystem/Typography/UpperCaseText/UpperCaseText";

export const columns = (loggedUsername, onAction) => [
    {
        title: 'Name',
        dataIndex: 'name',
        visible: true,
        render: (text, record) => {
            return <>{record.firstName} {record.lastName} {loggedUsername === record.username && (<Tag color="#2db7f5">It's you</Tag>)}</>
        }
    },
    {
        title: 'Username',
        dataIndex: 'username',
        visible: true
    },
    {
        title: 'Given access',
        dataIndex: 'createdAt',
        visible: true,
        render: (text, record) => {
            return <HumanReadableDate date={record.createdAt}/>
        }
    },
    {
        title: 'State',
        dataIndex: 'state',
        visible: true,
        render: (text, record) => {
            return <UpperCaseText text={record.state}/>
        }
    },
    {
        title: 'Action',
        key: 'actions',
        visible: true,
        width: 100,
        align: 'center',
        render: (text, record) => {
            return <ActionDropdown handleSelect={onAction} data={record} items={[
                {
                    title: 'Delete',
                    id: 'delete',
                    icon: <DeleteOutlined/>
                }
            ]}>
            </ActionDropdown>
        }
    }
];