export const normalizeFilter = (filter) => {

    if (filter) {

        if (Array.isArray(filter)) {
            return filter;
        } else {
            return [filter]
        }

    } else {
        return [];
    }

}

export const notificationLevels = [
    {text: 'Critical', value: 'CRITICAL'},
    {text: 'Info', value: 'INFO'},
    {text: 'Warning', value: 'WARNING'}];

export const notificationsTypes = [
    {text: 'Alert', value: 'ALERT_FIRED'},
    {text: 'Backup finished', value: 'BACKUP_FINISHED'}
];