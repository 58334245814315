import React from "react";
import {Card} from "antd";
import Center from "../../DesignSystem/Center/Center";
import PropTypes from "prop-types";

import style from "./PublicContent.module.css";

export const PublicContent = ({children, width = '600px'}) => {

    return <Center>
        <Card style={{width: width}} className={style.text}>
            <div style={{marginLeft: "40px", marginRight: "40px"}}>
                {children}
            </div>
        </Card>
    </Center>

}

PublicContent.propTypes = {
    width: PropTypes.string,
    children: PropTypes.any
}