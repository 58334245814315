import {notification} from "antd";

export const notificationType = (type) => {

    switch (type) {
        case "ALERT_FIRED":
            return 'Alert'
        case "BACKUP_FINISHED":
            return 'Backup finished'
        case "RESTORE_FINISHED":
            return "Restore finished"
        default:
            return type;
    }

}

export const showNotification = (message, description) => {
    notification.open({
        message: message,
        description: description
    });
};

export const showNotifications = (notifications) => {

    notifications.forEach(notification => {
        if (notification.type === "BACKUP_FINISHED" || notification.type === "RESTORE_FINISHED") {
            showNotification(notificationType(notification.type), notification.value);
        }
    })

}