import React, {useState} from "react";
import {message} from "antd";
import {ProfileForm} from "./ProfileForm";
import {userService} from "../../../../services/user.service";
import {SettingsLayout} from "../../../DesignSystem/Layouts/SettingsLayout";
import {getErrorFromResponse} from "../../../../utils/error.utils";
import PropTypes from "prop-types";

export const ProfileFormContainer = ({user}) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const handleSubmit = (values) => {

        setLoading(true);
        userService.updateProfile(values.firstName, values.lastName, values.sendNotifications).then((response) => {
            message.success('Profile changes have been saved');
        }).catch(error => {
            setError(getErrorFromResponse(error));
        }).finally(() => {
            setLoading(false);
        })

    };

    return <SettingsLayout>
        <ProfileForm user={user} loading={loading} error={error} handleSubmit={handleSubmit}/>
    </SettingsLayout>

}

ProfileFormContainer.propTypes = {
    user: PropTypes.object.isRequired
}