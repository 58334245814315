import React, {useEffect, useState} from "react";
import {companyService} from "../../../../services/company.service";
import {userService} from "../../../../services/user.service";
import {message, Skeleton, Spin} from "antd";
import {MembersTable} from "./MembersTable";
import {InviteMemberForm} from "./InviteMemberForm";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {getErrorFromResponse} from "../../../../utils/error.utils";
import PropTypes from "prop-types";
import {DeleteUserForm} from "./delete/DeleteUserForm";
import {CenteredDesign} from "../../../DesignSystem/CenteredDesign/CenteredDesign";
import {authenticationService} from "../../../../services/authentication.service";
import {useHistory} from "react-router-dom";

export const MembersFormContainer = ({companySubscriptionState}) => {

    let history = useHistory();

    const [loadingContext, setLoadingContext] = useState({loading: false, tip: undefined});
    const [reload, setReload] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [user, setUser] = useState(undefined);
    const [preDeleteUser, setPreDeleteUser] = useState(undefined);

    useEffect(() => {
        userService.me().then(response => {
            setUser(response.data);
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchMembers() {
        return companyService.getMembers();
    }

    function handleDeleteUser(values) {
        setLoadingContext({loading: true})
        userService.delete(values).then(() => {

            message.info(`User has been deleted`);

            if (preDeleteUser.userId === user.userId) {
                authenticationService.logout().then(() => {
                    history.push("/user-deleted");
                })
            } else {
                setPreDeleteUser(undefined);
                setReload(Math.random);
            }

        }).catch(error => {
            message.error(getErrorFromResponse(error));
        }).finally(() => {
            setLoadingContext({loading: false});
        });
    }

    function handleCancelDeleteUser() {
        setPreDeleteUser(undefined);
    }

    function handleAction(action, record) {

        if (action === 'delete') {
            setLoadingContext({loading: true})
            userService.preDeleteUser(record.id).then(response => {
                setPreDeleteUser(response.data);
            });
            setLoadingContext({loading: false});
        }

    }

    function handleInviteMember(values) {
        setError(undefined);
        setLoadingContext({loading: true})
        userService.invite(values.email).then(() => {
            message.info(`User ${values.email} has been invited`);
            setReload(Math.random);
        }).catch(error => {
            setError(getErrorFromResponse(error));
        }).finally(() => {
            setLoadingContext({loading: false})
        });
    }

    if (user === undefined) {
        return <Skeleton/>
    } else if (preDeleteUser !== undefined) {
        return <CenteredDesign sm={8}>
            <DeleteUserForm preDeleteUser={preDeleteUser} onDeleteUser={handleDeleteUser} onCancel={handleCancelDeleteUser} loggedUserId={user.userId}/>
        </CenteredDesign>
    } else {
        return <Spin spinning={loadingContext.loading} tip={loadingContext.tip}>
            <AlertIfMessage message={error}/>
            <InviteMemberForm onInviteMember={handleInviteMember} companySubscriptionState={companySubscriptionState}/>
            <MembersTable fetchData={fetchMembers} loggedUsername={user.username} onAction={handleAction}
                          reload={reload}/>
        </Spin>
    }

}

MembersFormContainer.propTypes = {
    companySubscriptionState: PropTypes.string.isRequired
}