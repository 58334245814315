import React from "react";
import {HumanReadableDate} from "../../DesignSystem/Moment/HumanReadableDate";
import fileSize from "filesize";
import {ScheduleOutlined, UserOutlined} from "@ant-design/icons";
import {Tooltip} from "antd";
import {StateColumn} from "./StateColumn";
import {ExportHistoryColumns} from "./ExportHistoryColumns";

function executionType(type, createdBy) {

    if (type === 'MANUAL') {
        return <>Executed by {createdBy}</>
    } else {
        return <>Executed by {createdBy}</>
    }

}

function exportedSubTitle(exportedAt, state) {
    if (state === 'STARTED') {
        return <>&nbsp;</>
    }
    return <> Exported <HumanReadableDate date={exportedAt}/></>
}

function exportedRecordsSubTitle(record) {
    if (record.state === 'STARTED') {
        return <>&nbsp;</>
    } else if (record.state === 'FAILED') {
        return <>{executionType(record.executionType, record.createdBy)}</>
    } else {
        return <>Records: {record.items}, Size: {fileSize(record.size)}, {executionType(record.executionType, record.createdBy)}</>
    }

}

export const columns = () => [
    {
        title: 'Export history for last 24 hours',
        dataIndex: 'items',
        key: 'items',
        visible: true,
        colSpan: 2,
        width: 200,
        render: (text, record) => <ExportHistoryColumns
            name={<><StateColumn record={record}/></>}
            subtitle={exportedSubTitle(record.exportedAt, record.state)}/>
    },
    {
        dataIndex: 'items2',
        key: 'items2',
        colSpan: 0,
        visible: true,
        render: (text, record) => <ExportHistoryColumns
            name={<>&nbsp;</>}
            subtitle={exportedRecordsSubTitle(record)}/>
    }
];
