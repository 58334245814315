import cronstrue from 'cronstrue';
import later from '@breejs/later';
import moment from "moment-timezone";
import {getTimezoneLabel} from "../../utils/moment.utils";

export const DAYS_INDEX = 5;
export const HOURS_INDEX = 2;

export const parseCron = (value) => {
    return value.split(" ")
}

export const detectType = (cronParts, scheduleType) => {

    if (scheduleType === "DAILY" && (cronParts[1] === "0/15" || cronParts[1] === "0/30")) {
        return null;
    }

    if (scheduleType === "MINUTES_30" && cronParts[1] === "0/15") {
        return null;
    }

    if (cronParts[1] === "0/15") {
        return "15";
    } else if (cronParts[1] === "0/30") {
        return "30";
    } else if (cronParts[HOURS_INDEX].includes("-")) {
        return "HOUR";
    } else if (cronParts[3] !== "*") {
        return "MONTH";
    } else {
        return "DAY";
    }

}

export const convertToHumanReadable = (cron) => {
    try {
        return cronstrue.toString(cron.replace("* ", ""));
    } catch (e) {
        return e.message;
    }
}

export const convertToNextRun = (cron, timezone) => {

    try {
        const cronSched = later.parse.cron(cron.replace("* ", ""));

        const currentTimeInSpecificTimezone = moment().tz(timezone);
        const formattedDate = currentTimeInSpecificTimezone.format("MM/DD/YYYY HH:mm:ss");

        later.date.timezone(true);

        const nextScheduledTime = later.schedule(cronSched).next(1, new Date(formattedDate));

        return `${moment(nextScheduledTime).format('dddd, MMMM D, YYYY H:mm A')} ${getTimezoneLabel(timezone)}`;
    } catch (e) {
        return cron;
    }


}