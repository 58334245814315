import React, {useEffect, useState} from "react";
import {Card, Col, message, Row, Skeleton} from "antd";
import {Spacer} from "../../../components/DesignSystem/Spacer/Spacer";
import {CenteredDesign} from "../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {companyService} from "../../../services/company.service";
import {EditCompanyForm} from "../../../components/apps/settings/company/EditCompanyForm";
import {DeleteCompanyForm} from "../../../components/apps/settings/company/DeleteCompanyForm";
import {authenticationService} from "../../../services/authentication.service";
import {useHistory} from "react-router-dom";
import {CollapsePanel} from "../../../components/DesignSystem/CollapsePanel/CollapsePanel";
import {SettingsLayout} from "../../../components/DesignSystem/Layouts/SettingsLayout";
import {PageHeader} from "@ant-design/pro-layout";
import {getErrorFromResponse} from "../../../utils/error.utils";
import {SubscriptionPanel} from "./panels/SubscriptionPanel";
import {userService} from "../../../services/user.service";

export const CompanyPage = () => {

    const history = useHistory();
    const [company, setCompany] = useState(undefined);
    const [user, setUser] = useState(undefined);

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        userService.me().then(response => {
            setUser(response.data);
            companyService.get().then(response => {
                setCompany(response.data);
            });
        });

    }, []);

    const handleSubmit = (values) => {

        setLoading(true);

        companyService.update(company.id, values.name, values.exportMappingType).then((response) => {
            message.success('Company changes have been saved');
            setCompany(response.data);
            const updatedUser = {...user, exportMappingType: values.exportMappingType};
            setUser(updatedUser);
        }).catch(error => {
            setError(getErrorFromResponse(error));
        }).finally(() => {
            setLoading(false);
        })

    };

    const handleDeleteCompany = (companyName) => {

        setLoading(true);

        companyService.delete(companyName).then(() => {
            authenticationService.logout().then(() => {
                history.replace(`/company-deleted`);
            });
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <CenteredDesign>
            <Spacer/>
            <Row justify={'center'}>
                <Col sm={20}>
                    <PageHeader className="site-page-header-responsive" title={"Company"}>
                        <Card>
                            {company === undefined ? <Skeleton/> : null}
                            {company !== undefined && (<>
                                <SettingsLayout>
                                    <EditCompanyForm handleSubmit={handleSubmit} error={error}
                                                     company={company} loading={loading}/>
                                </SettingsLayout>
                                <Spacer border={true}/>
                                <CollapsePanel title={"Subscription"} subtitle={"You can see your subscription here"}>
                                    <SubscriptionPanel companyPlan={user.companyPlan}/>
                                </CollapsePanel>
                                <Spacer border={true}/>
                                <CollapsePanel title={"Advanced"} subtitle={"You can delete your company here"}>
                                    <DeleteCompanyForm companyName={company.name} companyPlan={user.companyPlan} onDeleteCompany={handleDeleteCompany}/>
                                </CollapsePanel></>)
                            }
                        </Card>
                    </PageHeader>
                </Col>
            </Row>

        </CenteredDesign>
    )

};