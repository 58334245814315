import {axiosService} from "../axios";

export const stepTypeService = {

    fetchStepTypes: () => {
        return axiosService.get(`/api/step-types`)
    },

    fetchStepTypeInput: () => {
        return axiosService.get(`/api/step-types/input`)
    },

    fetchStepType: (stepType) => {
        return axiosService.get(`/api/step-types/${stepType}`);
    },

}