import React from "react";
import {Col, Row} from "antd";
import PropTypes from "prop-types";

export const CenteredDesign = ({children, sm = 24, lg = 20, className}) => {

    return <Row justify="center" className={className}>
        <Col sm={sm} lg={lg} xl={lg} >
            {children}
        </Col>
    </Row>

}

CenteredDesign.propTypes = {
    children: PropTypes.node.isRequired,
    sm: PropTypes.number,
    lg: PropTypes.number,
    className: PropTypes.string
}