import React from "react";
import {columns} from "./NotificationsTable.columns";
import {PageableTableWithPreferencesContainer} from "../../DesignSystem/PageableTable/PageableTableWithPreferences.container";
import PropTypes from "prop-types";

export const NotificationsTable = ({fetchData, filter, showPreferences}) => {

    return <PageableTableWithPreferencesContainer
            rowKey="id"
            columns={columns(filter)}
            fetch={fetchData}
            filter={filter}
            showPreferences={showPreferences}
            preferenceName={'notifications'}
        />

}

NotificationsTable.propTypes = {
    fetchData: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    showPreferences: PropTypes.bool
}