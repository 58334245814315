import React, {useEffect, useState} from "react";
import {Col, Row, Spin, Table} from "antd";
import {Resizable} from "react-resizable";
import ReactDragListView from "react-drag-listview";
import PropTypes from "prop-types";
import Center from "../../DesignSystem/Center/Center";

const ResizableTitle = (props) => {
    const {onResize, width, ...restProps} = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    onKeyDown={(e) => {
                        e.stopPropagation()
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{enableUserSelectHack: false}}
        >
            <th {...restProps} />
        </Resizable>
    );
};

ResizableTitle.propTypes = {
    onResize: PropTypes.func.isRequired,
    width: PropTypes.number
}

export const ExportMappingTableComponent = ({data, loading, columns, onMoved, loadingMessage}) => {

    const [internalColumns, setInternalColumns] = useState(undefined);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const columns = [...internalColumns];
            const item = columns.splice(fromIndex, 1)[0];
            columns.splice(toIndex, 0, item);
            onMoved(fromIndex, toIndex);
            setInternalColumns(columns);
        },
        nodeSelector: "th",
        handleSelector: ".dragHandler",
        ignoreSelector: "react-resizable-handle"
    };


    const components = {
        header: {
            cell: ResizableTitle
        }
    };

    useEffect(() => {

        const ress = columns.map((col, index) => ({
            ...col,
            onHeaderCell: (column) => ({
                width: column.width,
                onResize: handleResize(index)
            }),
            className: "preview-table-column"
        }));

        setInternalColumns(ress);

    }, [columns]);

    const handleResize = (index) => (e, {size}) => {
        setInternalColumns((columns) => {
            const nextColumns = [...columns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width
            };
            return nextColumns;
        });
    };

    if (loading === true) {
        return <Center>
            <Row>
                <Col sm={24}><Spin/>&nbsp;&nbsp;<strong>{loadingMessage}</strong></Col>
            </Row></Center>
    }

    return (
        <ReactDragListView.DragColumn {...dragProps}>
            <Table
                size={"small"}
                rowClassName={"preview-table-row"}
                style={{minHeight: '50vh', fontSize: '12px'}}
                bordered
                scroll={{y: '70vh', x: "max-content"}}
                pagination={false}
                components={components}
                columns={internalColumns}
                dataSource={data}
            />
        </ReactDragListView.DragColumn>)
}

ExportMappingTableComponent.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    onMoved: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.string.isRequired
}