import {Button, Flex, message} from "antd";
import style from "./CronDays.module.css";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";


export const CronDaysPart = ({value, onChange}) => {

    const DAYS = [
        {code: "MON"},
        {code: "TUE"},
        {code: "WED"},
        {code: "THU"},
        {code: "FRI"},
        {code: "SAT"},
        {code: "SUN"}
    ]

    const [internalValue, setInternalValue] = useState(undefined);

    useEffect(() => {
        if (value === "*") {
            setInternalValue(DAYS.map(v => v.code).join(","));
        } else {
            setInternalValue(value);
        }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

    const detectButtonStyle = (code) => {

        if (internalValue === undefined) {
            return "default";
        } else if (internalValue === "*") {
            return "primary";
        } else {
            return internalValue.includes(code) ? "primary" : "default";
        }

    }

    const toggleDay = (e, code) => {

        let values = internalValue.split(",");

        if (values.includes(code)) {
            values = values.filter(item => item !== code)
        } else {
            values.push(code);
        }

        if (values.length === 0) {
            message.warning('At least one day must be selected.');
        } else if (values.length === 7) {
            onChange("*");
        } else {
            // insert values in right order
            onChange(DAYS.filter(day => values.includes(day.code))
                .map(day => day.code).join(","));
        }

    }

    return <Flex wrap="wrap" gap="small" className="site-button-ghost-wrapper">
        {DAYS.map(day =>
            <Button className={style.button} key={day.code} type={detectButtonStyle(day.code)}
                    onClick={e => toggleDay(e, day.code)}>{day.code}</Button>)}
    </Flex>

}

CronDaysPart.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}