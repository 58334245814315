import {axiosService} from "../axios";

export const oauthService = {

    getOauthAccessToken: (code, connectionId, type) => {

        const data = {code: code};

        if (connectionId) {
            data.connectionId = connectionId;
        }

        return axiosService.post(`/api/oauth/${type}/access-token`, data);
    },

    getOauthCode: (type, connectionId) => {

        if (connectionId) {
            return axiosService.get(`/api/oauth/${type}/code?connectionId=${connectionId}`);
        } else {
            return axiosService.get(`/api/oauth/${type}/code`);
        }

    }
}