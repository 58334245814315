import React, {useState} from "react";
import {Form, Input, Button, Row, Col, Alert} from 'antd';
import {authenticationService} from "../../../../services/authentication.service";
import {Title} from "../../../DesignSystem/Typography/Title/Title";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {Link} from "react-router-dom";
import {userService} from "../../../../services/user.service";
import {Spinner} from "../../../DesignSystem/Spinner/Spinner";
import {getErrorFromResponse} from "../../../../utils/error.utils";
import {SubTitle} from "../../../DesignSystem/Typography/SubTitle/SubTitle";
import {PublicContent} from "../PublicContent";
import {useCookies} from "react-cookie";
import {PIPEDRIVE_COOKIE} from "../../../../views/apps/ouath/PipedriveCallback";

const LoginForm = () => {

    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const [cookies] = useCookies([PIPEDRIVE_COOKIE]);

    const [form] = Form.useForm();

    function handleSubmit(values) {

        setLoading(true);

        authenticationService
            .executeBasicAuthenticationService(
                values.username,
                values.password
            ).then(response => {

            authenticationService.registerSuccessfulLoginForJwt(response.data.token);

            userService.me().then(response => {
                window.location = "/exports";
            }).finally(() => {
                setLoading(false);
            });

        }).catch(error => {

            if (error.response.status === 401) {
                setError(getErrorFromResponse(error));
            } else {
                setError('Login service is not available');
            }

        }).finally(() => {
            setLoading(false);
        })
    }

    return <>
        <PublicContent>
            {cookies[PIPEDRIVE_COOKIE] && <Alert type={"warning"}
                                                 showIcon={true}
                                                 message={"Apimoon marketplace application info"}
                                                 description="To complete the linking of your Pipedrive account with Apimoon, simply log
            in or sign up."/>}
            <Spinner spinning={loading}>
                <Title>Sign in</Title>
                <SubTitle>Don't have an account? <Link to="/sign-up">Please, Sign up.</Link></SubTitle>
                <Spacer/>
                <AlertIfMessage message={error}/>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={handleSubmit}
                >
                    <Form.Item name="username" label="Username" rules={[
                        {
                            required: true
                        },
                        {
                            type: 'email',
                            message: 'Please insert a valid email address',
                        },
                    ]}>
                        <Input placeholder="Username"/>
                    </Form.Item>
                    <Form.Item name="password" label="Password" rules={[{required: true}]}>
                        <Input placeholder="Password" type={"password"}/>
                    </Form.Item>
                    <Form.Item>
                        <Spacer/>
                        <Row justify={"middle"}>
                            <Col span={8}>
                                <Button type="primary" htmlType="submit">Sign In</Button>
                            </Col>
                            <Col span={8} offset={8} style={{textAlign: 'right'}}>
                                <Link to="/reset-password-request">Forgot password?</Link>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Spinner>
        </PublicContent></>
};

export default LoginForm;
