import React, {useState} from "react";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {Alert, Button, Form, Input, Tag} from "antd";
import {CustomCard} from "../../../DesignSystem/CustomCard/CustomCard";
import PropTypes from "prop-types";

export const DeleteCompanyForm = ({onDeleteCompany, companyName, companyPlan}) => {

    const [showConfirmationForm, setShowConfirmationForm] = useState(false);

    function handleNo() {
        setShowConfirmationForm(false);
    }

    function onDeleteMyAccount(values) {
        onDeleteCompany(values.company);
    }

    const subscriptionActiveMessage = () => {

        return <>Your subscription is still active and marked for renewal. Please use this <a href={companyPlan.customerPortalUrl} target="_blank">link</a> to log into your
            Stripe account and cancel your subscription.</>

    }

    function isSubscriptionActive() {
        console.log(companyPlan);
        return companyPlan.subscriptionState === "active" && companyPlan.subscriptionCancelAtPeriodEnd === false;
    }

    return <CustomCard title={"Delete my company"} bordered={false}>
            {!showConfirmationForm && (<>
                    <p>This action will <strong>delete all data, backups, snapshots and users</strong>.</p>
                    <p><strong>Are you ABSOLUTELY SURE you wish to delete your whole company?</strong></p>
                    <Form layout='vertical'>
                        <Form.Item>
                            <Button danger htmlType="button" onClick={() => setShowConfirmationForm(true)}>Delete my
                                company</Button>
                        </Form.Item>
                    </Form>
                </>
            )}
            {showConfirmationForm && (
                <Form layout='vertical' onFinish={onDeleteMyAccount}>
                    <Alert
                        message="You are about to permanently delete your company"
                        description="Once a company is permanently deleted, it cannot be recovered. This action will immediately delete all data, backups, users, and related resources, including snapshots and statistics. Please note that this action is irreversible."
                        type="warning"
                        showIcon
                    />
                    <Spacer/>

                    {isSubscriptionActive() && (<Alert
                        message="Your subscription is active"
                        description={subscriptionActiveMessage()}
                        type="warning"
                        showIcon
                    />)}
                    <h4>Please type the following to confirm:</h4>

                    <Tag>{companyName}</Tag>
                    <Spacer/>

                    <Form.Item name="company" rules={[
                        {
                            required: true,
                            message: `Please fill in ${companyName}`
                        }, ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || value === companyName) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(`Please fill in ${companyName}`));
                            },
                        })
                    ]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="button" onClick={handleNo}>No, keep my company</Button>{' '}
                        <Button danger htmlType="submit">Yes delete my company</Button>
                    </Form.Item>
                </Form>
            )}
        </CustomCard>

}

DeleteCompanyForm.propTypes = {
    deleteCompany: PropTypes.func.isRequired,
    companyName: PropTypes.string.isRequired
}