import React, {useState, useEffect} from "react";
import {PricingTableItem} from "./PricingTableItem";
import {Col, Row, Radio, Skeleton, Card} from "antd";
import {TextCenter} from "../DesignSystem/Center/TextCenter";
import {Spacer} from "../DesignSystem/Spacer/Spacer";
import {CustomPricingTableItem} from "./CustomPricingTableItem";
import PropTypes from "prop-types";

export const PricingTable = ({monthlyPlans, annuallyPlans, user, customerPortal, mode = 'public'}) => {

    const [payment, setPayment] = useState("MONTHLY");

    useEffect(() => {
        if (mode !== 'public') {
            setPayment(user.companyPlan.subscriptionBilling);
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    function handlePaymentChange(event) {
        setPayment(event.target.value);
    }


    if (monthlyPlans === undefined) {
        return <Skeleton/>
    } else {
        return <>
            {/*<GetOfferFormContainer/>*/}
            <Row>
            <Col sm={24}>
                <TextCenter>
                    <Radio.Group value={payment} onChange={handlePaymentChange}>
                        <Radio.Button value="MONTHLY">Monthly Plans</Radio.Button>
                        <Radio.Button value="ANNUALLY">Annual Plans</Radio.Button>
                    </Radio.Group>
                </TextCenter>
                <Spacer size={"large"}/>
            </Col>
            {payment === 'MONTHLY' && (
                <Col sm={24}>
                    <Card>
                        <Row gutter={10}>
                            {monthlyPlans.map(plan =>
                                <Col sm={6} key={plan.id} style={{borderRight: '1px solid #F0F0F0', paddingLeft: '10px', paddingRight: '10px'}}>
                                    <PricingTableItem definition={plan} customerPortal={customerPortal}/>
                                </Col>
                            )}
                            <Col sm={6} style={{paddingLeft: '10px', paddingRight: '10px'}}>
                                <CustomPricingTableItem title={"Enterprise"} color={"#9254de"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            )}
            {payment === 'ANNUALLY' && (
                <Col sm={24}>
                    <Card>
                        <Row gutter={10}>
                            {annuallyPlans.map(plan =>
                                <Col sm={6} key={plan.id} style={{borderRight: '1px solid #F0F0F0', paddingLeft: '10px', paddingRight: '10px'}}>
                                    <PricingTableItem definition={plan} customerPortal={customerPortal}/>
                                </Col>
                            )}
                            <Col sm={6} style={{paddingLeft: '10px', paddingRight: '10px'}}>
                                <CustomPricingTableItem title={"Enterprise"} color={"#9254de"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            )}
        </Row></>
    }

}

PricingTable.propTypes = {
    monthlyPlans: PropTypes.array,
    annuallyPlans: PropTypes.array,
    user: PropTypes.object,
    customerPortal: PropTypes.bool,
    mode: PropTypes.string
}