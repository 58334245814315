import React from 'react';
import {Button, Card, Col, Flex, Row, Tag} from "antd";
import {TableOutlined} from "@ant-design/icons";
import {Spacer} from "../DesignSystem/Spacer/Spacer";

export const ExportMappingStepView = ({mapping, onEdit, exportMappingType}) => {

    if (!mapping) {
        return null;
    }

    return <Card style={{background: '#e6f4ff'}}>
        <Row>
            <Col sm={24}>
                <Flex align="center">
                    &nbsp;&nbsp;<TableOutlined style={{fontSize: '20px'}}/>
                    <span style={{fontSize: '18px', fontWeight: 400, lineHeight: 1.5}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;{mapping.length} Fields
                    </span>
                </Flex>
                <Spacer size={"large"}/>
                {mapping.map(item => <Tag key={item.name} bordered={false} style={{marginBottom: '10px'}}>{exportMappingType === 'NAME' ? item.name : item.label}</Tag>)}
                <Row>
                    <Col sm={24} style={{textAlign: 'center'}}>
                        <Spacer/>
                        <Button type={"link"} onClick={onEdit} style={{background: '#f0f0f0', fontWeight: 'bold'}} size={"small"}>Edit
                            Fields</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Card>
}