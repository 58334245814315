import React from 'react';
import {ConfigProvider, Table} from "antd";
import {BigColumn} from "../../DesignSystem/BigColumn/BigColumn";
import {BorderedIcon} from "../../DesignSystem/Icons/BorderedIcon";
import {CreatedAbout} from "../../DesignSystem/Moment/CreatedAbout";

export const FlowExistingConnections = ({connections, onSelect}) => {

    const columns = () => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <BigColumn icon={<BorderedIcon image={"/connectors/" + record.type + ".svg"} size={"small"}/>}
                                                 iconSize={"50px"}
                                                 name={record.name}
                                                 style={{cursor: 'pointer !important'}}
                                                 subtitle={<CreatedAbout createdAt={record.createdAt} usage={record.usage}/>}
                                                 onClick={e => onSelect(record)}
                                                 type={record.type}/>
        }
    ];

    return <ConfigProvider><Table
            rowKey="id"
            dataSource={connections}
            columns={columns()}
            showHeader={false}
            pagination={false}
            size={"small"}
        /></ConfigProvider>
}