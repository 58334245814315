import React from "react";
import {Button, Form, Input, Result, Spin} from "antd";
import {Title} from "../../../DesignSystem/Typography/Title/Title";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {PASSWORD_PATTERN} from "../../settings/password/PasswordForm";
import {PublicContent} from "../PublicContent";
import PropTypes from "prop-types";

export const ResetPasswordForm = ({confirmed, onSubmit, onLogin, error}) => {

    const [form] = Form.useForm();

    const inProgress = <PublicContent><Spin spinning={true}>
        <Result
            title="A token val/ResetPasswordRequestForm.jsidation in progress."
        /></Spin></PublicContent>

    const invalid = <PublicContent><Result
        status={"error"} title="You can't change a password."
        subTitle={"The token is not valid."}
    /></PublicContent>

    const valid = <PublicContent><Result
        status={"success"}
        title="Your password has been changed."
        subTitle="Please log in."
        extra={<Button type="primary" htmlType="submit" onClick={onLogin}>Sign in</Button>}>
    </Result></PublicContent>

    if (confirmed === 'reset-finished') {
        return valid
    } else if (confirmed === 'invalid') {
        return invalid
    } else if (confirmed === 'valid') {
        return (
            <PublicContent>
                    <Title>Change my password</Title>
                    <Spacer size="large"/>
                    <AlertIfMessage message={error}/>
                    <Form
                        layout='vertical'
                        form={form}
                        onFinish={onSubmit}
                    >
                        <Form.Item name="password" label="Password" rules={[
                            {
                                required: true,
                                min: 8,
                                pattern: PASSWORD_PATTERN
                            }
                        ]}>
                            <Input placeholder="Password" type={"password"}/>
                        </Form.Item>
                        <Form.Item name="password-confirmation" label="Confirm password"
                                   dependencies={['password']}
                                   rules={[{
                                       required: true,
                                       min: 8,
                                       pattern: PASSWORD_PATTERN
                                   }, ({getFieldValue}) => ({
                                       validator(_, value) {
                                           if (!value || getFieldValue('password') === value) {
                                               return Promise.resolve();
                                           }
                                           return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                       },
                                   })
                                   ]}>
                            <Input placeholder="Confirm Password" type={"password"}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Save new password</Button>
                        </Form.Item>
                    </Form>
            </PublicContent>)
    } else {
        return inProgress
    }

};

ResetPasswordForm.propTypes = {
    confirmed: PropTypes.oneOf(['reset-finished', 'invalid', 'valid', 'in-progress']),
    onSubmit: PropTypes.func.isRequired,
    onLogin: PropTypes.func.isRequired,
    error: PropTypes.string
};