import {Select} from "antd";
import React, {useMemo} from "react";
import moment from "moment-timezone";
import {getTimezoneLabel} from "../../../utils/moment.utils";
import PropTypes from "prop-types";

const {Option} = Select;

export const TimezoneSelect = ({value, onChange}) => {

    const timezones = useMemo(() => {
        return moment.tz.names().map(value => {
            return {timezone : value, label: getTimezoneLabel(value)}
        })
    }, []);

    return <Select placeholder={"Timezone"} showSearch onSelect={onChange} value={value}>
        {timezones.map(timezone => {
            return <Option key={timezone.timezone} value={timezone.timezone}>{timezone.label}</Option>
        })}

    </Select>

}

TimezoneSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}