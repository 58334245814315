import React from 'react'
import {Alert, Col, Row, Statistic} from "antd";
import {SilverTitle} from "../../../../components/DesignSystem/Typography/SilverTitle/SilverTitle";
import {Spacer} from "../../../../components/DesignSystem/Spacer/Spacer";
import {CustomCard} from "../../../../components/DesignSystem/CustomCard/CustomCard";

export const SubscriptionPanel = ({companyPlan}) => {

    const stripeDescription = () => {

        return <>
            We use Stripe as our payment gateway. Please use this <a href={companyPlan.customerPortalUrl} target="_blank" rel="noreferrer">link</a> to log into
            your Stripe account, where you can view your current subscription, invoices, payment details, and make changes or cancel your
            subscription.</>

    }

    return <CustomCard title={"My current subscription"}>
        <Row gutter={8}>
            <Col span={8}>
                <Statistic title="Current plan" value={companyPlan.subscriptionName}/>
                <SilverTitle>
                    {companyPlan.subscriptionMessage}
                </SilverTitle>
            </Col>
            <Col span={4}>
                <Statistic title="Users" value={companyPlan.usersLimit}/>
            </Col>
            <Col span={4}>
                <Statistic title="Runs" value={companyPlan.runsLimit}/>
            </Col>
            <Col span={4}>
                <Statistic title="Max import size" value={companyPlan.rowsLimit}/>
            </Col>
            <Col span={4}>
                <Statistic title="Schedule" value={companyPlan.scheduleType}/>
            </Col>
        </Row>
        <Spacer border={true}/>
        <Alert
            message="How to manage your subscription"
            description={stripeDescription()}
            type="info"
            showIcon
        />


    </CustomCard>
}