import React from "react";
import styles from "./SilverTitle.module.css";
import PropTypes from "prop-types";

export const SilverTitle = ({children, fontSize = '16px'}) => {
    return (
        <div className={styles.title} style={{fontSize: fontSize}}>{children}</div>
    )};

SilverTitle.propTypes = {
    children: PropTypes.node.isRequired
}