import React from "react";
import {Alert, Button} from "antd";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";


export const SubscriptionExpired = ({companySubscriptionState}) => {

    let history = useHistory();

    function showBillingPage() {
        history.push("/billing");
    }

    if (companySubscriptionState === "OK") {
        return <></>
    } else if (companySubscriptionState === "TRIAL_EXPIRED") {
        return <Alert
            message="Free trial expired"
            type="warning"
            showIcon
            description="Your access to apimoon is limited to view-only mode since your free trial has expired. To continue using apimoon, please sign up for a paid subscription."
            action={
                <Button size="large" style={{marginTop: "10px"}} onClick={showBillingPage}>
                    Select plan
                </Button>
            }
        />
    } else if (companySubscriptionState === "SUBSCRIPTION_EXPIRED") {
        return <Alert
            message="Subscription expired"
            type="warning"
            showIcon
            description="Your access to apimoon is limited to view-only mode since your subscription has expired. To continue using apimoon, please sign up for a paid subscription."
            action={
                <Button size="large" style={{marginTop: "10px"}} onClick={showBillingPage}>
                    Select plan
                </Button>
            }
        />
    }

}

SubscriptionExpired.propTypes = {
    companySubscriptionState: PropTypes.string
}
