import React from "react";
import {BigColumn} from "../../DesignSystem/BigColumn/BigColumn";
import {CreatedAbout} from "../../DesignSystem/Moment/CreatedAbout";
import {BorderedIcon} from "../../DesignSystem/Icons/BorderedIcon";
import ActionDropdown from "../../DesignSystem/ActionDropdown/ActionDropdown";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import {InlineEdit} from "../../DesignSystem/InlineEdit/InlineEdit";
import {Col, Flex, Row} from "antd";
import {CustomButton} from "../../DesignSystem/CustomButton/CustomButton";
import {EditOutlined, ReloadOutlined} from "@ant-design/icons";
import {ConnectionAccessSelect} from "../connection-access-select/ConnectionAccessSelect";

export const buttons = (handleSelect, record, companySubscriptionState) => {

    const actions = [];

    if (record.editable === true) {

        actions.push({
            title: 'Edit',
            id: 'edit',
            icon: <EditOutlined/>,
            disabled: companySubscriptionState !== 'OK'
        })

    }

    actions.push({
        title: 'Test connection',
        id: 'test',
        icon: <ReloadOutlined/>,
        disabled: companySubscriptionState !== 'OK'
    })

    if (record.type === "pipedrive") {
        actions.push({
            title: 'Revoke',
            id: 'revoke',
            icon: <ReloadOutlined/>,
            disabled: companySubscriptionState !== 'OK',
            confirmation: {
                title: 'Confirmation',
                content: `Do you really want to revoke the connection with name <b>${record.name}</b>?. If you do that, all exports which use this connection will stop working.`
            }
        })
    }

    actions.push({
        title: 'Delete',
        id: 'delete',
        disabled: record.usage > 0,
        icon: <DeleteOutlined/>,
        confirmation: {
            title: 'Confirmation',
            content: `Do you really want to delete the connection with name <b>${record.name}</b>?`
        }
    })

    return <Row align="middle" gutter={5}>
        <Col>
            {warn(record)}
        </Col>
        <Col>
            <Flex align={"center"}>
                <ConnectionAccessSelect value={record.access} connectionId={record.id}/>
                &nbsp;&nbsp;
                {(record.type === "jira" || record.type === "google-sheets" || record.type === "ms-excel" || record.type === "pipedrive") && (
                    <CustomButton type="dashed" onClick={() => handleSelect('refresh_token', record)}
                                  disabled={companySubscriptionState !== "OK"}>Reconnect</CustomButton>)}
                {actions.length > 0 && (<ActionDropdown handleSelect={handleSelect} data={record} items={actions}/>)}
            </Flex>
        </Col>
    </Row>

}

export const warn = (connection) => {

    if (connection.state === 'INVALID') {
        return <span style={{color: "red"}}>Invalid connection. {connection.message}</span>
    } else {
        return <></>
    }

}

export const columns = (handleSelect, handleNameChange, companySubscriptionState) => [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => <BigColumn icon={<BorderedIcon image={"/connectors/" + record.type + ".svg"} size={"small"}/>}
                                             iconSize={"50px"}
                                             name={<InlineEdit onChange={value => handleNameChange(value, record.id)} value={record.name}/>}
                                             type={record.type}
                                             subtitle={<CreatedAbout createdAt={record.createdAt} usage={record.usage}/>}
                                             extra={buttons(handleSelect, record, companySubscriptionState)}
        />
    }
];

