import {axiosService} from "../axios";

export const planService = {

    fetchPlans: () => {
        return axiosService.get(`/api/plans`)
    },

    fetchPublicPlans: () => {
        return axiosService.get(`/api/plans/public`)
    }

}