import React from "react";
import {useHistory} from "react-router-dom";
import {ExportIcon} from "../../../../../../components/Export/ExportIcon/ExportIcon";
import {Col, Row, message, Button, Tooltip, Flex, Card} from "antd";
import {Title} from "../../../../../../components/DesignSystem/Typography/Title/Title";
import {exportService} from "../../../../../../services/exportService";
import SyncOutlined from "@ant-design/icons/lib/icons/SyncOutlined";
import {RunExportButton} from "../../../../../../components/ExportDetail/table/RunExportButton";
import {InlineEdit} from "../../../../../../components/DesignSystem/InlineEdit/InlineEdit";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
import {ExportDocumentPublicUrlButton} from "../../../../../../components/Export/ExportDocumentPublicUrlButton";
import SettingOutlined from "@ant-design/icons/lib/icons/SettingOutlined";
import {StatisticsHeader} from "../StatisticsHeader";

export const ExportSnapshotsDetailHeader = ({
                                                companySubscriptionState,
                                                children, exportDefinition,
                                                subscriptionLimit,
                                                statistics, onChangedName, onStartExport,
                                                onRefresh,
                                                editable = false,
                                                schedule
                                            }) => {

    let history = useHistory();

    const handleActionMenuClick = (event) => {
        if (event === 'edit') {
            history.push(`/exports/${exportDefinition.id}/edit`);
        }
    }

    const handleNameChange = (value) => {
        exportService.updateExportName(exportDefinition.id, value).then(() => {
            message.info("Export name has been updated");
            onChangedName();
        }).catch(error => {
            message.error("Export name hasn't been updated. " + getErrorFromResponse(error));
        })
    }

    return (
        <Card>
            <Row align="middle">
                <Col sm={16}>
                    <Flex align="center">
                        <ExportIcon source={exportDefinition.source} target={exportDefinition.target}/>
                        <span>
                        <Title>
                            <InlineEdit onChange={handleNameChange} value={exportDefinition.name}/>
                        </Title>
                    </span>
                    </Flex>
                </Col>
                <Col sm={8}>
                    <div style={{textAlign: "right"}}>
                        <RunExportButton record={exportDefinition} subscriptionLimit={subscriptionLimit}
                                         companySubscriptionState={companySubscriptionState} onClick={onStartExport}/>{" "}
                        <Tooltip title={"Edit export definition"}>
                            <Button onClick={() => handleActionMenuClick('edit')} type={"text"}
                                    disabled={companySubscriptionState !== 'OK' || !editable}><SettingOutlined/></Button>{" "}
                        </Tooltip>
                        {exportDefinition.documentPublicUrl !== undefined && (<ExportDocumentPublicUrlButton
                            type={exportDefinition.target}
                            documentPublicUrl={exportDefinition.documentPublicUrl}/>)}
                    </div>
                </Col>
            </Row>
            <StatisticsHeader object={exportDefinition.object} statistics={statistics}
                              schedule={schedule}
                              rightPanel={<Tooltip title={"Refresh table"}>
                                  <Button icon={<SyncOutlined/>} type="text" onClick={() => onRefresh()}/>
                              </Tooltip>}/>
            {children}
        </Card>
    )

}

ExportSnapshotsDetailHeader.propTypes = {
    editable: PropTypes.bool,
    companySubscriptionState: PropTypes.string,
    children: PropTypes.node,
    exportDefinition: PropTypes.object,
    subscriptionLimit: PropTypes.number,
    statistics: PropTypes.object,
    onChangedName: PropTypes.func,
    onStartExport: PropTypes.func,
    setVisible: PropTypes.func,
    onRefresh: PropTypes.func
}