import {Button} from "antd";
import React from "react";
import PropTypes from "prop-types";


export const SelectButton = ({title, onClick, icon}) => {

    return <Button type={"text"} style={{width: "100%", textAlign: "left", fontSize: "10px", color: "#606060"}}
            icon={icon} onClick={onClick}>
        {title}
    </Button>

}

SelectButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.element
}

