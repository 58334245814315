import React, {useState} from "react";
import {Button, Col, Row} from "antd";
import PropTypes from "prop-types";
import {Spacer} from "../Spacer/Spacer";
import style from "./CollapsePanel.module.css";

export const CollapsePanel = ({title, subtitle, children}) => {

    const [expanded, setExpanded] = useState(false);

    function onChange() {
        setExpanded(!expanded);
    }

    return <div className={style.panel}>
        <Row onClick={onChange} className={style.header}>
            <Col sm={12}><div className={style.title}>{title}</div></Col>
            <Col sm={12} style={{textAlign: 'right'}}>
                <Button onClick={onChange} style={{minWidth: '100px'}}>
                {!expanded && (<>Expand</>)}
                {expanded && (<>Collapse</>)}
            </Button></Col>
        </Row>
        {subtitle}
        <Spacer/>
        {expanded && children}
    </div>

}

CollapsePanel.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    children: PropTypes.node.isRequired
};
