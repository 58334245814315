import React from "react";
import {AlertIfMessage} from "../../../DesignSystem/AlertIfMessage/AlertIfMessage";
import {Button, Form, Input, Select} from "antd";
import {Spinner} from "../../../DesignSystem/Spinner/Spinner";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import PropTypes from "prop-types";

export const EditCompanyForm = ({handleSubmit, company, error, loading}) => {

    const [form] = Form.useForm();

    return <Spinner spinning={loading}>
        <AlertIfMessage message={error}/>
        <Form
            layout='vertical'
            form={form}
            onFinish={handleSubmit}
            initialValues={{
                name: company.name,
                exportMappingType: company.exportMappingType
            }}
        >
            <Form.Item name="name" label="Name" rules={[{required: true}]}>
                <Input placeholder="Name"/>
            </Form.Item>
            <Form.Item name="exportMappingType" label="Export mapping type" rules={[{required: true}]} tooltip={"Specify whether the mapping" +
                " will be done by Name or Label. If by Name, the header will follow the format product.id. If by Label, the header will" +
                " simply be like Product Id."}>
                <Select>
                    <Select.Option value="NAME">Technical name <span style={{color: "silver", fontSize: '12px'}}>- (example: product.id)</span></Select.Option>
                    <Select.Option value="LABEL">User friendly label <span style={{color: "silver", fontSize: '12px'}}>- (example: Product Id)</span></Select.Option>
                </Select>
            </Form.Item>
            <Spacer/>
            <Form.Item>
                <Button type="primary" htmlType="submit">Save</Button>
            </Form.Item>
        </Form>
    </Spinner>

}

EditCompanyForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    company: PropTypes.object.isRequired,
    error: PropTypes.string,
    loading: PropTypes.bool.isRequired
}