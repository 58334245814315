
export const downloadFile = (data, filename, type = "application/json") => {
    const url = window.URL.createObjectURL(new Blob([data], { type: type }));
    const link = document.createElement("a");
    link.setAttribute("download", filename);
    link.setAttribute("href", url);
    document.body.appendChild(link);
    link.click();
};

export const getPublicDocumentText = (type) => {

    switch (type) {
        case 'google-sheets':
            return 'Open Google Sheet';
        case 'ms-excel':
            return 'Open Excel Document';
        default:
            return 'Open document';
    }

}

export const getTargetName = (target) => {
    switch (target) {
        case 'google-sheets':
            return 'Google Sheets';
        case 'google-drive':
            return 'Google Drive';
        case 'ms-excel':
            return 'MS Excel';
        default:
            return target;
    }
}

export const getSourceName = (source) => {
    switch (source) {
        case 'pipedrive':
            return 'Pipedrive';
        case 'jira':
            return 'Jira';
        default:
            return source;
    }
}