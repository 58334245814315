import React, {useEffect, useState} from "react";
import {Button} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import styles from './AddConnection.module.css';
import PropTypes from "prop-types";

export const AddConnection = ({connectionType, type}) => {

    const [screenSize, setScreenSize] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        setScreenSize({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    const handleCreateNewConnection = () => {

        let height = 600;
        let width = 800;
        const left = (screenSize.dynamicWidth - width) / 2;
        const top = (screenSize.dynamicHeight - height) / 2;
        window.open(`/connections/new?type=${connectionType}`, "center window", 'resizable = yes, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

    }

    return <Button type={type} className={type === 'text' ? styles.type: ''} icon={<PlusOutlined/>} onClick={() => handleCreateNewConnection()}>Create new connection</Button>

}

AddConnection.propTypes = {
    connectionType: PropTypes.string,
    type: PropTypes.string
}