import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {Col, message, Row, Skeleton, Spin} from "antd";
import {exportService} from "../../../../../../services/exportService";
import {ExportSnapshotsDetailHeader} from "./ExportSnapshotsDetailHeader";
import {CenteredDesign} from "../../../../../../components/DesignSystem/CenteredDesign/CenteredDesign";
import {Spacer} from "../../../../../../components/DesignSystem/Spacer/Spacer";
import {ExportSnapshotsTable} from "../../../../../../components/ExportSnapshot/table/ExportSnapshotsTable";
import {getErrorFromResponse} from "../../../../../../utils/error.utils";
import PropTypes from "prop-types";
import {ExportStatus, ExportStatusFinished} from "./ExportStatus";
import {ExportSchedulePanel} from "../schedule/ExportSchedulePanel";
import {useCookies} from "react-cookie";

export const ExportSnapshotsDetail = ({user}) => {

    const SHOW_FAILED_ONLY = "show_failed_only";

    let {exportId} = useParams();
    let history = useHistory();

    const [cookies, setCookies] = useCookies([SHOW_FAILED_ONLY]);

    const [loadingContext, setLoadingContext] = useState({loading: false, tip: undefined});
    const [exportDefinition, setExportDefinition] = useState(undefined);
    const [subscriptionLimit, setSubscriptionLimit] = useState(undefined);
    const [reloadTable, setReloadTable] = useState(undefined);
    const [statistics, setStatistics] = useState(undefined);
    const [schedule, setSchedule] = useState(undefined);
    const [exportSnapshotId, setExportSnapshotId] = useState(undefined);
    const [showExportStatus, setShowExportStatus] = useState(false);
    const [exportSnapshotStatus, setExportSnapshotStatus] = useState(undefined);
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        fetchExport();

        if (cookies[SHOW_FAILED_ONLY] === undefined) {
            setCookies(SHOW_FAILED_ONLY, false, {path: '/'});
        }

    }, [exportId]); // eslint-disable-line react-hooks/exhaustive-deps

    function fetchExport() {
        setLoadingContext({loading: true});
        exportService.fetchExport(exportId).then(response => {
            setExportDefinition(response.data.export);
            setSubscriptionLimit(response.data.subscriptionLimit);
            setStatistics(response.data.statistics);
            setSchedule(response.data.schedule);
            setEditable(response.data.editableMode === 'YES');
        }).catch(e => {
            message.warning("Required export no longer exists.");
            history.push("/exports");
        }).finally(() => {
            setLoadingContext({loading: false});
        })
    }

    function startExport() {
        exportService.startExport(exportId).then(response => {
            setShowExportStatus(false);
            setExportSnapshotId(response.data);
            setReloadTable(Math.random());
        }).catch(error => {
            message.error(getErrorFromResponse(error));
        })
    }

    function refreshSnapshots() {
        setReloadTable(Math.random());
    }

    function fetchExportSnapshots(page, size) {
        return exportService.fetchAllExportSnapshots(exportId, cookies[SHOW_FAILED_ONLY], page, size).then(response => {
            return Promise.resolve(response);
        })
    }

    function handleOnFinish(status, message) {
        refreshSnapshots();
        setExportSnapshotId(undefined);
        setExportSnapshotStatus({status: status, message: message});
        setShowExportStatus(true);
    }

    function handleShowFailedOnlyChange(e) {
        setCookies(SHOW_FAILED_ONLY, e, {path: '/'});
        setReloadTable(Math.random());
    }

    if (exportDefinition === undefined) {
        return <Skeleton/>
    } else {
        return <CenteredDesign><Spin spinning={loadingContext.loading} tip={loadingContext.tip}>
            <Spacer size={"large"}/>
            {exportSnapshotId !== undefined &&
                <ExportStatus exportId={exportId}
                              exportSnapshotId={exportSnapshotId} onFinish={handleOnFinish}/>}
            {showExportStatus && <ExportStatusFinished
                exportName={exportDefinition.name}
                status={exportSnapshotStatus.status}
                message={exportSnapshotStatus.message}
                documentPublicUrl={exportDefinition.documentPublicUrl}/>}
            <ExportSnapshotsDetailHeader
                editable={editable}
                exportDefinition={exportDefinition}
                companySubscriptionState={user.companyPlan.companySubscriptionState}
                subscriptionLimit={subscriptionLimit}
                statistics={statistics}
                onChangedName={fetchExport}
                onRefresh={refreshSnapshots}
                schedule={schedule}
                onStartExport={startExport}/>
            <Spacer/>
            <Row gutter={20}>
                <Col sm={14}>
                    <ExportSnapshotsTable fetchData={fetchExportSnapshots}
                                          reload={reloadTable}
                                          showFailedOnly={cookies[SHOW_FAILED_ONLY]}
                                          onShowFailedOnlyChange={handleShowFailedOnlyChange}
                    />
                </Col>
                <Col sm={10}>
                    <ExportSchedulePanel user={user}/>
                </Col>
            </Row>

        </Spin></CenteredDesign>
    }


}

ExportSnapshotsDetail.propTypes = {
    user: PropTypes.object.isRequired
};