import React from "react";
import {UpperCaseText} from "../../DesignSystem/Typography/UpperCaseText/UpperCaseText";
import PropTypes from "prop-types";

export const NotificationLevelValue = ({level}) => {

    switch (level) {
        case "CRITICAL":
            return <span style={{color: "#cd201f"}}><UpperCaseText  text={level}/></span>
        case "WARNING":
            return <span style={{color: "#f50"}}><UpperCaseText text={level}/></span>
        default:
            return <UpperCaseText text={level}/>
    }

}

NotificationLevelValue.propTypes = {
    level: PropTypes.string.isRequired
}