import React, {Col} from "antd";
import {useEffect, useState} from "react";
import {ProcessStepForm} from "./ProcessStepForm";
import {Spacer} from "../Spacer/Spacer";
import {MODE, ProcessStepHeader} from "./ProcessStepHeader";
import {processStepService} from "../../../services/processStepService";
import {ActionPanel} from "../ActionPanel/ActionPanel";
import PropTypes from "prop-types";

export const ProcessStep = ({processStepId, icon, defaultMode = MODE.READ, name, subtitle, onEdit, onDelete, showDelete = false}) => {

    const [properties, setProperties] = useState([]);
    const [initialValues, setInitialValues] = useState([]);
    const [mode, setMode] = useState(defaultMode);
    const [type, setType] = useState(undefined);

    useEffect(() => {
        setMode(defaultMode);
        if (defaultMode === MODE.EDIT) {
            handleOnClick();
        }
    }, [defaultMode]);

    const handleOnClick = () => {
        setMode(MODE.EDIT);
        onEdit(processStepId).then(response => {
            const existingConfiguration = response.processStep.configuration.reduce((obj, item) => ({...obj, [item.name]: item.value}), {});
            setInitialValues(existingConfiguration);
            setProperties(response.stepType.properties);
            setType(response.processStep.type);
        });
    }

    const handleSubmit = (values) => {
        processStepService.updateProcessStep(processStepId, name, values).then(response => {
            setMode(MODE.READ);
            window.location.reload();
        })
    }

    const handleOnClose = () => {
        setMode(MODE.READ);
    }

    const handleOnDelete = () => {
        onDelete(processStepId);
    }

    return <ActionPanel
        header={<ProcessStepHeader icon={icon} onClose={handleOnClose} title={name} subtitle={subtitle} mode={mode} onClick={handleOnClick} showDelete={showDelete} onDelete={handleOnDelete}/>}
    >{mode === MODE.EDIT && (
        <Col sm={24}>
            <Spacer size={"large"}/>
            <ProcessStepForm properties={properties} initialValues={initialValues} handleSubmit={handleSubmit} type={type}/>
        </Col>
    )}</ActionPanel>

}

ProcessStep.propTypes = {
    processStepId: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    showDelete: PropTypes.bool,
    defaultMode: PropTypes.string
}