import React, {useEffect, useState} from "react";
import {processStepService as processStepsService} from "../../../../services/processStepService";
import {Select} from "antd";
import {FilterOutlined} from "@ant-design/icons";

const {Option} = Select;

export const PipedriveFilters = ({connectionId, object, onChange, value}) => {

    const [filters, setFilters] = useState(undefined);

    const loadFilters = () => {
        return processStepsService.getProcessStepData("pipedrive", "filters", {connection: connectionId, object: object}).then(response => {
            setFilters(response.data);
        });
    }

    useEffect(() => {
        loadFilters();
    }, []);

    if (filters === undefined) {
        return <span/>
    }

    return <span>
        <Select placeholder={"Select filter"} suffixIcon={ <FilterOutlined />} showSearch style={{width: '500px'}} onChange={onChange} value={value} allowClear={true}>
        {filters.map(filter => {
            return <Option key={filter.id} value={filter.id}>{filter.name}</Option>
        })}
        </Select></span>

}