import React from "react";
import {PublicContent} from "../PublicContent";
import {Result} from "antd";

export const CompanyDeleted = () => {

    return <PublicContent>
        <Result
            status={"success"}
            title={<>Your company has been deleted.</>}>
        </Result>
    </PublicContent>


}