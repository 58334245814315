import {axiosService} from "../axios";

export const processStepService = {

    createProcessStepDraft: (exportId, type, name, direction) => {
        return axiosService.post("/api/process-steps/draft", {type: type,
            name: name, exportId: exportId, direction: direction});
    },

    getProcessStep: (processStepId) => {
        return axiosService.get(`/api/process-steps/${processStepId}`);
    },

    updateProcessStep: (processStepId, name, values) => {
        return axiosService.put(`/api/process-steps/${processStepId}`, {
            name: name, values: values});
    },

    deleteProcessStep: (processStepId) => {
        return axiosService.delete(`/api/process-steps/${processStepId}`);
    },

    getProcessStepData: (type, action, payload) => {
        return axiosService.post(`/api/process-steps/type/${type}/action/${action}`, payload);
    }

}