import React, {useEffect, useState} from "react";
import {Form, Input, Button, Checkbox} from 'antd';
import {PASSWORD_PATTERN} from "../../settings/password/PasswordForm";
import {Spacer} from "../../../DesignSystem/Spacer/Spacer";
import PropTypes from "prop-types";

export const RegistrationInputsForm = ({username, companyName, onSave}) => {

        const [form] = Form.useForm();

        const [bordered, setBordered] = useState(true);
        const [readOnly, setReadOnly] = useState(false);

        useEffect(() => {

            if (username !== undefined) {
                setBordered(false);
                setReadOnly(true);
            }

        }, [username]);

        return <Form
            layout='vertical'
            form={form}
            onFinish={onSave}
            initialValues={{username, companyName}}
        >
            <Form.Item name="username" label="E-mail address" rules={[
                {
                    type: 'email',
                    message: 'Please insert a valid email address'
                },
                {
                    required: true,
                    message: 'Please input your email',
                }
            ]}>
                <Input placeholder="E-mail address" readOnly={readOnly} bordered={bordered}/>
            </Form.Item>
            <Form.Item style={{marginBottom: 0}}>
                <Form.Item name="firstName" label="First name" rules={[
                    {
                        required: true,
                        min: 2,
                        message: 'Please input your first name!',
                    }
                ]} style={{display: 'inline-block', width: 'calc(50% - 12px)'}}>
                    <Input placeholder="First name"/>
                </Form.Item>{' '}
                <Form.Item name="lastName" label="Last name" rules={[
                    {
                        required: true,
                        min: 2,
                        message: 'Please input your last name!',
                    }
                ]} style={{display: 'inline-block', width: 'calc(50% + 7px)'}}>
                    <Input placeholder="Last name"/>
                </Form.Item>
            </Form.Item>
            <Form.Item name="companyName" label="Company Name" rules={[
                {
                    required: true,
                    min: 2,
                    message: 'Please input your company name!',
                }
            ]}>
                <Input placeholder="Company Name" readOnly={readOnly} bordered={bordered}/>
            </Form.Item>
            <Form.Item name="password" label="Password" rules={[
                {
                    required: true,
                    min: 8,
                    pattern: PASSWORD_PATTERN
                }
            ]}>
                <Input placeholder="Password" type={"password"}/>
            </Form.Item>
            <Form.Item name="password-confirmation" label="Confirm password"
                       dependencies={['password']}
                       rules={[{
                           required: true,
                           min: 8,
                           pattern: PASSWORD_PATTERN
                       }, ({getFieldValue}) => ({
                           validator(_, value) {
                               if (!value || getFieldValue('password') === value) {
                                   return Promise.resolve();
                               }
                               return Promise.reject(new Error('The two passwords that you entered do not match!'));
                           },
                       })
                       ]}>
                <Input placeholder="Confirm Password" type={"password"}/>
            </Form.Item>
            <Form.Item name="agree" valuePropName="checked" rules={[{
                required: true,
                message: 'You have to agree with apimoon terms!',
            }]}>
                <Checkbox>I agree to Apimoon's Terms of Service and Privacy
                    Policy</Checkbox>
            </Form.Item>
            <Form.Item>
                <Spacer/>
                <Button type="primary" htmlType="submit">Create Account</Button>
            </Form.Item>
        </Form>
    }
;

RegistrationInputsForm.propTypes = {
    username: PropTypes.string,
    companyName: PropTypes.string,
    onSave: PropTypes.func.isRequired
};