import React from "react";
import {Select} from "antd";
import PropTypes from "prop-types";

const {Option} = Select;

export const SelectSupportedObject = ({value, onChange, supportedObjects, disabled = false}) => {

    return <Select placeholder={"Object"} value={value} disabled={disabled} showSearch onChange={onChange}>
        {supportedObjects.map(supportedObject => <Option key={supportedObject.key}
                                                               value={supportedObject.key}>{supportedObject.name}</Option>)}
    </Select>

}

SelectSupportedObject.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    supportedObjects: PropTypes.array.isRequired,
    disabled: PropTypes.bool
}
