import React, {useMemo} from "react";
import {Col, Row, Select} from "antd";
import PropTypes from "prop-types";

const {Option} = Select;

export const CronDayOfMonthPart = ({value, onChange}) => {

    const mapping = useMemo(() => {

        const daysOfMonth = [];

        for (let day = 1; day <= 31; day++) {
            daysOfMonth.push({value: day, label: day});
        }

        return daysOfMonth;

    }, []);

    const handleChange = (dayOfMonth) => {
        onChange(dayOfMonth);
    }

    return <Row gutter={12}>
        <Col sm={24}>
            <Select showSearch value={value} style={{width: "100%"}} onChange={handleChange}>
                {mapping.map(interval => <Option key={interval.value} value={interval.value}>{interval.label}</Option>)}
            </Select>
        </Col>
    </Row>

}

CronDayOfMonthPart.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired
}