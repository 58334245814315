import React, {useEffect, useState} from "react";
import {Route} from "react-router-dom";
import {Layout, Skeleton} from "antd";
import TopHeader from "../../../components/Header/Header/TopHeader";
import SettingsSidebar from "../../../components/apps/settings/SettingsSidebar";
import {userService} from "../../../services/user.service";
import PropTypes from "prop-types";
const {Content} = Layout;

const SettingsParentPage = ({children, ...rest}) => {

    const [user, setUser] = useState(undefined);

    useEffect(() => {
        userService.me().then(response => {
            setUser(response.data);
        });
    });

    function layout() {
        return <Layout style={{minHeight: '100vh'}}>
            <TopHeader user={user}/>
            <Layout>
                <SettingsSidebar/>
                <Layout className="apimoon-layout">
                    <Content className="site-layout-background apimoon-content">
                        <Route
                            {...rest}
                            render={({location}) => (
                                children
                            )
                            }
                        />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    }

    if (user === undefined) {
        return <Skeleton/>
    } else {
        return (
            layout()
        );
    }

};

export default SettingsParentPage;

SettingsParentPage.propTypes = {
    children: PropTypes.object.isRequired
}