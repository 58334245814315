import React from "react";
import {Alert, Button} from "antd";
import {useHistory} from "react-router-dom";
import PropTypes from "prop-types";


export const SubscriptionLimitReached = ({subscriptionLimit}) => {

    let history = useHistory();

    function showBillingPage() {
        history.push("/billing");
    }

    if (subscriptionLimit === "OK") {
        return <></>
    } else if (subscriptionLimit === "LIMIT_REACHED") {
        return <Alert
            message="Subscription Runs Limit Reached"
            type="warning"
            showIcon
            description="To continue exporting data, please consider upgrading your subscription plan."
            action={
                <Button size="large" style={{marginTop: "10px"}} onClick={showBillingPage}>
                    Select plan
                </Button>
            }
        />
    }

}

SubscriptionLimitReached.propTypes = {
    subscriptionLimit: PropTypes.string
}
